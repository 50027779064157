import React from 'react';
import CardTimeline from '../cards/card_timeline';
const TimelineClass = (props) => {
    const { topics, turma } = props;

    return (
            <div className="w-100">
                {
                    topics &&
                    topics.map((topic, indice) => {
                        return (<CardTimeline key={`cardTimeLine__${indice}`} topic={topic} turma={turma}/>)
                    })
                }
            </div>
    );
}

export default TimelineClass