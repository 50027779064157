import React, { useEffect, useState } from "react";
import { logos, curved } from "../../../core/app_images";
import TopNavigator from "../components/navigator/top_navigator";
import Sidebar from "../components/sidebar/sidebar";
import "../../../assets/css/soft-ui-dashboard.css";
import ChatIcon from "@material-ui/icons/Chat";
import TabsStudentHome from "../components/elements/tabs_student_home";
import { MotionDiv } from "../../shared/tools/animations";
import { getUser } from "../../../services/auth";
import { Link } from "react-router-dom";
import {
  getProfileImage,
  getUserTurma,
  enviarProBack
} from "../../../repositories/user_repository";
import { Skeleton } from "@material-ui/lab";
import CertificadosCard from "../components/cards/certificados_card";
import Footer from "../../shared/components/footer/footer";
import EvolutionsCard from "../components/cards/evolutions_card";
import { getPerformanceByAlunoId } from '../../../repositories/turma_repository';

const StudentHomePage = () => {
  const count = 0;
  const [showNavbar, setShowNavbar] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [user, setUser] = useState({});
  const [turma, setTurma] = useState([]);
  const [performance, setPerformance] = useState([]);
  const [homeBlock, setHomeBlock] = useState(
  <button className="btn btn-primary btn-sm mb-2" type="button" disabled>
    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    Carregando...
  </button>);
  const colors = ['bg-gradient-success','bg-gradient-primary','bg-gradient-secondary','bg-gradient-warning'];

  const onChangeNavbar = (opt) => {
    setShowNavbar(opt);
  };

  const getPerformance = async(modulo) => {
    let performance = await getPerformanceByAlunoId(user.id, modulo);
    if (performance.success && performance.performance !== null) {
      return true;
    } else {
      return false;
    }
  }

  const getUserLogged = async () => {
    let userLogged = await getUser();

    setUser(userLogged);

    let image = getProfileImage(userLogged.id);
    setProfileImage(image);
  };

  const getTurmaUserLogged = async () => {
    let result = await getUserTurma();
    if (result) {
      if (result.success && result.turma !== null && result.turma.length !== 0) {
        setHomeBlock(<TabsStudentHome turma={result.turma} />);
        setTurma(result.turma);
      } else {
        setHomeBlock('Nenhuma informação disponível. Você não possui turmas ativas!');
      }
    }
  };

  useEffect(() => {
    getTurmaUserLogged();
    getUserLogged();
  }, []);

  return (
    <div
    className={`g-sidenav-show bg-gray-100${
      showNavbar ? " g-sidenav-pinned" : ""
    }`}
    >
      <Sidebar
        logo={logos.navbar}
        show={showNavbar}
        background={"bg-main"}
        onCloseSidebar={onChangeNavbar}
      />

      <div className="main-content position-relative bg-gray-100">
        <TopNavigator
          title="Profile"
          showNavbar={onChangeNavbar}
          navbarOpen={showNavbar}
        />
        <div className="container-fluid">
          <div
            className="page-header min-height-200 border-radius-xl mt-4"
            style={{
              backgroundImage: `url(${curved.curved_0})`,
              backgroundPositionY: "50%",
            }}
          >
            <span className="mask bg-gradient-main opacity-6"></span>
          </div>
          <div className="card card-body blur shadow-blur mx-4 mt-n6">
            <div className="row gx-4">
              <div className="col-auto">
                <div className="avatar avatar-xl position-relative">
                  <img
                    src={profileImage}
                    alt="..."
                    className="w-100 border-radius-lg shadow-sm"
                  />
                  <Link
                    to="/student/configs"
                    className="btn btn-sm btn-icon-only bg-gradient-light position-absolute bottom-0 end-0 mb-n2 me-n2"
                  >
                    <i
                      className="fa fa-pen top-0"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Edit Image"
                    ></i>
                  </Link>
                </div>
              </div>
              <div className="col-auto my-auto">
                <div className="h-100">
                  <h5 className="mb-1">{user.nome}</h5>
                  { (turma)  ? (
                    <MotionDiv className="mb-0 font-weight-bold text-sm">
                      {turma.nome}
                    </MotionDiv>
                  ) : (
                    <MotionDiv className="mb-0 font-weight-bold text-sm">
                      <Skeleton />
                    </MotionDiv>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
                <div className="nav-wrapper position-relative end-0">
                  <ul
                    className="nav nav-pills nav-fill p-1 bg-transparent"
                    role="tablist"
                  >
                    {turma ? (
                      <li className="nav-item">
                        <Link
                          className="nav-link mb-0 px-0 py-1"
                          to={`/student/turma/${turma.id}`}
                        >
                          <svg
                            className="text-dark"
                            width="16px"
                            height="16px"
                            viewBox="0 0 40 44"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              id="Basic-Elements"
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <g
                                id="Rounded-Icons"
                                transform="translate(-1870.000000, -591.000000)"
                                fill="#FFFFFF"
                                fillRule="nonzero"
                              >
                                <g
                                  id="Icons-with-opacity"
                                  transform="translate(1716.000000, 291.000000)"
                                >
                                  <g
                                    id="document"
                                    transform="translate(154.000000, 300.000000)"
                                  >
                                    <path
                                      className="color-background"
                                      d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"
                                      id="Path"
                                      opacity="0.603585379"
                                    ></path>
                                    <path
                                      className="color-background"
                                      d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"
                                      id="Shape"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                          <span className="ms-1">Turma</span>
                        </Link>
                      </li>
                    ) : (
                      <li className="nav-item">
                        <Skeleton />
                      </li>
                    )}
                    <li className="nav-item">
                      <Link
                        to="/student/messages"
                        className="nav-link mb-0 px-0 py-1 "
                      >
                        <ChatIcon style={{ color: "#3A416F" }} />
                        <span className="ms-1">Mensagens</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-3">
              <div className="row">
                <div className="col-12 py-4">
                  <EvolutionsCard />
                </div>
                <div className="col-12 py-4">
                  <CertificadosCard />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-9 py-4">
            <div className="container" style={{marginBottom:'20px'}}>
                  <div className="row">
                      { turma.niveis && 
                        turma.niveis.map((nivel, index) => {
                          return (
                              <div key={index} className="col-sm">
                                  <div><span>{nivel.nome}</span></div>
                                  <div>
                                      <div className="progress-wrapper">
                                          <div className="progress-info">
                                              <div className="progress-percentage">
                                              <span className="text-sm font-weight-bold">{nivel.porcentagem ? nivel.porcentagem.toFixed(2) : '0'}%</span>
                                              </div>
                                          </div>
                                          <div style={{height: '10px'}} className="progress">
                                              <div className={`progress-bar ${colors[index]}`} role="progressbar" aria-valuenow={nivel.porcentagem  } aria-valuemin="0" aria-valuemax="100" style={{width: `${nivel.porcentagem ? nivel.porcentagem : 0}%`}}></div>
                                          </div>
                                      </div>  

                                      {
                                         nivel.performance !== null ? (
                                              <Link target="_blank" key={index} to={`/student/certificado/${turma.id}/${nivel.id}`} className="col-12 col-md-4">
                                                  <div className="card cursor-pointer">
                                                      <div className="card-body">
                                                          Baixar certificado
                                                      </div>
                                                  </div>
                                              </Link>
                                          ) : (
                                              <div className="card">
                                                  <div className="card-body">
                                                          <small>Certificado: Em breve</small>
                                                  </div>
                                              </div>
                                          )                                                        
                                      }

                                  </div>
                              </div>
                          )
                        })
                      }
                  </div>
              </div>
              <div className="card h-100">
                <div className="card-body p-3 w-100">
                      {homeBlock}
                      {/* <TabsStudentHome turma={turma} /> */}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default StudentHomePage;
