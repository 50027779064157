const top_link_list = [
    {
        title: "Sobre Nós",
        url: "about-us",
        local: true,
        inPage: true
    },
    {
        title: "Cursos",
        url: "courses",
        local: true,
        inPage: true
    },
    {
        title: "Dicas",
        url: "tips",
        local: true,
        inPage: true
    },
    {
        title: "Parceiros",
        url: "clients",
        local: true,
        inPage: true
    },
    {
        title: "Clube de Vantagens",
        url: "advantages",
        local: true,
        inPage: true
    },
    {
        title: "Contato",
        url: "contact",
        local: true,
        inPage: true
    },
]

const top_right_links = [
    {
        title: 'Faça parte da equipe',
        type: 'link',
        url: 'https://api.whatsapp.com/send?phone=556598472-8167&text=Ol%C3%A1!%20Estou%20entrando%20em%20contato%20atrav%C3%A9s%20do%20seu%20site,%20com%20interesse%20em%20fazer%20parte%20da%20equipe!',
        local: false,
        inPage: false
    },
    {
        title: 'Entrar',
        type: 'special',
        url: '/login',
        local: true,
        inPage: false
    }
]

export { top_link_list, top_right_links }