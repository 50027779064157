import React, { useEffect, useState } from 'react';
import Melhada from '../../../../src/assets/img/backgrounds/medalha.jpeg';
import Assinatura from '../../../../src/assets/img/backgrounds/assinatura.png';
import Logo from '../../../../src/assets/img/backgrounds/logo.png';

const CertificadoComp = (props) => {
   const { turma, nivel, aluno, performance } = props; 
   const geral = () => {
        let escrevendo = performance.escrevendo !== null ? performance.escrevendo : 0;
        let falando = performance.falando !== null ? performance.falando : 0;
        let leitura = performance.leitura !== null ? performance.leitura : 0;
        let ouvindo = performance.ouvindo !== null ? performance.ouvindo : 0;

        return (parseInt(escrevendo) + parseInt(falando) + parseInt(leitura) + parseInt(ouvindo)) / 4;
   }

   const CurrentMonthYear = () => {
    const currentDate = new Date();
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const currentMonth = monthNames[currentDate.getMonth()];
    const currentYear = currentDate.getFullYear();
    
    return currentMonth+' '+currentYear;
  };

   const getLevelCigla = (level) => {
       level = level.toLowerCase();

       if (level === 'advanced') {
           return 'C1 - C2';
        }
        
        if (level === 'upper intermediate') {
            return 'B2';
        }

        if (level === 'lower intermediate') {
            return 'B1';
        }

        if (level === 'basic') {
            return 'A2';
        }
   }

   return (
    <>
       <div id="trecho-1" style={{ height: 1700, maxWidth: 900}}>
            <div
                id="lateral"
                style={{
                height: 1700,
                backgroundColor: "#00234e",
                width: 70,
                float: "left",
                backgroundImage: "linear-gradient(to top,#00234e 90%, #032957)",
                position: "absolute"
                }}
            />
            <div
                style={{
                backgroundColor: "#c0392b",
                width: 30,
                float: "left",
                marginLeft: '73px',
                height: 1700,
                boxShadow: "0px 1px 15px",
                position: "absolute"
                }}
            />
            
                <p style={{ textAlign: "center" }}>
                <img
                    alt=""
                    style={{ marginTop: '20px'}}
                    // height={132}
                    src={Logo}
                    width={380}
                />
                </p>
                <p style={{ textAlign: "center" }}>
                <span style={{ color: "#002060" }}>
                    <span style={{ fontSize: 30 }}>
                    <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                        English Certificate
                    </span>
                    </span>
                </span>
                </p>
                <p style={{ textAlign: "center" }}>
                <strong>
                    <span style={{ color: "#002060" }}>
                    <span style={{ fontSize: 60 }}>
                        <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                        { turma.turma.niveis[nivel-1].nome } ({getLevelCigla(turma.turma.niveis[nivel-1].nome)})
                        </span>
                    </span>
                    </span>
                </strong>
                </p>
                <p style={{ textAlign: "center" }}>&nbsp;</p>
                <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: 22 }}>
                    <span style={{ fontFamily: '"Aparajita",serif' }}>
                    This certificate is awarded to
                    </span>
                </span>
                </p>
                <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: 22 }}>
                    <span style={{ fontFamily: '"Aparajita",serif' }}>{aluno.toUpperCase()}</span>
                </span>
                </p>
                <p style={{ textAlign: "center" }}>&nbsp;</p>
                <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: 22 }}>
                    <span style={{ fontFamily: '"Aparajita",serif' }}>
                    for successfully completing the&nbsp;
                    </span>
                </span>
                </p>
                <p style={{ textAlign: "center" }}>
                <strong>
                    <span style={{ color: "#002060" }}>
                    <span style={{ fontSize: 28 }}>
                        <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                        { turma.turma.niveis[nivel-1].nome } Level
                        </span>
                    </span>
                    </span>
                </strong>
                </p>
                <p style={{ textAlign: "center" }}>&nbsp;</p>
                <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: 22 }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontFamily: '"Aparajita",serif' }}>
                        of Business English Course, being introduced&nbsp;{" "}
                    </span>
                    </span>
                </span>
                </p>
                <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: 22 }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontFamily: '"Aparajita",serif' }}>
                        to 96 hours of the Language{" "}
                    </span>
                    </span>
                </span>
                </p>
                <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: 22 }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontFamily: '"Aparajita",serif' }}>
                        according to the North-American patterns
                    </span>
                    </span>
                </span>
                </p>
                <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: 22 }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontFamily: '"Aparajita",serif' }}>
                        and being considered at{" "}
                        <strong>
                        <span style={{ color: "#002060" }}>{getLevelCigla(turma.turma.niveis[nivel-1].nome)}</span>
                        </strong>{" "}
                        <strong>
                        <span style={{ color: "#002060" }}>level</span>
                        </strong>{" "}
                        according to&nbsp;&nbsp;{" "}
                    </span>
                    </span>
                </span>
                </p>
                <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: 22 }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontFamily: '"Aparajita",serif' }}>
                        CEFR standard (Common European Framework of &nbsp;
                    </span>
                    </span>
                </span>
                </p>
                <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: 22 }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span style={{ fontFamily: '"Aparajita",serif' }}>
                        Reference for Languages).{" "}
                    </span>
                    </span>
                </span>
                </p>
                <p style={{ textAlign: "center" }}>&nbsp;</p>
                <p style={{ textAlign: "center" }}>&nbsp;</p>
                <div style={{ marginBottom: 80 }}>
                <div style={{ width: "100%" }}>
                    <p style={{ textAlign: "center" }}>
                    <span style={{ fontSize: 18 }}>
                        <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span style={{ fontFamily: '"Aparajita",serif' }}>
                            Cuiabá, MT, { CurrentMonthYear() }.
                        </span>
                        </span>
                    </span>
                    </p>
                    <p style={{ textAlign: "center" }}>
                    <img
                        alt=""
                        height={58}
                        src={Assinatura}
                        width={120}
                    />
                    </p>
                    <p style={{ textAlign: "center", marginTop: '-20px' }}>
                        <span style={{ fontSize: 16 }}>
                            <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <span style={{ fontFamily: '"Aparajita",serif' }}>
                                MARCELO DA SILVA SOARES
                            </span>
                            </span>
                        </span>
                    </p>
                    <p style={{ textAlign: "center", marginTop: '-17px' }}>
                    <span style={{ fontSize: 16 }}>
                        <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <span style={{ fontFamily: '"Aparajita",serif' }}>
                            BECOURSE CEO
                        </span>
                        </span>
                    </span>
                    </p>
                </div>
                <div style={{ position: "absolute", left: 790, marginTop: "-155px" }}>
                    <img
                    width={100}
                    src={Melhada}
                    />
                </div>
                </div>
       </div>

       <div id="trecho-2" style={{ height: 1700, maxWidth: 900}}>
            <div
            id="lateral"
            style={{
                height: 1700,
                backgroundColor: "#00234e",
                width: 70,
                float: "right",
                left: 833,
                position: "absolute"
            }}
            />
            <div
            style={{
                backgroundColor: "#c0392b",
                width: 30,
                float: "right",
                height: 1700,
                boxShadow: "0px 1px 15px",
                position: "absolute",
                left: "800px"
            }}
            />
            <p style={{ textAlign: "center", paddingTop: '130px' }}>
            <span style={{ fontSize: "11pt" }}>
                <span style={{ lineHeight: "107%" }}>
                <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span lang="EN-US" style={{ fontSize: "14.0pt" }}>
                    <span style={{ lineHeight: "107%" }}>
                        <span style={{ fontFamily: '"Aparajita",serif', height: '30px' }}>
                        Speaking .......................... {performance.falando !== null ? performance.falando : "0"}
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </span>
            </p>
            <p style={{ textAlign: "center" }}>
            <span style={{ fontSize: "11pt" }}>
                <span style={{ lineHeight: "107%" }}>
                <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span lang="EN-US" style={{ fontSize: "14.0pt" }}>
                    <span style={{ lineHeight: "107%" }}>
                        <span style={{ fontFamily: '"Aparajita",serif' }}>
                        Listening .......................... {performance.ouvindo !== null ? performance.ouvindo : "0"}
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </span>
            </p>
            <p style={{ textAlign: "center" }}>
            <span style={{ fontSize: "11pt" }}>
                <span style={{ lineHeight: "107%" }}>
                <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span lang="EN-US" style={{ fontSize: "14.0pt" }}>
                    <span style={{ lineHeight: "107%" }}>
                        <span style={{ fontFamily: '"Aparajita",serif' }}>
                        Reading&nbsp; ........................... {performance.leitura !== null ? performance.leitura : "0"}
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </span>
            </p>
            <p style={{  textAlign: "center" }}>
            <span style={{ fontSize: "11pt" }}>
                <span style={{ lineHeight: "107%" }}>
                <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <span lang="EN-US" style={{ fontSize: "14.0pt" }}>
                    <span style={{ lineHeight: "107%" }}>
                        <span style={{ fontFamily: '"Aparajita",serif' }}>
                        Writing ............................. {performance.escrevendo !== null ? performance.escrevendo : "0"}
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </span>
            </p>
            <p style={{ textAlign: "center" }}>&nbsp;</p>
            <p style={{ textAlign: "center" }}>&nbsp;</p>
            <p style={{ marginLeft: 78, textAlign: "center" }}>&nbsp;</p>
            <p style={{ textAlign: "center" }}>
            <span style={{ fontSize: "9pt" }}>
                <span style={{ lineHeight: "107%" }}>
                <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <b>EXPLICAÇÃO DOS RESULTADOS</b>
                </span>
                </span>
            </span>
            </p>
            <p style={{ marginLeft: 78, textAlign: "center" }}>&nbsp;</p>
            <div style={{ paddingLeft: "5%" , width: '88%'}}>
            <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                <span style={{ lineHeight: "normal" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <b>
                        <span style={{ fontSize: "10.0pt" }}>1.0 to 2.9 – </span>
                    </b>
                    <span style={{ fontSize: "10.0pt" }}>
                        Indica que o examinado tem pouca ou nenhuma habilidade de
                        comunicação dentro do nível que se encontra devido à falta de
                        contato e entrega de tarefas. Não se comunica.
                    </span>
                    </span>
                </span>
                </span>
            </p>
            <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                <span style={{ lineHeight: "normal" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <b>
                        <span style={{ fontSize: "10.0pt" }}>3.0 to 4.9 – </span>
                    </b>
                    <span style={{ fontSize: "10.0pt" }}>
                        Indica que o examinado tem algumas habilidades. No entanto, a
                        habilidade que tem é insuficiente para comunicar-se com sucesso
                        dentro do nível em que se encontra devido à baixa participação,
                        estudo pessoal e não entrega de exercícios. Fala e entende com
                        dificuldade e por meio de palavras isoladas mas tem capacidade
                        de maximizar seu potencial por meio de foco e esforço com
                        exercícios extras e ao desafiar-se ao falar apenas em inglês nas
                        aulas.{" "}
                    </span>
                    </span>
                </span>
                </span>
            </p>
            <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                <span style={{ lineHeight: "normal" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <b>
                        <span style={{ fontSize: "10.0pt" }}>5.0 to 6.9 –</span>
                    </b>
                    <span style={{ fontSize: "10.0pt" }}>
                        {" "}
                        Indica que o examinado tem se desenvolvido no idioma. Apresenta
                        algum conhecimento, porém às vezes limitado e insuficiente para
                        comunicar-se com total sucesso dependendo do assunto. Sua
                        linguagem pode ser entendida e faz boas inserções, embora cometa
                        erros que prejudicam a compreensão imediata de quem está
                        interagindo com ele sendo necessário que o interlocutor às vezes
                        tenha de repetir, corrigir ou pedir repetição/esclarecimento.
                        Terá mais sucesso ao fazer sempre as tarefas e se dedicar mais à
                        prática e exercícios sugeridos. Comunica-se de maneira regular
                        dentro do nível em que se encontra e tem grande potencial de
                        maximizar os resultados.
                    </span>
                    </span>
                </span>
                </span>
            </p>
            <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                <span style={{ lineHeight: "normal" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <b>
                        <span style={{ fontSize: "10.0pt" }}>7.0 to 8.9 – </span>
                    </b>
                    <span style={{ fontSize: "10.0pt" }}>
                        Indica que o examinado desenvolveu habilidades muito boas do
                        idioma dentro do nível em que se encontra. Consegue comunicar-se
                        e fazer-se entender, embora deva trabalhar para otimizar as
                        inserções e manter a mesma qualidade durante todo o
                        processo.&nbsp; Deve policiar-se para lembrar de palavras ou
                        estruturas corretas para falar certas sentenças, mas consegue
                        perceber e corrigir os próprios erros com certa rapidez. Às
                        vezes precisa da ajuda do interlocutor para concluir seu
                        raciocínio ou precisa de uma ou duas repetições a fim de
                        entender bem a fala, mas não comete erros que comprometem sua
                        comunicação e nem precisa de ajuda em demasia. Apresenta bom
                        entendimento quando escuta e comunica-se com certa facilidade.
                    </span>
                    </span>
                </span>
                </span>
            </p>
            <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                <span style={{ lineHeight: "normal" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <b>
                        <span style={{ fontSize: "10.0pt" }}>9.0 to 10 – </span>
                    </b>
                    <span style={{ fontSize: "10.0pt" }}>
                        Indica que o examinado tem grande habilidade de comunicação, usa
                        as estruturas corretamente e comunica-se com desenvoltura dentro
                        do nível que se encontra. Consegue corrigir-se rapidamente e com
                        precisão caso cometa erros, sendo esses considerados parte da
                        fluência, ou seja, ocorrem como com um nativo. Domina
                        vocabulário e entende comunicação sem necessidade de repetições.
                        Pronuncia as palavras corretamente e interage de maneira
                        natural. Entende e faz-se entender de maneira excelente tanto em
                        situações do cotidiano como em situações mais formais e
                        específicas como trabalho e eventos. É um falante fluente.{" "}
                    </span>
                    </span>
                </span>
                </span>
            </p>
            </div>
       </div>

       <div id="trecho-3" style={{ maxWidth: 900, height: 1700}}>
            <p style={{ textAlign: "justify" }}>&nbsp;</p>
            <div style={{ border: "1px solid #002060", margin: 'auto', marginRight: '5%', marginLeft: '5%', height: '1200px'}}>
            <p style={{ textAlign: "center" }}>
                <img
                alt=""
                height={58}
                src="https://www.becourse.com.br/becourse/class/img/Logo_certificado.png"
                width={220}
                />
            </p>
            <p style={{ textAlign: "center" }}>
                <b>
                <span style={{ fontSize: "14.0pt" }}>
                    <span style={{ lineHeight: "107%" }}>
                    <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                        ASSESSMENT REPORT
                    </span>
                    </span>
                </span>
                </b>
            </p>
            <p style={{ marginBottom: 11, textAlign: "center" }}>
                <span style={{ fontSize: "11pt" }}>
                <span style={{ lineHeight: "107%" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <b>
                        LEVEL <span style={{ color: "red" }}> { turma.turma.niveis[nivel-1].nome.toUpperCase() }</span>
                    </b>
                    </span>
                </span>
                </span>
            </p>
            <table
                align="center"
                className="MsoTableGrid"
                style={{ borderCollapse: "collapse", border: "none" }}
            >
                <tbody>
                <tr>
                    <td
                    style={{
                        borderBottom: "1px solid black",
                        width: 113,
                        padding: "0cm 7px 0cm 7px",
                        backgroundColor: "#c00000",
                        borderTop: "1px solid black",
                        borderRight: "1px solid black",
                        borderLeft: "1px solid black"
                    }}
                    valign="top"
                    >
                    <p style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "11pt" }}>
                        <span style={{ lineHeight: "normal" }}>
                            <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <b>
                                <span style={{ color: "white" }}>EXAMINEE</span>
                            </b>
                            </span>
                        </span>
                        </span>
                    </p>
                    </td>
                    <td
                    style={{
                        borderBottom: "1px solid black",
                        width: 113,
                        padding: "0cm 7px 0cm 7px",
                        backgroundColor: "#c00000",
                        borderTop: "1px solid black",
                        borderRight: "1px solid black",
                        borderLeft: "none"
                    }}
                    valign="top"
                    >
                    <p style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "11pt" }}>
                        <span style={{ lineHeight: "normal" }}>
                            <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <b>
                                <span style={{ color: "white" }}>SPEAKING</span>
                            </b>
                            </span>
                        </span>
                        </span>
                    </p>
                    </td>
                    <td
                    style={{
                        borderBottom: "1px solid black",
                        width: 113,
                        padding: "0cm 7px 0cm 7px",
                        backgroundColor: "#c00000",
                        borderTop: "1px solid black",
                        borderRight: "1px solid black",
                        borderLeft: "none"
                    }}
                    valign="top"
                    >
                    <p style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "11pt" }}>
                        <span style={{ lineHeight: "normal" }}>
                            <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <b>
                                <span style={{ color: "white" }}>LISTENING</span>
                            </b>
                            </span>
                        </span>
                        </span>
                    </p>
                    </td>
                    <td
                    style={{
                        borderBottom: "1px solid black",
                        width: 113,
                        padding: "0cm 7px 0cm 7px",
                        backgroundColor: "#c00000",
                        borderTop: "1px solid black",
                        borderRight: "1px solid black",
                        borderLeft: "none"
                    }}
                    valign="top"
                    >
                    <p style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "11pt" }}>
                        <span style={{ lineHeight: "normal" }}>
                            <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <b>
                                <span style={{ color: "white" }}>READING</span>
                            </b>
                            </span>
                        </span>
                        </span>
                    </p>
                    </td>
                    <td
                    style={{
                        borderBottom: "1px solid black",
                        width: 113,
                        padding: "0cm 7px 0cm 7px",
                        backgroundColor: "#c00000",
                        borderTop: "1px solid black",
                        borderRight: "1px solid black",
                        borderLeft: "none"
                    }}
                    valign="top"
                    >
                    <p style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "11pt" }}>
                        <span style={{ lineHeight: "normal" }}>
                            <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <b>
                                <span style={{ color: "white" }}>WRITING</span>
                            </b>
                            </span>
                        </span>
                        </span>
                    </p>
                    </td>
                </tr>
                <tr>
                    <td
                    style={{
                        borderBottom: "1px solid black",
                        width: 113,
                        padding: "0cm 7px 0cm 7px",
                        borderTop: "none",
                        borderRight: "1px solid black",
                        borderLeft: "1px solid black"
                    }}
                    valign="top"
                    >
                    <p style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "11pt" }}>
                        <span style={{ lineHeight: "normal" }}>
                            <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            {performance.examinador.nome}
                            </span>
                        </span>
                        </span>
                    </p>
                    </td>
                    <td
                    style={{
                        borderBottom: "1px solid black",
                        width: 113,
                        padding: "0cm 7px 0cm 7px",
                        borderTop: "none",
                        borderRight: "1px solid black",
                        borderLeft: "none"
                    }}
                    valign="top"
                    >
                    <p style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "11pt" }}>
                        <span style={{ lineHeight: "normal" }}>
                            <span style={{ fontFamily: "Calibri,sans-serif" }}>{performance.falando !== null ? performance.falando : "0"}</span>
                        </span>
                        </span>
                    </p>
                    </td>
                    <td
                    style={{
                        borderBottom: "1px solid black",
                        width: 113,
                        padding: "0cm 7px 0cm 7px",
                        borderTop: "none",
                        borderRight: "1px solid black",
                        borderLeft: "none"
                    }}
                    valign="top"
                    >
                    <p style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "11pt" }}>
                        <span style={{ lineHeight: "normal" }}>
                            <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            {performance.ouvindo !== null ? performance.ouvindo : "0"}
                            </span>
                        </span>
                        </span>
                    </p>
                    </td>
                    <td
                    style={{
                        borderBottom: "1px solid black",
                        width: 113,
                        padding: "0cm 7px 0cm 7px",
                        borderTop: "none",
                        borderRight: "1px solid black",
                        borderLeft: "none"
                    }}
                    valign="top"
                    >
                    <p style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "11pt" }}>
                        <span style={{ lineHeight: "normal" }}>
                            <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            {performance.leitura !== null ? performance.leitura : "0"}
                            </span>
                        </span>
                        </span>
                    </p>
                    </td>
                    <td
                    style={{
                        borderBottom: "1px solid black",
                        width: 113,
                        padding: "0cm 7px 0cm 7px",
                        borderTop: "none",
                        borderRight: "1px solid black",
                        borderLeft: "none"
                    }}
                    valign="top"
                    >
                    <p style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "11pt" }}>
                        <span style={{ lineHeight: "normal" }}>
                            <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            {performance.escrevendo !== null ? performance.escrevendo : "0"}
                            </span>
                        </span>
                        </span>
                    </p>
                    </td>
                </tr>
                </tbody>
            </table>
            <p style={{ marginBottom: 11, textAlign: "center" }}>&nbsp;</p>
            <table
                align="center"
                className="MsoTableGrid"
                style={{ borderCollapse: "collapse", border: "none" }}
            >
                <tbody>
                <tr>
                    <td
                    style={{
                        borderBottom: "1px solid black",
                        width: 283,
                        padding: "0cm 7px 0cm 7px",
                        backgroundColor: "#002060",
                        borderTop: "1px solid black",
                        borderRight: "1px solid black",
                        borderLeft: "1px solid black"
                    }}
                    valign="top"
                    >
                    <p style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "11pt" }}>
                        <span style={{ lineHeight: "normal" }}>
                            <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <b>
                                <span style={{ color: "white" }}>ATTENDANCE</span>
                            </b>
                            </span>
                        </span>
                        </span>
                    </p>
                    </td>
                    <td
                    style={{
                        borderBottom: "1px solid black",
                        width: 283,
                        padding: "0cm 7px 0cm 7px",
                        backgroundColor: "#002060",
                        borderTop: "1px solid black",
                        borderRight: "1px solid black",
                        borderLeft: "none"
                    }}
                    valign="top"
                    >
                    <p style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "11pt" }}>
                        <span style={{ lineHeight: "normal" }}>
                            <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <b>
                                <span style={{ color: "white" }}>HOME PRACTICE</span>
                            </b>
                            </span>
                        </span>
                        </span>
                    </p>
                    </td>
                </tr>
                <tr>
                    <td
                    style={{
                        borderBottom: "1px solid black",
                        width: 283,
                        padding: "0cm 7px 0cm 7px",
                        borderTop: "none",
                        borderRight: "1px solid black",
                        borderLeft: "1px solid black"
                    }}
                    valign="top"
                    >
                    <p style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "11pt" }}>
                        <span style={{ lineHeight: "normal" }}>
                            <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            Excellent
                            </span>
                        </span>
                        </span>
                    </p>
                    </td>
                    <td
                    style={{
                        borderBottom: "1px solid black",
                        width: 283,
                        padding: "0cm 7px 0cm 7px",
                        borderTop: "none",
                        borderRight: "1px solid black",
                        borderLeft: "none"
                    }}
                    valign="top"
                    >
                    <p style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "11pt" }}>
                        <span style={{ lineHeight: "normal" }}>
                            <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            Excellent
                            </span>
                        </span>
                        </span>
                    </p>
                    </td>
                </tr>
                </tbody>
            </table>
            <p style={{ marginBottom: 11, textAlign: "center" }}>&nbsp;</p>
            <table
                align="center"
                style={{ backgroundColor: "white" }}
                className="MsoTableGrid"
            >
                <tbody>
                <tr>
                    <td
                    style={{
                        borderBottom: "1px solid black",
                        width: 113,
                        padding: "0cm 7px 0cm 7px",
                        backgroundColor: "#002060",
                        borderTop: "1px solid black",
                        borderRight: "1px solid black",
                        borderLeft: "1px solid black"
                    }}
                    valign="top"
                    >
                    <p style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "11pt" }}>
                        <span style={{ lineHeight: "normal" }}>
                            <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            <b>
                                <span style={{ color: "white" }}>AVERAGE</span>
                            </b>
                            </span>
                        </span>
                        </span>
                    </p>
                    </td>
                </tr>
                <tr>
                    <td
                    style={{
                        borderBottom: "1px solid black",
                        width: 113,
                        padding: "0cm 7px 0cm 7px",
                        height: 64,
                        borderTop: "none",
                        borderRight: "1px solid black",
                        borderLeft: "1px solid black"
                    }}
                    valign="top"
                    >
                    <p style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "11pt" }}>
                        <span style={{ lineHeight: "normal" }}>
                            <span style={{ fontFamily: "Calibri,sans-serif" }}>
                            </span>
                        </span>
                        </span>
                    </p>
                    <table width="100%">
                        <tbody>
                        <tr>
                            <td>
                            <div>
                                <p style={{ marginBottom: 11, textAlign: "center" }}>
                                <span style={{ fontSize: "11pt" }}>
                                    <span style={{ lineHeight: "107%" }}>
                                    <span
                                        style={{ fontFamily: "Calibri,sans-serif" }}
                                    >
                                        <b>
                                        <span style={{ fontSize: "26.0pt" }}>
                                            <span style={{ lineHeight: "107%" }}>
                                            &nbsp;{ geral() }
                                            </span>
                                        </span>
                                        </b>
                                    </span>
                                    </span>
                                </span>
                                </p>
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p style={{ textAlign: "center" }}>&nbsp;</p>
                    </td>
                </tr>
                </tbody>
            </table>
            <p align="center" style={{ textAlign: "center", marginBottom: 11 }}>
                &nbsp;
            </p>
            <p align="center" style={{ textAlign: "center", marginBottom: 11 }}>
                <span style={{ fontSize: "11pt" }}>
                <span style={{ lineHeight: "107%" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    *
                    <span style={{ fontSize: "8.0pt" }}>
                        <span style={{ lineHeight: "107%" }}>
                        Resultados baseados em uma escala de 1 a 10
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </p>
            <p align="center" style={{ textAlign: "center", marginBottom: 11 }}>
                <span style={{ fontSize: "11pt" }}>
                <span style={{ lineHeight: "107%" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                    <b>
                        <span lang="EN-US" style={{ fontSize: "14.0pt" }}>
                        <span style={{ lineHeight: "107%" }}>
                            TEACHER’S COMMENTS:
                        </span>
                        </span>
                    </b>
                    </span>
                </span>
                </span>
            </p>
            <p align="center" style={{ textAlign: "center", marginBottom: 11 }}>
                &nbsp;
            </p>
            <div
                style={{
                boxShadow: "2px 2px 1px 2px rgba(0, 0, 0, 0.2)",
                width: 500,
                margin: "auto",
                paddingBottom: 50
                }}
            >
                <p align="center" style={{ textAlign: "center", marginBottom: 11 }}>
                <span style={{ fontSize: "11pt" }}>
                    <span style={{ lineHeight: "107%" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <b>
                        <span lang="EN-US" style={{ color: "#002060" }}>
                            STRENGTHS
                        </span>
                        </b>
                    </span>
                    </span>
                </span>
                </p>
                <p align="center" style={{ textAlign: "center", marginBottom: 11 }}>
                <span style={{ fontSize: "11pt" }}>
                    <span style={{ lineHeight: "107%" }}>
                    <span style={{ fontFamily: "Calibri,sans-serif" }}>
                        <b>
                        <span lang="EN-US" style={{ color: "#002060" }}>
                        {performance.justificativa}
                        </span>
                        </b>
                    </span>
                    </span>
                </span>
                </p>
            </div>
            <p style={{ textAlign: "center" }}>&nbsp;</p>
            </div>
       </div>
    </>
   );
}

export default CertificadoComp;
