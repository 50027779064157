
import React, { useState } from 'react';
import { Dialog, AppBar, Slide, Typography, IconButton, Button, Toolbar, TextField } from '@material-ui/core';
import DefaultTextEditor from '../../../shared/components/editor/DefaultTextEditor';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import { getUserTurma } from '../../../../repositories/user_repository';
import { createDiscussion, updateDiscussion, saveTaskForm } from '../../../../repositories/turma_repository';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const CreateDiscussion = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const { open, onClose, onSuccess, infoTask, turma } = props

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [tags, setTags] = useState('')

    const getTurmaUserLogged = async () => {
        let result = await getUserTurma()

        if (result.success) {
            return (result.turma)
        }

        return {}
    }

    const onSubmitForm = async (ev) => {
        ev.preventDefault()
        if (props.turma === undefined) {
            let turma = await getTurmaUserLogged();
        }

        if (turma) {
            savedSuggestion({ assunto: infoTask ? infoTask.nome : title, descricao: description ? description : infoTask.descricao, tags, fk_turma: turma.id })
        }
    }

    const savedSuggestion = async (data) => {
        let response = await saveTaskForm(data, infoTask.id)

        if (response.success) {
            resetFields()
            enqueueSnackbar('Discussão atualizada com sucesso!',
                { variant: 'success' })
            onClose()
            onSuccess()
        } else {
            enqueueSnackbar('Ops, aconteceu algo de errado, tente novamente mais tarde!',
                { variant: 'danger' })
        }
    }

    const resetFields = () => {
        setTitle('')
        setDescription('')
        setTags('')
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <AppBar className="bg-main position-static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Nova Discussão
                    </Typography>
                </Toolbar>
            </AppBar>
            <form onSubmit={onSubmitForm} className="mt-4 container">
                <TextField className="mb-2" label="Título" variant="standard" value={ infoTask ? infoTask.nome : title} onChange={(ev) => setTitle(ev.target.value)} fullWidth />
                <DefaultTextEditor value={infoTask ? infoTask.descricao : description} onChange={(val) => setDescription(val)} />
                <TextField
                    value={tags} onChange={(ev) => setTags(ev.target.value)}
                    className="mb-2 mt-2"
                    label="Tags"
                    variant="standard"
                    helperText="Separe as tags por vírgula (,) ou ponto e vírgula (;)"
                    fullWidth />
                <Button type="submit" className="btn btn-main bg-main text-white">Salvar</Button>
            </form>

        </Dialog>
    )
}

export default CreateDiscussion