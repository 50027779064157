import { IconButton, makeStyles, useTheme, Zoom } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles((theme) => ({
    toTop: {
        zIndex: 2,
        position: 'fixed',
        bottom: '10vh',
        right: '2vw',
        backgroundColor: "#00234e",
        color: '#ffffff',
        "&:hover, &.Mui-focusVisible": {
            transition: '0.5s',
            color: '#ffffff',
            backgroundColor: '#001126'
        }
    }
}))

const ScrollToTop = (props) => {
    const classes = useStyles()
    const theme = useTheme()
    const { showBelow } = props
    const [show, setShow] = useState(showBelow ? false : true)

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
      }

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        } else {
            if (show) setShow(false)
        }
    }

    const handleClick = () => {
        window['scrollTo']({ top: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        if (showBelow) {
            window.addEventListener('scroll', handleScroll)
            return () => window.removeEventListener('scroll', handleScroll)
        }
    })

    return (
        <div>
            <Zoom
                in={show}
                timeout={transitionDuration}
                style={{
                    transitionDelay: `${transitionDuration.exit}ms`,
                }}
                unmountOnExit
            >
                <IconButton onClick={handleClick} className={classes.toTop}>
                    <ExpandLessIcon />
                </IconButton>
            </Zoom>
        </div>
    )

}

export default ScrollToTop