const iconsUnselectedColor = "#3A416F";

const sidebar_links = [
    {
        title: "Início",
        link: "/student",
        IconSelected: <i className={`fas fa-home text-lg opacity-10`} style={{ marginTop: -4 }} aria-hidden="true"></i>,
        Icon: <i className={`fas fa-home text-lg opacity-10`} style={{ marginTop: -4, color: iconsUnselectedColor }} aria-hidden="true"></i>
    },
    {
        title: "Classes",
        link: "/student/classes",
        IconSelected: <i className={`fas fa-user-friends text-lg opacity-10`} style={{ marginTop: -4 }} aria-hidden="true"></i>,
        Icon: <i className={`fas fa-user-friends text-lg opacity-10`} style={{ marginTop: -4, color: iconsUnselectedColor }} aria-hidden="true"></i>
    },
    {
        title: "Mensagens",
        link: "/student/messages",
        IconSelected: <i className={`fas fa-comments text-lg opacity-10`} style={{ marginTop: -4 }} aria-hidden="true"></i>,
        Icon: <i className={`fas fa-comments text-lg opacity-10`} style={{ marginTop: -4, color: iconsUnselectedColor }} aria-hidden="true"></i>
    },
    {
        title: "Discussões",
        link: "/student/discussions",
        IconSelected: <i className={`far fa-comments text-lg opacity-10`} style={{ marginTop: -4 }} aria-hidden="true"></i>,
        Icon: <i className={`far fa-comments text-lg opacity-10`} style={{ marginTop: -4, color: iconsUnselectedColor }} aria-hidden="true"></i>
    },
    {
        title: "Sugestões",
        link: "/student/suggestions",
        IconSelected: <i className={`far fa-lightbulb text-lg opacity-10`} style={{ marginTop: -4 }} aria-hidden="true"></i>,
        Icon: <i className={`far fa-lightbulb text-lg opacity-10`} style={{ marginTop: -4, color: iconsUnselectedColor }} aria-hidden="true"></i>
    }
];

const sidebar_other_links = [
    {
        title: "Configurações",
        link: "/student/configs",
        IconSelected: <i className={`fas fa-cog text-lg opacity-10`} style={{ marginTop: -4 }} aria-hidden="true"></i>,
        Icon: <i className={`fas fa-cog text-lg opacity-10`} style={{ marginTop: -4, color: iconsUnselectedColor }} aria-hidden="true"></i>
    },
    {
        title: "Financeiro",
        link: "/student/financial",
        IconSelected: <i className={`fas fa-money-bill-wave text-lg opacity-10`} style={{ marginTop: -4 }} aria-hidden="true"></i>,
        Icon: <i className={`fas fa-money-bill-wave text-lg opacity-10`} style={{ marginTop: -4, color: iconsUnselectedColor }} aria-hidden="true"></i>
    },
];

export { sidebar_links, sidebar_other_links }