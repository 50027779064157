import React, { useState, useEffect } from "react";
import {
  getMyCertificates,
  getMyEvolution,
} from "../../../../repositories/user_repository";

import {
  Avatar,
  CircularProgress,
  makeStyles,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { getNameInitials } from "../../../../utils/functions";
import EvolutionDetailCard from "../dialogs/evolution_detail_card";
import AllEvolutionDialog from "../dialogs/all_evolution_dialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const EvolutionsCard = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [evolutions, setEvolutions] = useState([]);
  const [selected, setSelected] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openAllEvolutionDialog, setOpenAllEvolutionDialog] = useState(false);

  const getEvolutions = async () => {
    setLoading(true);
    let result = await getMyEvolution();

    if (result.success) {
      setEvolutions(result.evolutions);
    }
    setLoading(false);
  };

  useEffect(() => {
    getEvolutions();
  }, []);

  const onCloseDialog = () => {
    setOpenDialog(false);
    setOpenAllEvolutionDialog(false);
    setSelected(null);
  };

  const onClickListItem = (item) => {
    setOpenDialog(true);
    setSelected(item);
  };

  const onClickShowAllEvolution = () => {
    setOpenAllEvolutionDialog(true);
  };

  return (
    <>
      <div className="card h-100">
        <div className="card-header pb-0 p-3">
          <h6 className="mb-0">
            Evolução Acadêmica{" "}
            <IconButton
              aria-label="read more"
              onClick={onClickShowAllEvolution}
            >
              <ReadMoreIcon />
            </IconButton>
          </h6>
          <hr />
        </div>
        <div className="card-body p-3">
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
          >
            {loading ? (
              <ListItem button style={{ borderRadius: 50 }}>
                <CircularProgress style={{ marginRight: 15 }} />
                <ListItemText primary={"Carregando Evolução..."} />
              </ListItem>
            ) : (
              evolutions.map((c, iterator) => {
                return (
                  <ListItem
                    onClick={() => onClickListItem(c)}
                    key={`${iterator}___evolution-${c.id}`}
                    button
                    style={{ borderRadius: 50 }}
                  >
                    <ListItemIcon>
                      <Avatar>
                        {iterator + 1 < 10 ? `0${iterator + 1}` : iterator + 1}
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText primary={c.etapa} />
                  </ListItem>
                );
              })
            )}
            {evolutions.length == 0 && !loading && (
              <ListItem button style={{ borderRadius: 50 }}>
                <ListItemText
                  primary={
                    "Infelizmente nenhuma evolução foi cadastrada, ainda... :("
                  }
                />
              </ListItem>
            )}
          </List>
        </div>
      </div>
      <EvolutionDetailCard
        open={openDialog}
        onClose={onCloseDialog}
        evolutionSelected={selected}
      />

      <AllEvolutionDialog
        open={openAllEvolutionDialog}
        onClose={onCloseDialog}
        evolutions={evolutions}
      />
    </>
  );
};

export default EvolutionsCard;
