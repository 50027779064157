import React, { useEffect } from "react";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { Center } from "@chakra-ui/layout";
import AudioPlayerComponent from "../../../shared/components/players/audio_player_component";
import { url } from "../../../../services/api";
import { Pagination } from "@material-ui/lab";

const TabAudios = (props) => {
  const { audios, changeAudios } = props;

  const handleChangePagination = (_, value) => {
    changeAudios(value);
  };
  return (
    <div className="w-100 row">
      {audios.data.map((audio, indice) => {
        console.log(audio);
        return (
          <div className="p-2 col-12">
            <div
              className="card bg-main text-white"
              key={`${indice}_tab-lessons_${audio.id}`}
            >
              <p className="px-4 pt-2 pb-0">{audio.nome.replace(".mp3", "")}</p>
              <AudioPlayerComponent
                src={`https://becourse.com.br/becourse${audio.url.replace(
                  "..",
                  ""
                )}${audio.nome_unico}`}
              />
            </div>
          </div>
        );
      })}

      {audios.data.length == 0 && (
        <div className="card p-4">
          <p>Nenhum audio encontrado.</p>
        </div>
      )}
      <Center className="mt-2">
        <Pagination
          page={audios.current_page}
          value={audios.current_page}
          count={
            audios.total <= audios.per_page
              ? 1
              : parseInt(audios.total / audios.per_page + 1)
          }
          onChange={handleChangePagination}
          showFirstButton
          showLastButton
        />
      </Center>
    </div>
  );
};

export default TabAudios;
