
import React, { useEffect, useState } from 'react';
import { Dialog, AppBar, Slide, Typography, IconButton, Button, Toolbar, TextField } from '@material-ui/core';
import DefaultTextEditor from '../../../shared/components/editor/DefaultTextEditor';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import { format, isAfter } from 'date-fns';
import { getUserTurma, getFullDataOfUser } from '../../../../repositories/user_repository';
import { createDiscussion, updateDiscussion, saveTaskForm } from '../../../../repositories/turma_repository';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const SaveTask = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const { open, onClose, onSuccess, infoTask, turma, topic } = props

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [answer, setAnswer] = useState('')
    const [tags, setTags] = useState('')

    const dataLimite = new Date(infoTask.data_fim);
    const prazoPermitido = !isAfter(new Date(), dataLimite);

    const getTurmaUserLogged = async () => {
        let result = await getUserTurma()

        if (result.success) {
            return (result.turma)
        }

        return {}
    }

    const onSubmitForm = async (ev) => {
        ev.preventDefault()
        if (props.turma === undefined) {
            let turma = await getTurmaUserLogged();
        }

        if (turma) {
            saveTakSend({ assunto: infoTask ? infoTask.nome : title, descricao: description , tags, fk_turma: turma.id })
        }
    }

    const saveTakSend = async (data) => {
  
        // let user = await getFullDataOfUser(turma.fk_id_user);

        const dataTask = {
            // fk_topico : infoTask.fk_topico,
            // fk_aluno: user.aluno.id,
            texto_task : data.descricao,
            // fk_id_user: topic.fk_id_user,
        };

        // console.log(infoTask);
        setAnswer(data.descricao);
        let response = await saveTaskForm(dataTask, infoTask.id)

        if (response.success) {
            resetFields()
            enqueueSnackbar('Task enviada com sucesso!',
                { variant: 'success' })
            onClose()
            onSuccess()
        } else {
            enqueueSnackbar('Ops, aconteceu algo de errado, tente novamente mais tarde!',
                { variant: 'danger' })
        }
    }

    const resetFields = () => {
        setTitle('')
        setDescription('')
        setTags('')
        // setAnswer('')
    }

    useEffect(() => {
        if (infoTask.answer > 0) {
            setAnswer(infoTask.getAnswers[0].texto_task);
        }

    }, [infoTask])

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <AppBar className="bg-main position-static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Responder Tarefa
                    </Typography>
                </Toolbar>
            </AppBar>
            <form onSubmit={onSubmitForm} className="mt-4 container">
                {/* <TextField className="mb-2" label="Título" variant="standard" value={ infoTask ? infoTask.nome : title} onChange={(ev) => setTitle(ev.target.value)} fullWidth /> */}
                <div className="mb-2" style={{ color: 'gray' }}>
                    <label>Título</label>
                    <TextField
                        className="mb-2"
                        label="Título"
                        variant="standard"
                        value={infoTask ? infoTask.nome : title}
                        onChange={(ev) => setTitle(ev.target.value)}
                        fullWidth
                        disabled
                    />
                </div>
                <div className="mb-2">
                    <label>Descrição</label>
                    <div
                        style={{ border: '1px solid #ddd', padding: '10px', marginBottom: '10px', minHeight: '50px' }}
                        dangerouslySetInnerHTML={{ __html: infoTask ? infoTask.descricao : description }}
                    />
                </div>
                <DefaultTextEditor className="mb-2 mt-2" placeholder="Responda aqui" value={answer} onChange={(val) => setDescription(val)} />
                {/* <TextField
                    value={tags} onChange={(ev) => setTags(ev.target.value)}
                    className="mb-2 mt-2"
                    label="Tags"
                    variant="standard"
                    helperText="Separe as tags por vírgula (,) ou ponto e vírgula (;)"
                    fullWidth /> */}
                <Button type="submit" className="btn btn-main bg-main text-white">Salvar</Button>
            </form>

        </Dialog>
    )
}

export default SaveTask