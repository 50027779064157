import React, { useEffect, useState } from 'react';
import { logos } from '../../../core/app_images';
import TopNavigator from '../components/navigator/top_navigator';
import Sidebar from '../components/sidebar/sidebar';
import '../../../assets/css/soft-ui-dashboard.css';
import { getAllTurmasByAlunoId } from '../../../repositories/turma_repository';
import { getUser } from '../../../services/auth';
import { Link } from 'react-router-dom';
import Footer from '../../shared/components/footer/footer';

const StudentClassesPage = () => {
    const [showNavbar, setShowNavbar] = useState(false);
    const [turmas, setTurmas] = useState([]);

    const onChangeNavbar = (opt) => {
        setShowNavbar(opt);
    }

    const getAllTurmas = async () => {
        let logged = getUser()
        let result = await getAllTurmasByAlunoId(logged.id)

        if (result.success) {
            setTurmas(result.turmas)
        }
    }

    useEffect(() => {
        getAllTurmas()
    }, [])

    return (
        <div className={`g-sidenav-show bg-gray-100${showNavbar ? " g-sidenav-pinned" : ""}`}>
            <Sidebar logo={logos.navbar} show={showNavbar} background={'bg-main'} onCloseSidebar={onChangeNavbar} />
            <div className="main-content position-relative bg-gray-100" style={{ height: '100vh' }}>
                <TopNavigator key={Math.random()} title="Turmas" breadcrumps={[{ title: 'Início', link: '/student' }]} showNavbar={onChangeNavbar} navbarOpen={showNavbar} />
                <div className="container-fluid">
                    <div className="row py-4" style={{ minHeight: '75vh' }}>
                        <div className="col-12 col-md-12">
                            <div className="row">
                                <div className="col-12" >
                                    <div className="card my-4">
                                        <div className="card-body p-3">
                                            <h6 className="mb-0">Suas Turmas</h6>
                                        </div>
                                    </div>
                                    <div className="row my-4">
                                        {
                                            turmas.map(turma => {
                                                return (
                                                    <Link to={`/student/turma/${turma.id}`} className="col-12 col-md-4">
                                                        <div className="card cursor-pointer">
                                                            <div className="card-body">
                                                                {turma.nome} {turma.local && ` - ${turma.local}`} - {turma.hora_init}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />

                </div>
            </div>
        </div>
    );
}

export default StudentClassesPage;
