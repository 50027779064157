import Routes from './routes/routes';
import { SnackbarProvider } from 'notistack';
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}>
      <Routes />
    </SnackbarProvider>
  );
}

export default App;
