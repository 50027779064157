import DashboardPage from "../modules/admin/pages/dashboard_page";
import LoginPage from "../modules/auth/pages/login_page";
import StudentClassesPage from "../modules/student/pages/student_classes_page";
import StudentConfigsPage from "../modules/student/pages/student_configs_page";
import StudentDiscussionsPage from "../modules/student/pages/student_discussions_page";
import StudentDiscussionPage from "../modules/student/pages/student_discussion_page";
import StudentFinancialPage from "../modules/student/pages/student_financial_page";
import StudentHomePage from "../modules/student/pages/student_home_page";
import StudentMessagesPage from "../modules/student/pages/student_messages_page";
import StudentSuggestionsPage from "../modules/student/pages/student_suggestions_page";
import StudentSuggestionPage from "../modules/student/pages/student_suggestion_page";
import StudentTurmaPage from "../modules/student/pages/student_turma_page";
import LandingPage from "../modules/website/pages/landing_page";
import LandingPageOLD from "../modules/website/pages/landing_page_old";
import CertificadoStudant from "../modules/student/pages/certificado";

const routes_list = [
    // SITE
    {
        title: "Página Inicial",
        module: "website",
        link: "/",
        component: LandingPage,
        protected: false,
        description: "Business English Course"
    },
    {
        title: "OLD Página Inicial",
        module: "website",
        link: "/home_old",
        component: LandingPageOLD,
        protected: false,
        description: "Business English Course"
    },
    // AUTH
    {
        title: "Login",
        module: "auth",
        link: "/login",
        component: LoginPage,
        protected: false,
        description: "Efetue o login na plataforma Becourse!"
    },
    // STUDENT 
    {
        title: "Dashboard do Aluno",
        module: "student",
        link: "/student",
        component: StudentHomePage,
        protected: true,
        description: "Página de Dashboard do aluno"
    },
    {
        title: "Classes do Aluno",
        module: "student",
        link: "/student/classes",
        component: StudentClassesPage,
        protected: true,
        description: "Página de exibição de turmas do aluno"
    },
    {
        title: "Turma do Aluno",
        module: "student",
        link: "/student/turma/:id",
        component: StudentTurmaPage,
        protected: true,
        description: "Página de exibição de aulas da turma"
    },
    {
        title: "Mensagens",
        module: "student",
        link: "/student/messages",
        component: StudentMessagesPage,
        protected: true,
        description: "Página de mensagens do aluno"
    },
    {
        title: "Discussões",
        module: "student",
        link: "/student/discussions",
        component: StudentDiscussionsPage,
        protected: true,
        description: "Página de Discussões do aluno"
    },
    {
        title: "Exibição de Discussão",
        module: "student",
        link: "/student/discussion/:id",
        component: StudentDiscussionPage,
        protected: true,
        description: "Página de Discussões do aluno"
    },
    {
        title: "Sugestões",
        module: "student",
        link: "/student/suggestions",
        component: StudentSuggestionsPage,
        protected: true,
        description: "Página de Sugestões do aluno"
    },
    {
        title: "Exibição de Sugestão",
        module: "student",
        link: "/student/suggestion/:id",
        component: StudentSuggestionPage,
        protected: true,
        description: "Página da Sugestão do aluno"
    },
    {
        title: "Configurações",
        module: "student",
        link: "/student/configs",
        component: StudentConfigsPage,
        protected: true,
        description: "Página de configurações do aluno"
    },
    {
        title: "Financeiro",
        module: "student",
        link: "/student/financial",
        component: StudentFinancialPage,
        protected: true,
        description: "Página financeira do aluno"
    },
    {
        title: "TEST",
        module: 'admin',
        link: "/admin/test",
        component: DashboardPage,
        protected: false,
        description: "Página de test"
    },
    {
        title: "Certificado",
        module: "Certificado ",
        link: "/student/certificado/:turma/:modulo",
        component: CertificadoStudant,
        protected: true,
        description: "Página de vizuavição do certificado"
    },

];

export default routes_list;