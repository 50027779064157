import React, { useEffect, useState } from "react";
import {
  Dialog,
  AppBar,
  Slide,
  Typography,
  IconButton,
  Toolbar,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import {
  getUrlToDownloadLessonFile,
  getExtrasFiles,
  getLessonsFiles,
} from "../../../../repositories/turma_repository";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { Center } from "@chakra-ui/layout";
import { useHistory } from "react-router";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LessosFilesDialog = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useHistory();

  const { open, onClose, lessonSelected, onSuccess } = props;

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const getListOfFiles = async () => {
    setLoading(true);
    let response = await getLessonsFiles(lessonSelected.id);
    if (response.success) {
      setFiles(response.files);
    }
    setLoading(false);
  };

  const downloadFile = async (id) => {
    let res = await getUrlToDownloadLessonFile(id);

    if (res.success) {
      let nWindow = window.open(res.url);

      if (!nWindow || nWindow.closed || typeof nWindow.closed == "undefined") {
        enqueueSnackbar(
          "Infelizmente o popup foi bloqueado, libere para conseguir abrir o arquivo.",
          { variant: "error" }
        );
      } else {
        enqueueSnackbar("Abrindo arquivo em uma nova aba ;)", {
          variant: "success",
        });
      }
    } else {
      enqueueSnackbar(
        "Não foi possível encontrar o arquivo, entre em contato com o responsável ou tente mais tarde!",
        { variant: "warning" }
      );
    }
  };

  const clickFilesDialog = (f) => {
    let url = `https://becourse.com.br/becourse${f.url.replace("..", "")}${
      f.nome_unico
    }`;

    let nWindow = window.open(url);

    if (!nWindow || nWindow.closed || typeof nWindow.closed == "undefined") {
      enqueueSnackbar(
        "Infelizmente o popup foi bloqueado, libere para conseguir abrir o arquivo.",
        { variant: "error" }
      );
    } else {
      enqueueSnackbar("Abrindo arquivo em uma nova aba ;)", {
        variant: "success",
      });
    }
  };

  useEffect(() => {
    if (open) {
      getListOfFiles();
    }
  }, [open]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className="bg-main position-static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {lessonSelected.nome}
          </Typography>
        </Toolbar>
      </AppBar>
      {loading ? (
        <Center className="my-auto">
          <CircularProgress />
        </Center>
      ) : (
        <div className="container row">
          {files.map((file, indice) => {
            return (
              <div className="p-2 col-6 col-md-4">
                <div
                  onClick={() => clickFilesDialog(file)}
                  className="card bg-main text-white cursor-pointer"
                  key={`${indice}_tab-extras-file_${file.id}`}
                >
                  <Center>
                    <AttachFileIcon style={{ fontSize: 50 }} />
                    <p className="px-2 py-1">{file.nome}</p>
                  </Center>
                </div>
              </div>
            );
          })}

          {files.length == 0 && (
            <div className="card p-4">
              <p>Nenhum arquivo encontrado.</p>
            </div>
          )}
        </div>
      )}
    </Dialog>
  );
};

export default LessosFilesDialog;
