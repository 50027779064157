import React from 'react'

const ClientCard = (props) => {
    const { img, title } = props

    return (
        <div className="card shadow-none h-100">
            <div className="card-header p-0 mx-0 mt-1 position-relative z-index-1">
                <div className="d-block">
                    <img src={img} alt={`logomarca ${title}`} className="img-fluid blur rounded" />
                </div>
                <div className="" style={{ backgroundImage: `url(${img})` }}></div>
            </div>
        </div>
    )
}

export default ClientCard