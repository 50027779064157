import { backgrounds } from "../core/app_images";

const allCourses = [
    {
        background: backgrounds.new.bg_003,
        title: "PROJETO INGLÊS NA MINHA EMPRESA",
        subTitle: "(English in company)",
        description: {
            "time": 1625839395270,
            "blocks": [
                {
                    "id": "m7RmGCPvL-",
                    "type": "header",
                    "data": {
                        "text": "PROJETO INGLÊS NA MINHA EMPRESA",
                        "level": 3
                    }
                },
                {
                    "id": "BufpYA0BEL",
                    "type": "header",
                    "data": {
                        "text": "(English in company)",
                        "level": 5
                    }
                },
                {
                    "id": "c8QkPxb6fl",
                    "type": "paragraph",
                    "data": {
                        "text": "          O projeto English in company atende os colaboradores das empresas parceiras fazendo com que desenvolvam inglês para conversação, escrita e leitura, a fim de resolver todas as demandas de trabalho e lazer, tornando os alunos aptos tanto a enviar, entender e receber e-mails e outros tipo de comunicação em inglês, quanto a receberem estrangeiros com tranquilidade e confiança tanto em situações mais descontraídas como um almoço por exemplo ou mesmo em situações mais sérias como reuniões de negociação."
                    }
                },
                {
                    "id": "QRMpmzfoyb",
                    "type": "paragraph",
                    "data": {
                        "text": "          Esse modelo pode ser contratado como benefício corporativo - quando a empresa financia os custos (ou parte dos custos) para o colaborador - ou mesmo como benefício facultativo, quando a empresa faz apenas a negociação para conseguir valores mais em conta, ficando a cargo inteiramente do colaborador contratar as aulas de maneira particular. Ambos podem ser oferecidos aos colaboradores como benefício por parte da gestão de pessoas e oferecem cobertura para familiares dos colaboradores da empresa parceira, que adquirem o direito de ter seus familiares fazendo as aulas e pagando os mesmos valores ofertados a eles, ainda que os mesmos não sejam alunos ativos."
                    }
                },
                {
                    "id": "k9ZgLRWNvo",
                    "type": "paragraph",
                    "data": {
                        "text": "          Além das aulas, o departamento de gestão de RH (ou os colaboradores de maneira individual) também recebem relatórios mensais de presença, realização de prática extra aula e progresso dos alunos em todas as habilidades (fala, escuta, escrita e leitura), assim como uma lista com os nomes dos alunos que se destacaram em cada uma das aulas realizadas no mês."
                    }
                },
                {
                    "id": "lpmtHrg62W",
                    "type": "header",
                    "data": {
                        "text": "O curso é dividido em quatro módulos, a saber:",
                        "level": 6
                    }
                },
                {
                    "id": "ewSX7EhOsM",
                    "type": "list",
                    "data": {
                        "style": "ordered",
                        "items": [
                            "BASIC",
                            "LOWER INTERMEDIATE",
                            "UPPER INTERMEDIATE&nbsp;(Business English)",
                            "ADVANCED&nbsp;(Business English)"
                        ]
                    }
                },
                {
                    "id": "sjM1Z-8Sqi",
                    "type": "paragraph",
                    "data": {
                        "text": "          O material do curso é liberado via plataforma e pode ser usado por qualquer aparelho que leia arquivos PDF (celular, tablet, kindle, laptop, computador etc) e é liberado assim que o registro é realizado. Mesmo sendo em PDF o material conta com mídia de áudio inserida que pode ser acessada diretamente pelo celular, mesmo enquanto offline. Para aqueles que ainda preferem escrever e ter mais contato com o papel para melhor aprendizado, oferecemos a opção de impressão de material dentro da plataforma."
                    }
                },
                {
                    "id": "koJbAFPYhc",
                    "type": "paragraph",
                    "data": {
                        "text": "          Também, cada um dos módulos é guiado por meio de gerenciamento das turmas por parte da Becourse e certificação de conclusão de módulo, com um relatório de pontos fortes e sugestões de pontos a serem melhorados. O curso tem duração média de 24 meses, podendo ser concluído antes caso o grupo tenha um rendimento e entregas acima da média, ou um pouco depois para atender a demanda de agendas dos grupos."
                    }
                },
                {
                    "id": "2ig9f4iR12",
                    "type": "paragraph",
                    "data": {
                        "text": "          Os alunos também contam com material de apoio para os casos em que, devido à demanda de trabalho, o colaborador precise faltar a algumas aulas. Tudo isso para que a empresa tenha a tranquilidade de oferecer um benefício que será de fato usufruído pelos colaboradores e seus familiares, aumentando assim o engajamento, entrosamento, a cultura de ownership (sentimento de dono) e a satisfação daqueles que trabalham dentro da empresa."
                    }
                }
            ],
            "version": "2.22.0"
        }
    },
    {
        background: backgrounds.new.bg_007,
        title: "PARTICULARES OU EM GRUPOS",
        subTitle: "",
        description: {
            "time": 1625839727516,
            "blocks": [
                {
                    "id": "m7RmGCPvL-",
                    "type": "header",
                    "data": {
                        "text": "PARTICULARES OU EM GRUPOS",
                        "level": 3
                    }
                },
                {
                    "id": "c8QkPxb6fl",
                    "type": "paragraph",
                    "data": {
                        "text": "          A <i><b>Becourse </b></i>trabalha com atendimento de aulas a grupos privados, assim como com aulas particulares oferecendo um curso de inglês rápido e com vocabulário voltado para adultos - viagens, negócios e trabalho. As aulas são abordadas e direcionadas a conversação. Os alunos interagem por meio de entrevistas em todas as aulas e passam por entrevistas individuais e projetos com frequência, tendo pelo menos um Challenge (desafio) a cada 10 lições. Ou seja, são desafiados a desenvolverem habilidade de conversação durante todas as aulas."
                    }
                },
                {
                    "id": "RNKSLqQhUB",
                    "type": "paragraph",
                    "data": {
                        "text": "          Quanto ao horário das aulas, essas acontecem no horário de maior interesse dos alunos, uma vez que são realizadas via online (verificar disponibilidade para aulas in loco). Os alunos escolhem os dias e horários mais convenientes e a <i><b>Becourse </b></i>realiza as aulas dentro dessa demanda."
                    }
                },
                {
                    "id": "_gRyjgx_DY",
                    "type": "paragraph",
                    "data": {
                        "text": "          A <i><b>Becourse </b></i>conta com aulas na modalidade vídeo conferência desde 2017. Temos uma equipe de teachers Norte-Americanos (que também falam Português fluentemente) e brasileiros que ministram as aulas por vídeo chamada, quando as empresas/pessoas estão em locais onde não temos um teacher residente ou quando os alunos acreditam ser mais conveniente uma aula por vídeo chamada por não estarem na cidade em virtude do trabalho ou por não terem uma sala disponível no local. Também, normalmente marcamos uma aula extra por mês para revisão do que foi perdido usando o horário e dias normais de aula."
                    }
                },
                {
                    "id": "qKhKgRCWU6",
                    "type": "paragraph",
                    "data": {
                        "text": "          As aulas são totalmente voltadas à conversação, tendo uma entrevista no início da aula (a partir da lição 02 revendo o que foi aprendido na lição anterior) e uma entrevista ao final de cada lição para que o tempo todo os alunos estejam na situação de conversação."
                    }
                },
                {
                    "id": "k_shcYd79j",
                    "type": "paragraph",
                    "data": {
                        "text": "          O material é disponibilizado on-line junto com o acesso à plataforma onde o PDF e os áudios do curso são disponibilizados. O material em PDF é editável e acompanha mídia de áudio podendo ser diretamente acessado do PDF. Nosso material pode ser acessado pelo celular, laptop, tablet, computador ou em qualquer dispositivo compatível de acesso com a internet para download, sendo responsivo a aparelhos celulares tanto na plataforma Android quanto IOS (e OS)."
                    }
                },
                {
                    "id": "oq9a9kVYap",
                    "type": "paragraph",
                    "data": {
                        "text": "          Para aqueles que preferem uma experiência mais “tradicional”, existe a possibilidade de imprimir o material para fazerem as anotações como de costume."
                    }
                },
                {
                    "id": "0nKNs4Ix8B",
                    "type": "paragraph",
                    "data": {
                        "text": "          Além das aulas, os alunos também recebem relatórios mensais de presença, realização de prática extra aula e progresso em todas as habilidades (fala, escuta, escrita e leitura) e um certificado correspondente a conclusão de cada um dos quatro módulos:"
                    }
                },
                {
                    "id": "gsZfFnzsa0",
                    "type": "list",
                    "data": {
                        "style": "unordered",
                        "items": [
                            "BASIC",
                            "LOWER INTERMEDIATE",
                            "UPPER INTERMEDIATE (Business English)",
                            "ADVANCED (Business English)"
                        ]
                    }
                },
                {
                    "id": "8xNaiW2sE9",
                    "type": "paragraph",
                    "data": {
                        "text": "          Os alunos também contam com material de apoio para aqueles que, devido à demanda de trabalho e viagens, precisem faltar a algumas aulas. Tudo isso para que tenham a tranquilidade de saber que terão as explicações e acompanhamento necessários para o caso de precisarem ter alguma falta durante o curso, tornando a experiência ainda mais completa e com ótimos resultados para os aqueles que se tornam alunos <b><i>Becourse</i></b>."
                    }
                }
            ],
            "version": "2.22.0"
        }
    },
    {
        background: backgrounds.new.bg_004,
        title: "CURSO TEENS",
        subTitle: "(Adolescentes de 14 a 17 anos)",
        description: {
            "time": 1625840155598,
            "blocks": [
                {
                    "id": "m7RmGCPvL-",
                    "type": "header",
                    "data": {
                        "text": "CURSO TEENS",
                        "level": 3
                    }
                },
                {
                    "id": "MvOgpnammF",
                    "type": "header",
                    "data": {
                        "text": "(Adolescentes de 14 a 17 anos)",
                        "level": 5
                    }
                },
                {
                    "id": "Lv7bbLchjG",
                    "type": "paragraph",
                    "data": {
                        "text": "          A <i>Becourse</i>, que já é uma empresa especializada em ensino de adultos em ambientes corporativos, agora lança <b><i>BECOURSE TEENS</i></b> – Seu filho preparado para o novo futuro."
                    }
                },
                {
                    "id": "ZgU_srAa2h",
                    "type": "paragraph",
                    "data": {
                        "text": "          Durante os anos que trabalhamos exclusivamente com adultos para que se destaquem com inglês e consigam usar essa ferramenta maravilhosa tanto para lazer e viagens quanto para trabalhar, recebemos constantemente o pedido para que algo similar fosse criado para os filhos daqueles que já se beneficiaram com nossos cursos."
                    }
                },
                {
                    "id": "MtinPGmFeZ",
                    "type": "paragraph",
                    "data": {
                        "text": "          Uma vez que a missão da <b><i>Becourse </i></b>é ajudar o Brasil a sair da lista de países que falam uma só língua e entrar para a lista dos países bilíngues, percebemos, então, que deveríamos incluir nossos jovens – seus filhos – que estão na fase de interesse e preparação para o mercado de trabalho para que ingressem em seus ramos de preferência, já com o domínio de inglês."
                    }
                },
                {
                    "id": "XNEm315ob4",
                    "type": "paragraph",
                    "data": {
                        "text": "          Com a nossa experiência trabalhando em parceria com empresas de vários ramos e de diferentes tamanhos, atendendo muitas delas na modalidade <i>In Company</i> – notamos dentro das empresas a diferença que o inglês faz. Notamos, também que a cada dia, os jovens que têm se tornado líderes e crescido de maneira exponencial fazendo carreira e a diferença nas empresas e em nossa comunidade, já falam ou, ao menos, já estudam o inglês como segundo idioma, o que naturalmente abre mais e mais portas ao longo dos anos trazendo segurança profissional e econômica, bem como benefícios de bem-estar para eles e para as famílias que formam."
                    }
                },
                {
                    "id": "8N8I7zAI2c",
                    "type": "header",
                    "data": {
                        "text": "O QUE É DIFERENTE COM A BECOURSE TEENS?",
                        "level": 6
                    }
                },
                {
                    "id": "Pa9OQYm3xm",
                    "type": "paragraph",
                    "data": {
                        "text": "          Pensando em somar esforços e cooperar com aqueles que se preocupam com o futuro já presente dos seus filhos, criamos o <i><b>BECOURSE TEENS</b></i>. Esse curso usará de nossa experiência e time especializado em inglês Business (para negócios e trabalho) para preparar os adolescentes Brasileiros não apenas para que falem inglês, mas para que falem inglês em qualquer situação e assim consigam se posicionar no mercado de trabalho com as melhores oportunidades."
                    }
                },
                {
                    "id": "igTL75b7T2",
                    "type": "paragraph",
                    "data": {
                        "text": "          <b><i>BECOURSE TEENS</i></b> contará com lições que abordam inglês do dia-a-dia e inglês para o trabalho. A primeira metade do curso (50 lições) apresenta vocabulário cotidiano para que o aluno aprenda a conversar em todas as situações diárias como ir ao banco, fazer compras, utilizar serviços de hotelaria etc."
                    }
                },
                {
                    "id": "62dCMHDXix",
                    "type": "paragraph",
                    "data": {
                        "text": "          A segunda parte do curso (40 lições) apresenta vocabulário para trabalho e negócios abordando temas como entrevistas de emprego, negociações, venda, tecnologia etc., passando por todas os departamentos que uma empresa grande tem, desde a entrada pelo RH até presidir uma reunião e fazer negociações como presidente da empresa."
                    }
                },
                {
                    "id": "fEjITUDHLp",
                    "type": "paragraph",
                    "data": {
                        "text": "          Além disso tudo, os alunos terão <b>duas entrevistas em grupo a cada lição</b>, e uma entrevista com um americano nativo a cada módulo, assim como apresentações de projetos em inglês, fazendo com que pratiquem em uma situação real com um americano nativo especialista."
                    }
                },
                {
                    "id": "fjOaLW8idq",
                    "type": "header",
                    "data": {
                        "text": "MODALIDADE DO CURSO",
                        "level": 6
                    }
                },
                {
                    "id": "tN5gntJdPK",
                    "type": "paragraph",
                    "data": {
                        "text": "          O curso <b><i>BECOURSE TEENS </i></b>é exclusivamente feito na modalidade On-line, por meio de aulas de vídeo conferência. Essa modalidade, além de poupar os pais da preocupação de ter de ir levar e buscar seus filhos no curso, permitirá que eles façam as aulas em qualquer lugar, uma vez que podem acessar o grupo por meio do celular ou computador estejam onde estiverem, desde que tenham acesso a internet."
                    }
                },
                {
                    "id": "1ae-Tk3jz6",
                    "type": "paragraph",
                    "data": {
                        "text": "          Além das aulas e domínio do inglês, os jovens alunos da <b><i>Becourse </i></b>farão e aprenderão a controlar uma rede de network entre eles, uma vez que a ideia é preparar e lançar esses jovens no mercado de trabalho mundial, tendo conexões e amizades importantes para ajudá-los com essa tarefa. Essa rede de contatos, além de fomentar o cooperativismo auxilia no convívio e envolvimento social dos nossos jovens com outras pessoas com objetivos semelhantes."
                    }
                },
                {
                    "id": "PZqx9zqj6s",
                    "type": "paragraph",
                    "data": {
                        "text": "          O material do curso também é liberado de maneira totalmente virtual por meio da plataforma da <i><b>Becourse</b></i>, o que além de cooperar com o meio ambiente poupa o aluno de ter de carregar livros, deixando-o à vontade para fazer todas as tarefas e participar das aulas usando seu celular ou laptop ou qualquer que seja o aparelho de uso pessoal. Ou seja, auxiliamos seu filho a desenvolver-se intelectual e socialmente."
                    }
                },
                {
                    "id": "wQ8n24Qx1G",
                    "type": "paragraph",
                    "data": {
                        "text": "          Os pais ou responsáveis que têm seus filhos estudando na <b><i>Becourse </i></b>recebem acesso a relatórios de presença, trabalho dentro e fora de aula e de progresso em todas as 4 habilidades (fala, escuta, escrita e leitura) de seus filhos, a fim de poderem acompanhar de perto o desenvolvimento e domínio do idioma dos seus filhos em cada um dos módulos a seguir:"
                    }
                },
                {
                    "id": "DT7vuujNQL",
                    "type": "list",
                    "data": {
                        "style": "unordered",
                        "items": [
                            "BASIC",
                            "LOWER INTERMEDIATE",
                            "UPPER INTERMEDIATE (Business English)",
                            "ADVANCED (Business English)."
                        ]
                    }
                }
            ],
            "version": "2.22.0"
        }
    },
    {
        background: backgrounds.new.bg_002,
        title: "CHAT COM AMERICANOS",
        subTitle: "(Somente online - para quem já tem inglês intermediário ou avançado).",
        description: null
    },
];

const getListCourses = async () => {
    let list = allCourses.map((course, index) => {
        return {
            background: course.background,
            title: course.title,
            subTitle: course.subTitle,
            id: index
        }
    })

    return list;
}

const getCourseDetail = async (course_index) => {
    return allCourses[course_index];
}

export { getListCourses, getCourseDetail }