import React, { useState, useEffect } from "react";
import { getMyCertificates } from "../../../../repositories/user_repository";

import {
  Avatar,
  CircularProgress,
  makeStyles,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const CertificadosCard = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [certificates, setCertificates] = useState([]);

  const getCertificates = async () => {
    setLoading(true);
    let result = await getMyCertificates();

    if (result.success) {
      setCertificates(result.certificados);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCertificates();
  }, []);

  return (
    <div className="card h-100">
      <div className="card-header pb-0 p-3">
        <h6 className="mb-0">Certificados</h6>
        <hr />
      </div>
      <div className="card-body p-3">
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          {loading ? (
            <ListItem button style={{ borderRadius: 50 }}>
              <CircularProgress style={{ marginRight: 15 }} />
              <ListItemText primary={"Carregando certificados..."} />
            </ListItem>
          ) : (
            certificates.map((c, iterator) => {
              return (
                <ListItem
                  onClick={() => {
                    window.open(
                      `https://becourse.com.br/becourse${c.url.replace("..", '')}${
                        c.nome_unico
                      }`,
                      "_blank"
                    );
                  }}
                  key={`${iterator}___certificate-${c.id}`}
                  button
                  style={{ borderRadius: 50 }}
                >
                  <ListItemIcon>
                    <Avatar>{"C"}</Avatar>
                  </ListItemIcon>
                  <ListItemText primary={c.nome_unico.replace(".pdf", "")} />
                </ListItem>
              );
            })
          )}
          {certificates.length == 0 && !loading && (
            <ListItem button style={{ borderRadius: 50 }}>
              <ListItemText
                primary={
                  "Infelizmente não encontramos nenhum certificado... :("
                }
              />
            </ListItem>
          )}
        </List>
      </div>
    </div>
  );
};

export default CertificadosCard;
