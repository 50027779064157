import React, { useEffect, useState } from 'react';
import { logos } from '../../../core/app_images';
import TopNavigator from '../components/navigator/top_navigator';
import Sidebar from '../components/sidebar/sidebar';
import '../../../assets/css/soft-ui-dashboard.css';
import { CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router';
import { getDiscussaoById } from '../../../repositories/turma_repository';
import DefaultTextEditor from '../../shared/components/editor/DefaultTextEditor';
import { Center } from '@chakra-ui/layout';
import Footer from '../../shared/components/footer/footer';


const StudentDiscussionPage = () => {
    const { id } = useParams();
    const [showNavbar, setShowNavbar] = useState(false);
    const [loading, setLoading] = useState(true);
    const [discussion, setDiscussion] = useState({});
    const onChangeNavbar = (opt) => {
        setShowNavbar(opt);
    }

    useEffect(() => {
        const getDiscussion = async () => {
            setLoading(true)
            let result = await getDiscussaoById(id);

            if (result.success) {
                setDiscussion(result.discussao)
            }
            setLoading(false)
        }
        getDiscussion()
    }, [id])

    return (
        <div className={`g-sidenav-show bg-gray-100${showNavbar ? " g-sidenav-pinned" : ""}`}>
            <Sidebar logo={logos.navbar} show={showNavbar} background={'bg-main'} onCloseSidebar={onChangeNavbar} />
            <div className="main-content position-relative bg-gray-100">
                <TopNavigator title="Discussão" breadcrumps={[{ title: 'Início', link: '/student' }, { title: 'Discussões', link: '/student/discussions' }]} showNavbar={onChangeNavbar} navbarOpen={showNavbar} />
                <div className="container-fluid">
                    <div className="row py-4">
                        <div className="col-12 col-md-12">
                            <div className="row">
                                <div className="col-12 py-4" >
                                    <div className="container">
                                        {
                                            loading ?
                                                <Center>
                                                    <CircularProgress />
                                                </Center>
                                                :
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h2>
                                                            {discussion.assunto}
                                                        </h2>
                                                        <div className="my-2">
                                                            {
                                                                discussion.tags?.split(',').map((tag) => {
                                                                    return <span className="badge bg-gradient-light text-dark mx-1">{tag}</span>
                                                                })
                                                            }
                                                        </div>
                                                        <DefaultTextEditor
                                                            readonly
                                                            value={discussion.descricao}
                                                        />
                                                    </div>
                                                </div>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default StudentDiscussionPage;
