import { logos } from "../../../core/app_images";

const clients_list = [
    {
        title: "Sicredi",
        image: logos.clients.sicredi
    },
    {
        title: "Sicoob",
        image: logos.clients.sicoob
    },
    {
        title: "Domani",
        image: logos.clients.domani
    },
    {
        title: "Brf",
        image: logos.clients.brf
    },
    {
        title: "Localiza",
        image: logos.clients.localiza
    },
    {
        title: "CVL",
        image: logos.clients.cvl
    },
    {
        title: "Pantanal Shopping",
        image: logos.clients.pantanalShopping
    },
    {
        title: "LATAM",
        image: logos.clients.latam
    },
    {
        title: "Scheffer",
        image: logos.clients.scheffer
    },
    {
        title: "Azul",
        image: logos.clients.azul
    },
    {
        title: "Elétrica Paraná",
        image: logos.clients.eletricaParana
    },
    {
        title: "Gran Odara Hotel",
        image: logos.clients.odara
    },
    {
        title: "Agro Amazônia",
        image: logos.clients.agroAmazonia
    },
    {
        title: "ABIH",
        image: logos.clients.abih
    },
    {
        title: "Brookfield",
        image: logos.clients.brookfield
    },
    {
        title: "Luciana Duarte Azevedo",
        image: logos.clients.lucianaDuarte
    },
    {
        title: "SEBRAE MT",
        image: logos.clients.sebraeMT
    },
    {
        title: "SESC Pantanal",
        image: logos.clients.sescPantanal
    },
    {
        title: "Sescoop / MT",
        image: logos.clients.sescoopMT
    },
    {
        title: "Solução Cosméticos",
        image: logos.clients.solucaoCosmeticos
    },

];

export { clients_list }