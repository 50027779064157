import React, { useEffect, useState } from 'react';
import { logos } from '../../../core/app_images';
import TopNavigator from '../components/navigator/top_navigator';
import Sidebar from '../components/sidebar/sidebar';
import '../../../assets/css/soft-ui-dashboard.css';
import { useParams } from 'react-router';
import { getSuggestionById } from '../../../repositories/user_repository';
import DefaultTextEditor from '../../shared/components/editor/DefaultTextEditor';
import { CircularProgress } from '@material-ui/core';
import { Center } from '@chakra-ui/layout';
import Footer from '../../shared/components/footer/footer';

const StudentSuggestionPage = () => {
    const { id } = useParams();
    const [showNavbar, setShowNavbar] = useState(false);
    const [loading, setLoading] = useState(true);
    const [suggestion, setSuggestion] = useState({});
    const onChangeNavbar = (opt) => {
        setShowNavbar(opt);
    }

    useEffect(() => {
        const getSuggestion = async () => {
            setLoading(true)
            let result = await getSuggestionById(id);

            if (result.success) {
                setSuggestion(result.sugestao)
            }

            setLoading(false)
        }
        getSuggestion()
    }, [id])

    return (
        <div className={`g-sidenav-show bg-gray-100${showNavbar ? " g-sidenav-pinned" : ""}`}>
            <Sidebar logo={logos.navbar} show={showNavbar} background={'bg-main'} onCloseSidebar={onChangeNavbar} />
            <div className="main-content position-relative bg-gray-100">
                <TopNavigator title="Sugestão" breadcrumps={[{ title: 'Início', link: '/student' }, { title: 'Sugestões', link: '/student/suggestions' }]} showNavbar={onChangeNavbar} navbarOpen={showNavbar} />
                <div className="container-fluid">
                    <div className="row py-4">
                        <div className="col-12 col-md-12">
                            <div className="row">
                                <div className="col-12 py-4" >
                                    <div className="container">
                                        {
                                            loading ?
                                                <Center>
                                                    <CircularProgress />
                                                </Center>
                                                :
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h2>
                                                            {suggestion.titulo}
                                                        </h2>
                                                        <DefaultTextEditor
                                                            readonly
                                                            value={suggestion.descricao}
                                                        />
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default StudentSuggestionPage;
