import React from "react";
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { isAuthenticated } from "../services/auth";
import routes_list from "./routes_list";

const DOMAIN = process.env.REACT_APP_SITE_DOMAIN;

const PrivateRoute = ({ canonical, route, component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isAuthenticated() ? (
                <>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{`${route.title} | Becourse`}</title>
                        <link rel="canonical" href={`https://${DOMAIN}${canonical}`} />
                        <link rel="description" content={route.description} />
                    </Helmet>
                    <Component {...props} />
                </>
            ) : (
                <Redirect
                    to={{ pathname: `/login`, state: { from: props.location } }}
                />
            )
        }
    />
);

const SimpleRoute = ({ route, canonical, component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${route.title} | Becourse`}</title>
                <link rel="canonical" href={`https://${DOMAIN}${canonical}`} />
                <link rel="description" content={route.description} />
            </Helmet>
            <Component {...props} />
        </>}
    />
)

const Routes = () => {

    const generateRoute = (route) => {
        let canonical = route.link.split(':')[0];
        if (route.protected) {
            return <PrivateRoute canonical={canonical} route={route} key={route.title} exact path={route.link} component={route.component} />
        }
        return (
            <SimpleRoute exact canonical={canonical} route={route} key={route.title} path={route.link} component={route.component} />
        )
    }

    return (
        <BrowserRouter>
            <Switch>
                {routes_list.map((route) => {
                    return generateRoute(route)
                })}
            </Switch>
        </BrowserRouter>
    )
};

export default Routes;