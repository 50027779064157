import React, { useEffect, useState } from 'react';
import { logos } from '../../../core/app_images';
import TopNavigator from '../components/navigator/top_navigator';
import Sidebar from '../components/sidebar/sidebar';
import '../../../assets/css/soft-ui-dashboard.css';
import { useParams } from 'react-router';
import { getTurmaAllData } from '../../../repositories/turma_repository';
import CardTimeline from '../components/cards/card_timeline';
import Footer from '../../shared/components/footer/footer';
import { Link } from 'react-router-dom';

const StudentTurmaPage = () => {
    const { id } = useParams()
    const [showNavbar, setShowNavbar] = useState(false);
    const [turma, setTurma] = useState({ topics: [] });
    const colors = ['bg-gradient-success','bg-gradient-primary','bg-gradient-secondary','bg-gradient-warning'];

    const onChangeNavbar = (opt) => {
        setShowNavbar(opt);
    }

    useEffect(() => {
        const getTurma = async () => {
            let result = await getTurmaAllData(id)

            if (result.success) {
                setTurma(result.turma)
            }
        }
        getTurma()

    }, [id])

    return (
        <div className={`g-sidenav-show bg-gray-100${showNavbar ? " g-sidenav-pinned" : ""}`}>
            <Sidebar logo={logos.navbar} show={showNavbar} background={'bg-main'} onCloseSidebar={onChangeNavbar} />
            <div className="main-content position-relative bg-gray-100" style={{ height: '100vh' }}>
                <TopNavigator title="Aulas da Turma" breadcrumps={[{ title: 'Início', link: '/student' }, { title: 'Turmas', link: '/student/classes' }]} showNavbar={onChangeNavbar} navbarOpen={showNavbar} />
                <div className="container-fluid">
                    <div className="row py-4" style={{ minHeight: '75vh' }}>
                        <div className="col-12 col-md-12">
                            <div className="row">
                                <div className="col-12" >
                                    <div className="card my-4">
                                        <div className="card-body p-3">
                                            <h6 className="mb-0">{turma.nome}</h6>
                                        </div>
                                    </div>

                                    <div className="container">
                                        <div className="row">
                                            { turma.niveis && 
                                              turma.niveis.map((nivel, index) => {
                                                return (
                                                    <div key={index} className="col-sm">
                                                        <div><span>{nivel.nome}</span></div>
                                                        <div>
                                                            <div className="progress-wrapper">
                                                                <div className="progress-info">
                                                                    <div className="progress-percentage">
                                                                    <span className="text-sm font-weight-bold">{nivel.porcentagem ? nivel.porcentagem.toFixed(2) : '0'}%</span>
                                                                    </div>
                                                                </div>
                                                                <div style={{height: '10px'}} className="progress">
                                                                    <div className={`progress-bar ${colors[index]}`} role="progressbar" aria-valuenow={nivel.porcentagem  } aria-valuemin="0" aria-valuemax="100" style={{width: `${nivel.porcentagem ? nivel.porcentagem : 0}%`}}></div>
                                                                </div>
                                                            </div>  

                                                            { 
                                                                nivel.porcentagem == '100' ? (
                                                                    <Link target="_blank" key={index} to={`/student/certificado/${turma.id}/${nivel.id}`} className="col-12 col-md-4">
                                                                        <div className="card cursor-pointer">
                                                                            <div className="card-body">
                                                                                Baixar certificado
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                ) : (
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                                <small>Certificado: Em breve</small>
                                                                        </div>
                                                                    </div>
                                                                )                                                        
                                                            }

                                                        </div>
                                                    </div>
                                                )
                                              })
                                            }
                                        </div>
                                    </div>

                                    <div className="row my-4">
                                        {
                                            turma.topics.map((item, i) => {
                                                return (
                                                    <div key={`timeline_topics__${i}`} className="col-12 col-md-6">
                                                        <CardTimeline showMore={false} topic={item} turma={turma}/>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default StudentTurmaPage;
