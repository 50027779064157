import React, { useState, useEffect } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { Skeleton } from '@material-ui/lab';
import { getSuggestions } from '../../../../repositories/user_repository';
import { useHistory } from 'react-router';
import { getFormatedDate } from '../../../../utils/functions';
import CreateSuggestion from '../modals/create_suggestion';

const TableSuggestions = () => {

    const navigate = useHistory();
    const [openedModal, setOpenedModal] = useState(false);
    const [loadingRows, setLoadingRows] = useState(true)
    const [rows, setRows] = useState([])
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePagination = (_, value) => {
        setPage(value);
    }



    const tableRowGenerator = (item, loading = false) => {

        if (loading) {
            return (
                <tr key={`suggestion_table__loading--`}>
                    <td>
                        <Skeleton variant="text" />
                    </td>
                    <td>
                        <Skeleton variant="text" />
                    </td>
                    <td className="align-middle">
                        <Skeleton variant="text" />
                    </td>
                </tr>
            )
        }
        else {
            return (
                <tr onClick={() => navigate.push(`/student/suggestion/${item.id}`)} className="cursor-pointer" key={`discussion_table__${item.id}`}>
                    <td>
                        <div className="d-flex px-2 py-1">
                            <div className="d-flex flex-column justify-content-center">
                                <h6 className="mb-0 text-sm">{item.titulo}</h6>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="text-xs font-weight-bold">{item.criador.nome}</span>
                    </td>
                    <td className="align-middle">
                        <span className="text-xs font-weight-bold">{getFormatedDate(new Date(item.modified), true)}</span>
                    </td>
                </tr>
            )
        }
    }

    const openModalCreateDiscussao = () => {
        setOpenedModal(!openedModal)
    }

    const savedSuggestion = () => {
        getMySuggestions();
    }

    const getMySuggestions = async () => {
        setLoadingRows(true)
        let result = await getSuggestions()
        if (result.success) {
            setRows(result.sugestoes)
        }
        setLoadingRows(false)
    }

    useEffect(() => {
        getMySuggestions()
        setRowsPerPage(5)
    }, [])

    return (
        <div className="card">
            <div className="card-header pb-0">
                <div className="row">
                    <div className="col-lg-6 col-7">
                        <h6>Sugestões</h6>
                        <p className="text-sm mb-0">
                            <span className="font-weight-bold ms-1">{rows.length}</span> {rows.length > 1 ? 'Sugestões criadas' : 'Sugestão criada'}
                        </p>
                    </div>
                    <div className="col-lg-6 col-5 my-auto text-end">
                        <button className="btn btn-main" onClick={openModalCreateDiscussao}>
                            <i className="fa fa-plus text-white"></i> <span className="text-white">{"Criar Sugestão"}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-body px-0 pb-2">
                <div className="table-responsive">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Título</th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Criador</th>
                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Última postagem</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                rows.map((item, i) => {
                                    if (page > 0) {
                                        if (((page - 1) * rowsPerPage <= i + 1) && (page * rowsPerPage >= i + 1))
                                            return (tableRowGenerator(item))
                                    }
                                    else {
                                        if (page * rowsPerPage >= i + 1)
                                            return (tableRowGenerator(item))
                                    }
                                })

                            }
                            {
                                rows.length === 0 && loadingRows &&
                                tableRowGenerator({}, true)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card-footer">
                <Pagination page={page} value={page} count={rows.length <= rowsPerPage ? 1 : parseInt((rows.length / rowsPerPage) + 1)} onChange={handleChangePagination} showFirstButton showLastButton />
            </div>

            <CreateSuggestion
                open={openedModal}
                onClose={openModalCreateDiscussao}
                onSuccess={savedSuggestion}
            />
        </div>
    )
}

export default TableSuggestions