import { Divider, Drawer, List, ListItem, ListItemText, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Link as InternalLink } from "react-scroll"
import { logos } from '../../../../core/app_images'
import { top_link_list, top_right_links } from './top_link_list'

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
})

const TopNavbar = (props) => {
    const classes = useStyles()
    const { fixed } = props

    const [drawerOpen, setDrawerOpen] = useState(false)

    const closeDrawer = () => {
        setDrawerOpen(false)
    }

    const openDrawer = () => {
        setDrawerOpen(true)
    }

    const renderLeftLink = (link, drawer = false) => {
        if (link.local) {
            return (
                <li key={`${link.title}__left-top-link`} className="nav-item px-3">
                    {
                        link.inPage ?
                            <InternalLink onClick={() => drawer && closeDrawer()} to={link.url} className="nav-link text-dark opacity-8 cursor-pointer">
                                {drawer ? <ListItem button>
                                    <ListItemText primary={link.title} />
                                </ListItem> :
                                    link.title}
                            </InternalLink>
                            :
                            <Link onClick={() => drawer && closeDrawer()} to={link.url} className="nav-link text-dark opacity-8 cursor-pointer">
                                {drawer ? <ListItem button>
                                    <ListItemText primary={link.title} />
                                </ListItem> :
                                    link.title}
                            </Link>
                    }
                </li>
            )
        } else {
            return (
                <li key={`${link.title}__left-top-link`} className="nav-item px-3 cursor-pointer">
                    <a onClick={() => drawer && closeDrawer()} href={link.url} className="nav-link text-dark opacity-8">
                        {drawer ? <ListItem button>
                            <ListItemText primary={link.title} />
                        </ListItem> :
                            link.title}
                    </a>
                </li>
            )
        }
    }

    const renderRightLink = (link, drawer = false) => {

        if (link.type === 'link') {
            if (link.local) {
                return (
                    <li key={`${link.title}__right-top-link`} className="nav-item my-auto px-3">
                        {
                            link.inPage ?
                                <InternalLink onClick={() => drawer && closeDrawer()} to={link.url} className="nav-link text-dark opacity-8">
                                    {drawer ? <ListItem button>
                                        <ListItemText primary={link.title} />
                                    </ListItem> :
                                        link.title}
                                </InternalLink>
                                :
                                <Link onClick={() => drawer && closeDrawer()} to={link.url} className="nav-link text-dark opacity-8">
                                    {drawer ? <ListItem button>
                                        <ListItemText primary={link.title} />
                                    </ListItem> :
                                        link.title}
                                </Link>
                        }
                    </li>
                )
            } else {
                return (
                    <li key={`${link.title}__right-top-link`} onClick={() => drawer && closeDrawer()} className="nav-item my-auto px-3">
                        <a href={link.url} className="nav-link text-white opacity-8">
                            {drawer ? <ListItem button>
                                <ListItemText primary={link.title} />
                            </ListItem> :
                                link.title}
                        </a>
                    </li>
                )
            }
        } else if (link.type === 'button') {
            return (
                link.inPage ?
                    <InternalLink key={`${link.title}__right-top-button`} onClick={() => drawer && closeDrawer()} to={link.url} className="nav-link text-white opacity-8">
                        {drawer ? <ListItem button>
                            <button className="btn btn-sm bg-dark text-white mb-0 me-1 h-100">
                                {link.title}
                            </button>
                        </ListItem> : <button className="btn btn-sm bg-dark text-white mb-0 me-1 h-100">
                            {link.title}
                        </button>}
                    </InternalLink>
                    :
                    <Link key={`${link.title}__right-top-button`} onClick={() => drawer && closeDrawer()} to={link.url} className="nav-link text-white opacity-8">
                        {drawer ? <ListItem button onClick={() => closeDrawer()}>
                            <button className="btn btn-sm bg-dark mb-0 me-1 h-100">
                                {link.title}
                            </button>
                        </ListItem> : <button className="btn btn-sm bg-dark text-white h-100 mb-0 me-1">
                            {link.title}
                        </button>}
                    </Link>
            )
        } else if (link.type === 'special') {
            return (
                <Link key={`${link.title}__right-top-button`} onClick={() => drawer && closeDrawer()} to={link.url} className="text-white opacity-8">
                    {drawer ? <ListItem button onClick={() => closeDrawer()}>
                        <button className="btn btn-sm bg-dark mb-0 me-1 h-100">
                            {link.title}
                        </button>
                    </ListItem> : <button className="btn btn-sm bg-main text-white mb-0 me-1" style={{ borderRadius: 0, minHeight: 60 }}>
                        {link.title}
                    </button>}
                </Link>
            )
        } else {
            return <></>
        }
    }

    return (
        <nav
            className={`navbar navbar-expand-lg navbar-white bg-light blur shadow-blur z-index-3 p-0 ${fixed && 'fixed-top'}`}>
            <div className="container-fluid" style={{ paddingRight: 0, marginRight: 0, minHeight: 60 }}>
                <a className="navbar-brand text-white" href="/" rel="tooltip" title="Becourse" data-placement="bottom">
                    <img className="rounded" src={logos.navbar} height={'100%'} alt="Becourse logomarca" />
                </a>
                <button className="navbar-toggler" onClick={openDrawer} style={{ border: 'none' }} type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                    <div className="sidenav-toggler-inner">
                        <i className="sidenav-toggler-line"></i>
                        <i className="sidenav-toggler-line"></i>
                        <i className="sidenav-toggler-line"></i>
                    </div>

                </button>
                <div className="collapse navbar-collapse" id="navigation">
                    <ul className="navbar-nav navbar-nav-hover mx-auto">
                        {
                            top_link_list.map(link => {
                                return renderLeftLink(link)
                            })
                        }
                    </ul>

                    <ul className="navbar-nav ms-auto">
                        {
                            top_right_links.map(link => {
                                return renderRightLink(link)
                            })
                        }

                    </ul>
                </div>
            </div>
            <Drawer anchor={'right'} style={{ border: 'none' }} open={drawerOpen} onClose={closeDrawer}>
                <div
                    className={`${classes.list} bg-gradient-dark text-white h-100`}
                    role="presentation"
                    onClick={closeDrawer}
                    onKeyDown={closeDrawer}
                >
                    <List>
                        {top_link_list.map((link) => (
                            renderLeftLink(link, true)
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {top_right_links.map((link) => (renderRightLink(link, true)
                        ))}
                    </List>
                </div>
                {/* <div className="bg-gradient-dark h-100" style={{ border: 'none' }} id="navigation__drawer">
                    <ul className="navbar-nav navbar-nav-hover mx-auto">
                        {
                            top_link_list.map(link => {
                                return renderLeftLink(link)
                            })
                        }
                    </ul>

                    <ul className="navbar-nav ms-auto">
                        {
                            top_right_links.map(link => {
                                return renderRightLink(link)
                            })
                        }

                    </ul>
                </div> */}
            </Drawer>
        </nav>
    )
}

export default TopNavbar