import React, { useState } from "react";
import { Link } from "react-router-dom";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { sidebar_links, sidebar_other_links } from "../../tools/sidebar_links";
import "./sidebar.css";
import { logout } from "../../../../services/auth";
import { containerCard, containerCardFastChild, itemsCard, MotionDiv, MotionLi, MotionUl } from "../../../shared/tools/animations";

const Sidebar = (props) => {
    const { logo, background, show, onCloseSidebar } = props;
    const [bg, setBg] = useState(background ? background : "bg-transparent");

    const onClose = () => {
        onCloseSidebar(!show);
    }

    const verifyIsActive = (link) => {
        let actualPath = window.location.pathname;

        if (actualPath === link.link) {
            return true;
        }

        return false;
    }

    const linkComponentGenerate = (link, indice) => {
        let activedLink = verifyIsActive(link);

        return (
            <MotionLi key={`link_${indice}`} variants={itemsCard} className="nav-item">
                <Link className={`nav-link${activedLink ? ' active' : ' text-white'}`} to={link.link}>
                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                        {activedLink ? link.IconSelected : link.Icon}
                    </div>
                    <span className="nav-link-text ms-1">{link.title}</span>
                </Link>
            </MotionLi>
        )
    }

    const doLogout = () => {
        let isLogout = logout();

        if (isLogout) window.location.href = '/';
    }

    return (
        <>
            <div className={`sidebar-overlay ${show && 'is-active'}`} onClick={onClose}></div>
            <aside className={`sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-left ms-3 ${show ? "blur-main z-index-sticky" : bg}`} id="sidenav-main" style={{overflow: 'hidden'}}>
                <MotionDiv
                    initial="hidden"
                    animate="visible"
                    variants={containerCard}
                    style={{overflow: 'hidden'}}
                >
                    <MotionDiv variants={itemsCard} className="sidenav-header">
                        <i className={`fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute right-0 top-0 ${show ? "" : "d-none d-xl-none"}`} onClick={onClose} aria-hidden="true" id="iconSidenav"></i>
                        <a className="navbar-brand m-0 text-center rounded" href="#">
                            <img src={logo} className="navbar-brand-img h-100" alt="Logo Becourse" />
                        </a>
                    </MotionDiv>
                    <hr className="horizontal dark mt-0" />
                    <div className="collapse navbar-collapse h-auto w-auto" id="sidenav-collapse-main" style={{overflow: 'hidden'}}>
                        <MotionUl variants={containerCardFastChild} className="navbar-nav" style={{overflow: 'hidden'}}>

                            {
                                sidebar_links.map((link, i) => {
                                    return (
                                        linkComponentGenerate(link, i)
                                    )
                                })
                            }
                            <MotionLi variants={itemsCard} className="nav-item mt-3">
                                <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder text-white opacity-6">Outras páginas</h6>
                            </MotionLi>
                            {
                                sidebar_other_links.map((link, i) => {
                                    return (
                                        linkComponentGenerate(link, i)
                                    )
                                })
                            }
                            <MotionLi variants={itemsCard} className="nav-item">
                                <a href={'#'} className="nav-link text-white cursor-pointer" onClick={doLogout}>
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white color-background text-center me-2 d-flex align-items-center justify-content-center">
                                        <ExitToAppIcon style={{ color: '#3A416F', fontSize: 16 }} />
                                    </div>
                                    <span className="nav-link-text ms-1">Sair</span>
                                </a>
                            </MotionLi>
                        </MotionUl>
                    </div>
                </MotionDiv>
            </aside>
        </>
    )
}

export default Sidebar;