import React, { useState } from "react";
import {
  Dialog,
  AppBar,
  Slide,
  Typography,
  IconButton,
  Toolbar,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { Center } from "@chakra-ui/layout";
import randomColor from "randomcolor";
import ReactApexChart from "react-apexcharts";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AllEvolutionDialog = (props) => {
  const { open, onClose, evolutions } = props;
  const graficLabels = evolutions.map((e) => {
    return e.etapa;
  });
  const colorArray = randomColor({
    count: 4,
  });
  const [loading, setLoading] = useState(false);

  const data = {
    series: [
      {
        name: "Escrevendo",
        data: evolutions.map((e) => e.escrevendo),
        background: colorArray[0],
      },
      {
        name: "Falando",
        data: evolutions.map((e) => e.falando),
        background: colorArray[1],
      },
      {
        name: "Leitura",
        data: evolutions.map((e) => e.leitura),
        background: colorArray[2],
      },
      {
        name: "Ouvindo",
        data: evolutions.map((e) => e.ouvindo),
        background: colorArray[3],
      },
    ],
    options: {
      chart: {
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "string",
        categories: graficLabels,
      },
    },
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className="bg-main position-static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {"Evolução Completa do Aluno"}
          </Typography>
        </Toolbar>
      </AppBar>
      {loading ? (
        <Center className="my-auto">
          <CircularProgress />
        </Center>
      ) : (
        <div className="container row pt-3">
          {evolutions && (
            <div className="">
              <ReactApexChart
                options={data.options}
                series={data.series}
                type="area"
                height={500}
              />
            </div>
          )}
          {evolutions.length == 0 && (
            <div className="card p-4">
              <p>Nenhuma evolução encontrada.</p>
            </div>
          )}
        </div>
      )}
    </Dialog>
  );
};

export default AllEvolutionDialog;
