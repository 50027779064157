import justChilling from '../assets/videos/justchilling.mp4'
import gettingReady from '../assets/videos/gettingready.mp4'
import stopBy from '../assets/videos/stopby.mp4'

const clews = [
    {
        title: '#JustChilling',
        video: justChilling,
    },
    {
        title: '#GettingReady',
        video: gettingReady,
    },
    {
        title: '#StopBy',
        video: stopBy,
    },
]

export { clews }