import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  AppBar,
  Slide,
  Typography,
  IconButton,
  Toolbar,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import {
  getUrlToDownloadLessonFile,
  getExtrasFiles,
  getLessonsFiles,
} from "../../../../repositories/turma_repository";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import { Box, LinearProgress } from "@mui/material";
import { Center } from "@chakra-ui/layout";
import { useHistory } from "react-router";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        {props.percent ? (
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        ) : (
          <Typography variant="body2" color="text.secondary">
            {props.value}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const EvolutionDetailCard = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useHistory();

  const { open, onClose, evolutionSelected } = props;
  const [loading, setLoading] = useState(false);

  const getPercent = (val, max) => {
    return (100 * val) / max;
  };

  return (
    <Dialog
      //   fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      style={{ borderRadius: 50 }}
    >
      <AppBar className="bg-main position-static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {evolutionSelected && evolutionSelected.etapa}
          </Typography>
        </Toolbar>
      </AppBar>
      {loading ? (
        <Center className="my-auto">
          <CircularProgress />
        </Center>
      ) : (
        evolutionSelected != null && (
          <div className="container row">
            <div className="col-12 p-5 pb-0">
              <p>
                <span className="font-weight-bold">{"Aluno(a): "}</span>{" "}
                {evolutionSelected.aluno}
              </p>
              <p>
                <span className="font-weight-bold">{"Falando: "}</span>{" "}
                {evolutionSelected.falando}{" "}
                <LinearProgressWithLabel
                  percent
                  value={getPercent(evolutionSelected.falando, 10)}
                />
              </p>
              <p>
                <span className="font-weight-bold">{"Escrevendo: "}</span>{" "}
                {evolutionSelected.escrevendo}{" "}
                <LinearProgressWithLabel
                  percent
                  value={getPercent(evolutionSelected.escrevendo, 10)}
                />
              </p>
              <p>
                <span className="font-weight-bold">{"Leitura: "}</span>{" "}
                {evolutionSelected.leitura}{" "}
                <LinearProgressWithLabel
                  percent
                  value={getPercent(evolutionSelected.leitura, 10)}
                />
              </p>
              <p>
                <span className="font-weight-bold">{"Ouvindo: "}</span>{" "}
                {evolutionSelected.ouvindo}{" "}
                <LinearProgressWithLabel
                  percent
                  value={getPercent(evolutionSelected.ouvindo, 10)}
                />
              </p>
            </div>
            <div className="col-12 p-4">
              <div className="card">
                <div className="card-header pb-0 p-3">
                  <h6 className="mb-0">{"Justificativa:"}</h6>
                  <hr />
                </div>
                <div className="card-body">
                  <p>{evolutionSelected.justificativa}</p>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </Dialog>
  );
};

export default EvolutionDetailCard;
