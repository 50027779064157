import React, {
    useEffect,
    useState
} from 'react';
import { backgrounds, defaultImages, logos } from '../../../core/app_images';
import '../../../assets/css/soft-ui-dashboard.css';

import { Button, FormControl, InputLabel, Select, MenuItem, Slide, TextField, Grow } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import TopNavbar from '../components/navigation/top_navbar';
import Slider from 'react-slick';
import PlayerComponent from '../../shared/components/players/player_component';
import SiteFooter from '../components/navigation/footer';
import { clews } from '../../../core/app_videos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ClientCard from '../components/cards/client_card';
import { clients_list } from '../utils/clients_list';
import ScrollToTop from '../../shared/components/buttons/scroll_to_top';
import { Link as InternalLink } from "react-scroll"
import { getCourseDetail, getListCourses } from '../../../repositories/course_repository';
import ModalCourses from '../components/modals/modal_courses';
import WhatsAppButton from '../../shared/components/buttons/whatsapp_button';

const LandingPageOLD = () => {
    const settingsVideoSlider = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: current => setSlideCurrentPage(current)
    };
    const [fadeIn, setFadeIn] = useState(false)
    const sliderClientsSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 5,
        slidesToScroll: 5,
        rows: 2,
        beforeChange: () => setFadeIn(false),
        afterChange: _ => setFadeIn(true),
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    const [course, setCourse] = useState('');
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState();
    const [openDetailCourse, setOpenDetailCourse] = useState(false);

    const [videoSliderRef, setVideoSliderRef] = useState(null);

    const [slideCurrentPage, setSlideCurrentPage] = useState(0);

    const [boolAnimations, setBoolAnimations] = useState(false);

    const handleChangeCourseSelect = (ev) => {
        setCourse(ev.target.value);
    }

    const handleOpenCourseModal = async (item) => {
        let newCourse = await getCourseDetail(item.id);
        setSelectedCourse(newCourse)
        setOpenDetailCourse(true);
    }

    const handleCloseCourseModal = () => {
        setSelectedCourse(null)
        setOpenDetailCourse(false);
    }

    const startAnimation = () => {
        setTimeout(() => {
            setBoolAnimations(true)
        }, 500)
    }

    const submitContactForm = (ev) => {
        ev.preventDefault()


    }

    const getCourses = async () => {
        let listOfCourses = await getListCourses()

        setCourses(listOfCourses)
    }

    useEffect(() => {
        getCourses()
        startAnimation()
    }, [])

    return (
        <div className="g-sidenav-show bg-white">
            <TopNavbar fixed position={'top'} />
            <div className="container position-sticky z-index-sticky top-0">
            </div>
            <section style={{ minHeight: '100vh', backgroundImage: `url(${backgrounds.bg_001})`, backgroundSize: 'cover', backgroundPosition: 'fixed' }}>
                <div className="page-header section-height-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto my-auto">
                                <div>
                                    <img className="rounded" src={logos.navbar} height={'100%'} alt="Logomarca becourse" />
                                </div>
                                <h2 className="text-white">
                                    Capacite a sua equipe
                                </h2>
                                <h6 className="text-white opacity-8">
                                    Soluções corporativas de inglês com a maior empresa de educação internacional do Brasil
                                </h6>
                                <InternalLink to="contact" className="btn btn-md bg-white btn-round w-50 m-0 mt-2">
                                    {"Comece agora"}
                                </InternalLink>
                            </div>
                            <div className="col-md-2">
                            </div>
                            <div className="col-md-6 d-lg-block d-none" style={{ marginTop: -20 }}>

                                <Slide direction="down" in={boolAnimations} mountOnEnter unmountOnExit>
                                    <div className="page-header">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12 d-flex justify-content-center flex-column">
                                                    <div className="card d-flex blur justify-content-center p-4 shadow-lg my-sm-0 my-sm-6 mt-8 mb-5 section-height-85">
                                                        <div className="text-left px-4">
                                                            <h3 className="text-gradient text-dark">Interessado?</h3>
                                                            <p className="mb-0">
                                                                {"Faça um orçamento agora!"}
                                                            </p>
                                                        </div>
                                                        <form id="contact-form" method="post" autoComplete="off">
                                                            <div className="card-body pb-2">
                                                                <div className="row">
                                                                    <div className="col-md-6 mb-2">
                                                                        <TextField
                                                                            fullWidth
                                                                            label="Qual o seu nome?"
                                                                            placeholder="Insira aqui o seu nome"
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-6 ps-md-2 mb-4">
                                                                        <TextField
                                                                            fullWidth
                                                                            label="Número para contato"
                                                                            placeholder="Insira aqui o seu telefone"
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-12 ps-md-2 mb-4">
                                                                        <TextField
                                                                            fullWidth
                                                                            type="email"
                                                                            label="Qual o seu e-mail?"
                                                                            placeholder="Insira aqui o seu e-mail"
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-12 ps-md-2 mb-4">
                                                                        <FormControl required fullWidth>
                                                                            <InputLabel shrink id="label_course_select">
                                                                                Interesse em qual curso?
                                                                            </InputLabel>
                                                                            <Select
                                                                                labelId={"label_course_select"}
                                                                                value={course}
                                                                                onChange={handleChangeCourseSelect}
                                                                                displayEmpty
                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                            >
                                                                                <MenuItem value="" disabled>
                                                                                    Selecione o curso de interesse
                                                                                </MenuItem>
                                                                                {
                                                                                    courses.map(c => {
                                                                                        return (
                                                                                            <MenuItem key={`select__${c.title}`} value={c.title}>{c.title}</MenuItem>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12 text-left">
                                                                        <Button fullWidth type="submit" className="btn btn-lg bg-dark text-white w-100 btn-round mb-0 me-1">Concluir</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slide>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-lg-7 pt-5" id="about-us">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 ms-auto order-1 order-md-1 order-lg-1">
                            <div className="p-3 pt-0">
                                <div className="icon icon-shape bg-gradient-light rounded-circle shadow text-center mb-4">
                                    {/* <i className="ni ni-building"></i> */}
                                    <img src={logos.icon} height="70%" className="mt-2" alt="Logomarca becourse" />
                                </div>
                                <h3 className="text-gradient text-dark mb-0">Becourse</h3>
                                <h4 className="mb-4">Inglês exclusivo para adultos</h4>
                                <p>Professor de Inglês, especialista em ensino de adultos em ambientes corporativos, vindo do Rio Grande do Sul, Marcelo Soares visitou a Capital Mato-grossense em férias e percebeu a necessidade e carência de um ensino de inglês voltado exclusivamente a esse público na Capital. Utilizando método e material próprios, começou a lecionar aulas de cunho particular e, logo em seguida, adaptou o método para atender empresas e grandes grupos in loco, tanto de maneira presencial quanto online, atendendo nos horários e lugares mais convenientes para os alunos, poupando tanto seu tempo de ida e vinda às escolas quanto seus gastos provenientes desse deslocamento.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-8 order-2 order-md-2 order-lg-1">
                            <div className="position-relative ms-lg-5 mb-0 mb-md-7 mb-lg-0 h-75">
                                <div className="bg-gradient-dark w-100 h-100 border-radius-xl position-absolute d-lg-block d-none"></div>
                                <img src={defaultImages.collaboration} className="w-100 border-radius-xl mt-7 ms-lg-5 position-relative z-index-5" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 d-lg-block d-none ms-auto order-1 order-md-1 order-lg-1">
                            <p>Hoje, Becourse, atuando desde 2012 no mercado Mato-grossense,  conta com uma equipe constituída de Teachers Brasileiros e Norte-Americanos especializados no ramo, que atendem dentro e fora do estado ensinando o Inglês conversação e profissionalizando seus alunos com aulas também de Inglês Business (Inglês para negócios e mercado de trabalho) e áreas mais específicas como a de inglês para mecânicos de aeronaves, fazendo da Becourse a primeira instituição de ensino exclusivo de adultos e inglês Business no Brasil. </p>

                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-4 bg-gray" id="courses">
                <div className="py-2">
                    <div className="container">
                        <div className="row">
                            <div className="row text-center my-sm-5 mt-5">
                                <div className="col-lg-6 mx-auto">
                                    <h2 className="text-dark text-gradient mb-0">Cursos</h2>
                                    <p className="lead">Conheça os nossos cursos.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="row mt-4">
                                    {
                                        courses.map(c => {
                                            return (
                                                <div className="col-md-6 mt-md-0 mt-5" key={c.title}>
                                                    <div style={{ cursor: 'pointer' }} onClick={() => { handleOpenCourseModal(c) }}>
                                                        <div className="card move-on-hover" style={{ borderRadius: 0 }}>
                                                            <img className="w-100" src={c.background} alt={c.title} />
                                                            <h2 className="text-white" style={{ position: 'absolute', bottom: '10%', left: '10%' }}>{c.title}</h2>
                                                        </div>
                                                        {/* <div className="mt-2 ms-2">
                                                            <p className="mb-0">{c.subTitle}</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-md-3 mx-auto mt-md-0 mt-5 d-md-block d-none">
                                <div className="position-sticky" style={{ top: 100 }}>
                                    <h4 className="text-dark">Observe ao lado os nossos cursos.</h4>
                                    <h6 className="text-dark opacity-7">Clique em um curso e obtenha mais informações</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <svg className="" viewBox="0 0 500 150" style={{ width: '100%', marginTop: '-10%' }}>
                    <path id="tips" fill="#252f40" d="M0.28,127.78 C208.52,64.63 237.87,36.02 500.84,121.88 L500.00,150.00 L0.00,150.00 Z" ></path>
                </svg>
            </section>
            <section className="" style={{ marginTop: '-12%' }}>
                <div className="container">
                    <div className="row">
                        <div className="row text-center">
                            <div className="col-lg-6 mx-auto">
                                <h2 className="text-white mb-0">Algumas dicas ;)</h2>
                                <p className="lead">Aproveite e conheça alguns de nossos "Teachers"!</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row w-100 bg-dark m-0 p-0 pt-5">
                    <div className="col-md-3 col-2 text-right my-auto">
                        <IconButton onClick={() => {
                            if (slideCurrentPage === 0) {
                                videoSliderRef.slickGoTo(clews.length - 1)
                            } else {
                                videoSliderRef.slickGoTo(slideCurrentPage - 1)
                            }
                        }}>
                            <ArrowBackIosIcon />
                        </IconButton>
                    </div>
                    <div className="col-md-6 col-8 my-auto overflow-hidden">
                        <Slider className="bg-white" ref={slider => { setVideoSliderRef(slider) }} {...settingsVideoSlider}>
                            {
                                clews.map((v, vIndex) => {
                                    return (
                                        <div key={`clews__${v.title}`} className="bg-white" style={{ borderRadius: 50, width: "auto" }}>
                                            <div className="text-center bg-white rounded">
                                                <h3 className="h2 text-dark py-2">{v.title}</h3>
                                            </div>
                                            {
                                                slideCurrentPage === vIndex &&
                                                <PlayerComponent url={v.video} />
                                            }
                                        </div>
                                    )
                                })
                            }

                        </Slider>
                    </div>
                    <div className="col-md-3 col-2 text-left align-center my-auto">
                        <IconButton onClick={() => {
                            if (slideCurrentPage === (clews.length - 1)) {
                                videoSliderRef.slickGoTo(0)
                            } else {
                                videoSliderRef.slickGoTo(slideCurrentPage + 1)
                            }
                        }}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </div>
                </div>
            </section>
            <section className="bg-white">
                <svg height="100%" width="100%" id="svg" viewBox="0 0 1440 300" xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150"><path d="M 0,600 C 0,600 0,300 0,300 C 205.71428571428572,385.7142857142857 822.8571428571429,342.8571428571429 1440,300 C 1440,300 1440,600 1440,600 Z" stroke="none" strokeWidth="0" fill="#252f40" className="transition-all duration-300 ease-in-out delay-150" transform="rotate(-180 720 300)"></path></svg>
                <div id="clients" className="p-3">
                </div>
                <div className="container bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="row text-center my-sm-5">
                                <div className="col-lg-6 mx-auto">
                                    <h2 className="text-dark mb-0">Nossos Parceiros</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Slider {...sliderClientsSettings}>
                        {
                            clients_list.map((client) => {
                                return (
                                    <Grow key={`client__${client.title}`} in={fadeIn}>
                                        <div className="bg-white p-3">
                                            <ClientCard img={client.image} title={client.title} />
                                        </div>
                                    </Grow>
                                )
                            })
                        }
                    </Slider>
                </div>
                <svg height="100%" width="100%" id="svg" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150"><path d="M 0,400 C 0,400 0,200 0,200 C 106.14285714285714,207.92857142857144 212.28571428571428,215.85714285714286 334,228 C 455.7142857142857,240.14285714285714 593,256.5 730,252 C 867,247.49999999999997 1003.7142857142858,222.14285714285714 1122,210 C 1240.2857142857142,197.85714285714286 1340.142857142857,198.92857142857144 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="#252f40" className="transition-all duration-300 ease-in-out delay-150"></path></svg>
            </section>
            <section className="bg-dark">
                <div className="container bg-dark" id="clients">
                    <div className="container">
                        <div className="row">
                            <div className="row text-center my-sm-5">
                                <div className="col-lg-6 mx-auto">
                                    <h2 className="text-white mb-0">Clube de vantagens</h2>
                                    <p>
                                        {"Conheça todos os locais em que você tem várias vantagens por fazer parte da família Becourse!"}
                                    </p>
                                    <div className="col-md-12 text-center">
                                        <Button fullWidth type="submit" className="btn bg-gradient-light mt-3 mb-0 w-100">Clique aqui e descubra!</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-dark pt-5 pb-5" id="contact">
                <div className="page-header section-height-85">
                    <div>
                        <img className="position-absolute fixed-top ms-auto h-100 z-index-0 d-none d-sm-none d-md-block border-radius-section border-top-end-radius-0 border-top-start-radius-0 border-bottom-end-radius-0" src={backgrounds.bg_003} alt="Contact-us background" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-7 d-flex justify-content-center flex-column">
                                <div className="card d-flex blur justify-content-center p-4 shadow-lg my-sm-0 my-sm-6 mt-8 mb-5">
                                    <div className="text-center">
                                        <h3 className="text-gradient text-dark">Fale conosco...</h3>
                                        <p className="mb-0">
                                            {"Que tal marcarmos uma reunião ou videoconferência para conhecer o projeto?"}
                                        </p>
                                    </div>
                                    <form className="pb-1" id="contact-form" method="post" autoComplete="off" onSubmit={submitContactForm}>
                                        <div className="card-body pb-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <TextField
                                                        fullWidth
                                                        label="Seu nome"
                                                        placeholder="Favor, insira aqui o seu nome"
                                                        variant="filled"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <TextField
                                                        fullWidth
                                                        label="Telefone para contato?"
                                                        type="tel"
                                                        placeholder="Favor, insira aqui o seu telefone"
                                                        variant="filled"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <TextField
                                                fullWidth
                                                type="email"
                                                label="Seu melhor e-mail?"
                                                placeholder="Ex.: meuemail@becourse.com.br"
                                                variant="filled"
                                                required
                                            />
                                            <FormControl variant="filled" required fullWidth>
                                                <InputLabel shrink id="label_course_select">
                                                    Interesse em qual curso?
                                                </InputLabel>
                                                <Select
                                                    labelId={"label_course_select"}
                                                    value={course}
                                                    onChange={handleChangeCourseSelect}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem value="" disabled>
                                                        Selecione o curso de interesse
                                                    </MenuItem>
                                                    {
                                                        courses.map(c => {
                                                            return (
                                                                <MenuItem key={`select__${c.title}`} value={c.title}>{c.title}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                fullWidth
                                                label="Mensagem"
                                                multiline
                                                rows={4}
                                                placeholder="Insira aqui a sua mensagem..."
                                                variant="filled"
                                                required
                                            />
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <Button fullWidth type="submit" className="btn bg-gradient-dark mt-3 mb-0 w-100">Enviar Mensagem</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5 d-flex justify-content-center flex-column my-5 ">
                                <div>
                                    <div className="card blur">
                                        <div className="card-body pt-3 pb-0">
                                            <div className="row">
                                                <div className="col-1">
                                                    <i className="fas fa-map-marker-alt text-dark"></i>
                                                </div>
                                                <div className="col-10">
                                                    <p>
                                                        {"Cuiabá MT - Brazil"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-1">
                                                    <i className="fas fa-phone-alt text-dark"></i>
                                                </div>
                                                <div className="col-10">
                                                    <p>
                                                        {"Telefone: +55 65 98472-8167"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-1">
                                                    <i className="fas fa-at text-dark"></i>
                                                </div>
                                                <div className="col-10">
                                                    <p>
                                                        {"E-mail: comercial@becourse.com.br"}
                                                    </p>
                                                </div>
                                                <div className="col-1"></div>
                                                <div className="col-1">
                                                    <i className="fas fa-at text-dark"></i>
                                                </div>
                                                <div className="col-10">
                                                    <p>
                                                        {"E-mail: administrativo@becourse.com.br"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SiteFooter />
            <ScrollToTop showBelow={300} />
            <WhatsAppButton always showBelow={10} />

            {/** Modal Courses */}
            <ModalCourses open={openDetailCourse} handleClose={handleCloseCourseModal} showContent={selectedCourse != null} course={selectedCourse} />
        </div>
    );
}

export default LandingPageOLD;