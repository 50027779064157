import React, { useEffect, useState } from 'react';
import CertificadoComp from './certificadoComp';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useParams } from 'react-router-dom';
import { getUser } from "../../../services/auth";
import { getPerformanceByAlunoId } from '../../../repositories/turma_repository';

import { getTurmaAllData } from '../../../repositories/turma_repository';

import fs from 'fs';

const CertificadoStudant = (props) => {
    const { turma, modulo } = useParams();
    const [turmaLoad, setTurmaLoad] = useState([]);
    const [user, setUser] = useState({});
    const [certificadoCompo, setCertificadoCompo] = useState('Carregando');
    const [imagemCertificadoCompo, setImagemCertificadoCompo] = useState('');
    const [pdfSaved, setPdfSaved] = useState('');

    const baixar = async () => {
        const ids = ["trecho-1", "trecho-2", "trecho-3"];
      
        const pdf = new jsPDF("portrait", "px");
      
        for (let i = 0; i < ids.length; i++) {
          const element = document.getElementById(ids[i]);
      
          if (element) {
            await html2canvas(element, { scale: 3 }).then((canvas) => {
              const imgData = canvas.toDataURL("image/png");
              const pageWidth = pdf.internal.pageSize.getWidth();
              const pageHeight = pdf.internal.pageSize.getHeight();
              const imgAspectRatio = canvas.width / canvas.height;
      
              const pdfWidth = pageWidth;
              const pdfHeight = pageWidth / imgAspectRatio;
              setImagemCertificadoCompo(imgData);
              pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      
              if (i < ids.length - 1) {
                pdf.addPage();
              }
            });
          }
        }
        
        setPdfSaved(pdf);
        pdf.save("certificado.pdf");
    };

    const baixarCarregado = () => {
          const pdf = pdfSaved;
          pdf.save('certificado.pdf');
    }

    useEffect(() => {
            const getTurma = async () => {
                let result = await getTurmaAllData(turma);
                let userLogged = await getUser();
                if (result.success) { 
                    setTurmaLoad(result);
                    setUser(userLogged);
                    let performance = await getPerformanceByAlunoId(userLogged.id, modulo);
                    console.log(performance);
                    if (performance.success && performance.performance !== null) {
                        setCertificadoCompo(<CertificadoComp turma={result} nivel={modulo} aluno={userLogged.nome} performance={performance.performance}></CertificadoComp>);
                        baixar();
                    } else {
                        setCertificadoCompo('Aguarde o lançamento das notas para Baixar o certificado!');
                        return ;
                    }
                }
            }

            getTurma();
        }
    , [modulo, turma]);

    return(
        <div>
            { certificadoCompo !== 'Carregando' && 
                <div style={{width: '100%'}}>
                    <button onClick={baixarCarregado} style={{marginLeft: '45%', marginTop: '15px'}} type="button">Baixar Certificado</button>
                    <hr></hr>
                </div>
            }
            { certificadoCompo }
            <div id="output"></div>
        </div> 
    );
}

export default CertificadoStudant;
