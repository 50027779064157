import React from 'react'

const Footer = () => {

    return (
        <footer className="footer py-3">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6 mb-lg-0 mb-4">
                        <div className="copyright text-center text-sm text-muted text-lg-left">
                            Becourse © {new Date().getFullYear()},
                            made with <i className="fa fa-heart"></i>.
                            
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer