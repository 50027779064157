import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const AudioPlayerComponent = (props) => {

    const { src } = props

    return (
        <AudioPlayer
            src={src}
            onPlay={e => console.log("onPlay")}

        />
    )
}

export default AudioPlayerComponent