import React from "react";
import EditorJs from "react-editor-js";
import { EDITOR_JS_TOOLS } from "../tools/editor_js_tools";
import "./editor_js.css";

const EditorJS = (props) => {
    const { onChange, readOnly, data, holder, paragraphIdentor } = props;
    const dataDefault = {
        time: 1556098174501,
        blocks: [
            {
                type: "paragraph",
                data: {
                    text:
                        "Em breve descrição detalhada deste curso,. 😏"
                }
            },
        ],
        version: "2.12.4"
    }
    return (
        <EditorJs
            tools={EDITOR_JS_TOOLS}
            data={data ? data : dataDefault}
            readOnly={readOnly ? (readOnly === true || readOnly === false) ? readOnly : true : false}
            onChange={(_, data) => onChange && onChange(data)}
            holder={holder ? holder : "place"}
        >
            <div id={holder ? holder : "place"} className={`place__editor${paragraphIdentor && ' identor'}`} />
        </EditorJs>
    )
}

export default EditorJS;