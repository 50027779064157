
import React, { useState } from 'react';
import { Dialog, AppBar, Slide, Typography, IconButton, Button, Toolbar, TextField } from '@material-ui/core';
import DefaultTextEditor from '../../../shared/components/editor/DefaultTextEditor';
import CloseIcon from '@material-ui/icons/Close';
import { SettingsOutlined } from '@material-ui/icons';
import { createSuggestion } from '../../../../repositories/user_repository';
import { useSnackbar } from 'notistack';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const CreateSuggestion = (props) => {
    const { enqueueSnackbar } = useSnackbar()

    const { open, onClose, onSuccess } = props

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')

    const onSubmitForm = (ev) => {
        ev.preventDefault()
        savedSuggestion({ titulo: title, descricao: description })
    }

    const savedSuggestion = async (data) => {

        let response = await createSuggestion(data)

        if(response.success) {
            resetFields()
            enqueueSnackbar('Sugestão criada com sucesso!',
            { variant: 'success' })
            onClose()
            onSuccess()
        }
    }

    const resetFields = () => {
        setTitle('')
        setDescription('')
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <AppBar className="bg-main position-static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Nova Sugestão
                    </Typography>
                </Toolbar>
            </AppBar>
            <form onSubmit={onSubmitForm} className="mt-4 container">
                <TextField className="mb-2" label="Título" variant="standard" value={title} onChange={(ev) => setTitle(ev.target.value)} fullWidth />
                <DefaultTextEditor value={description} onChange={(val) => setDescription(val)} />
                <Button type="submit" className="btn btn-main bg-main text-white">Salvar</Button>
            </form>

        </Dialog>
    )
}

export default CreateSuggestion