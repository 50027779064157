import React, {
    useEffect,
    useState
} from 'react';
import { Link } from 'react-router-dom';
import { backgrounds } from '../../../core/app_images';
import '../../../assets/css/soft-ui-dashboard.css';

import { CircularProgress, TextField } from '@material-ui/core';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { doLogin } from '../../../repositories/user_repository';
import { getRemember, getUser, isAuthenticated } from '../../../services/auth';
import { containerCard, itemsCard, MotionDiv } from '../../shared/tools/animations';
import { useSnackbar, withSnackbar } from 'notistack';

const LoginPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [values, setValues] = useState({
        email: '',
        password: ''
    });
    const [remember, setRemember] = useState(false);
    const [doingLogin, setDoingLogin] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const handleChangeRemember = () => {
        setRemember(!remember);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const goToUserHomePage = () => {
        window.location.href = '/student'
    }

    const onLoginFormSubmit = async (ev) => {
        ev.preventDefault();
        let loginVariables = {
            email: values.email,
            password: values.password,
            remember
        }
        setDoingLogin(true)

        let logged = await doLogin(loginVariables)

        if (logged.success) {
            enqueueSnackbar('Login efetuado com sucesso!', { variant: 'success' })
            goToUserHomePage()
            setDoingLogin(false)
        }
        else {
            if (logged.url) {
                enqueueSnackbar('O novo sistema só está disponível para alunos, em breve para os outros perfis, também!', { variant: 'warning' });
                enqueueSnackbar('Estamos te redirecionando para conseguir acessar corretamente o seu perfil.', { variant: 'warning' });
                
                setTimeout(()=> {
                    window.location.href = logged.url
                }, 2000)
            }else {
                enqueueSnackbar(logged.error ? logged.error : 'Usuário ou senha incorretos, verifique os dados e tente novamente!', { variant: 'error' })
                setDoingLogin(false)
            }
        }


    }

    useEffect(() => {
        if (isAuthenticated()) {
            goToUserHomePage()
        }
        let rememberUser = getRemember();
        setRemember(rememberUser)
        if (rememberUser) {
            let userRemembered = getUser();
            setValues({ ...values, email: userRemembered.email });
        }
    }, [])

    return (
        <div className="g-sidenav-show" style={{
            backgroundColor: '#969ca8',
            backgroundImage: 'linear-gradient(90deg, #969ca8 0%, #dbe8f1 50%, #969ca8 100%)',
            height: '100vh'
        }}>
            <div className="container position-sticky z-index-sticky top-0">
                <div className="row">
                    <div className="col-12">
                        {/* <!-- Navbar --> */}
                        <nav className="w-auto navbar navbar-expand-lg top-0  z-index-3 position-absolute my-3 py-2 start-0 mx-4" style={{ boxShadow: 'none', }}>
                            <div className="container-fluid">
                                <Link className="text-white font-weight-bolder ms-lg-0 ms-3 " to="/">
                                    <button className="btn btn-sm bg-main text-white mb-0 me-1" style={{ borderRadius: 50, minHeight: 60 }}>
                                        Voltar ao site
                                    </button>
                                </Link>
                            </div>
                        </nav>
                        {/* <!-- End Navbar --> */}
                    </div>
                </div>
            </div>
            <section>
                <div className="page-header section-height-75">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
                                <MotionDiv
                                    initial="hidden"
                                    animate="visible"
                                    variants={containerCard}
                                    className="card min-blur mt-8">
                                    <div className="card-header pb-0 text-left bg-transparent">
                                        <h3 className="font-weight-bolder text-main text-gradient">Bem vindo(a)!</h3>
                                        <p className="mb-0">Insira seu e-mail e senha para efetuar o login.</p>
                                    </div>
                                    <MotionDiv
                                        variants={itemsCard}
                                        className="card-body">
                                        <form role="form text-left" onSubmit={onLoginFormSubmit}>
                                            <MotionDiv
                                                variants={itemsCard}
                                            >
                                                <TextField
                                                    fullWidth
                                                    className={'mb-3'}
                                                    id="email__login-form"
                                                    type="email"
                                                    label="E-mail"
                                                    value={values.email}
                                                    onChange={handleChange('email')}
                                                    required
                                                />
                                            </MotionDiv>
                                            <MotionDiv
                                                variants={itemsCard}
                                            >
                                                <TextField
                                                    fullWidth
                                                    className={'mb-3'}
                                                    id="password__login-form"
                                                    type={showPassword ? 'text' : 'password'}
                                                    label="Senha"
                                                    value={values.password}
                                                    onChange={handleChange('password')}
                                                    required
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </MotionDiv>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="rememberMe" onChange={handleChangeRemember} checked={remember ? 'checked' : ''} />
                                                <label className="form-check-label" for="rememberMe">Lembrar de mim</label>
                                            </div>
                                            <MotionDiv variants={itemsCard} className="text-center">
                                                <button type="submit" disabled={doingLogin} className="btn bg-gradient-main w-100 mt-4 mb-0">{doingLogin ? <CircularProgress size={13} color='inherit' /> : 'Entrar'}</button>
                                            </MotionDiv>
                                        </form>
                                    </MotionDiv>
                                </MotionDiv>
                            </div>
                            <div className="col-xl-8 col-lg-7 col-md-6">
                                <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n1">
                                    <div className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style={{ backgroundImage: `url(${backgrounds.new.bg_005})`, width: '70vw' }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default withSnackbar(LoginPage);