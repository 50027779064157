import { Divider, Drawer, List, ListItem, ListItemText, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Link as InternalLink } from "react-scroll"
import { logos } from '../../../../core/app_images'
import { top_link_list, top_right_links } from '../navigation/top_link_list'

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
})

const DefaultHeader = (props) => {
    const classes = useStyles()
    const { fixed, isMobile } = props

    const [drawerOpen, setDrawerOpen] = useState(false)

    const closeDrawer = () => {
        setDrawerOpen(false)
    }

    const openDrawer = () => {
        setDrawerOpen(true)
    }

    const renderLeftLink = (link, drawer = false) => {
        if (link.local) {
            return (
                <div key={`${link.title}__left-top-link`} className="nav-item px-3 col-auto">
                    {
                        link.inPage ?
                            <InternalLink onClick={() => drawer && closeDrawer()} to={link.url} className={`nav-link text-${drawer ? 'dark' : 'white'} opacity-8 cursor-pointer`}>
                                {drawer ? <ListItem button>
                                    <ListItemText primary={link.title} />
                                </ListItem> :
                                    link.title}
                            </InternalLink>
                            :
                            <Link onClick={() => drawer && closeDrawer()} to={link.url} className={`nav-link text-${drawer ? 'dark' : 'white'} opacity-8 cursor-pointer`}>
                                {drawer ? <ListItem button>
                                    <ListItemText primary={link.title} />
                                </ListItem> :
                                    link.title}
                            </Link>
                    }
                </div>
            )
        } else {
            return (
                <div key={`${link.title}__left-top-link`} className="nav-item px-3 cursor-pointer col-auto">
                    <a onClick={() => drawer && closeDrawer()} href={link.url} className={`nav-link text-${drawer ? 'dark' : 'white'} opacity-8`}>
                        {drawer ? <ListItem button>
                            <ListItemText primary={link.title} />
                        </ListItem> :
                            link.title}
                    </a>
                </div>
            )
        }
    }

    const renderRightLink = (link, drawer = false) => {

        if (link.type === 'link') {
            if (link.local) {
                return (
                    <div key={`${link.title}__right-top-link`} className="col-auto my-auto px-3 align-items-end">
                        {
                            link.inPage ?
                                <InternalLink onClick={() => drawer && closeDrawer()} to={link.url} className={`nav-link text-${drawer ? 'dark' : 'main'} opacity-8`}>
                                    {drawer ? <ListItem button>
                                        <ListItemText primary={link.title} />
                                    </ListItem> :
                                        link.title}
                                </InternalLink>
                                :
                                <Link onClick={() => drawer && closeDrawer()} to={link.url} className={`nav-link text-${drawer ? 'dark' : 'main'} opacity-8`}>
                                    {drawer ? <ListItem button>
                                        <ListItemText primary={link.title} />
                                    </ListItem> :
                                        link.title}
                                </Link>
                        }
                    </div>
                )
            } else {
                return (
                    <div key={`${link.title}__right-top-link`} onClick={() => drawer && closeDrawer()} className="col-auto my-auto px-3 align-items-end">
                        <a href={link.url} className={`nav-link text-${drawer ? 'dark' : 'main'} opacity-8`}>
                            {drawer ? <ListItem button>
                                <ListItemText primary={link.title} />
                            </ListItem> :
                                link.title}
                        </a>
                    </div>
                )
            }
        } else if (link.type === 'button') {
            return (
                link.inPage ?
                    <InternalLink key={`${link.title}__right-top-button`} onClick={() => drawer && closeDrawer()} to={link.url} className="col-auto text-white opacity-8 align-items-end">
                        {drawer ? <ListItem button>
                            <button className="btn btn-sm bg-dark text-white mb-0 me-1 h-100">
                                {link.title}
                            </button>
                        </ListItem> : <button className="btn btn-sm bg-dark text-white mb-0 me-1 h-100">
                            {link.title}
                        </button>}
                    </InternalLink>
                    :
                    <Link key={`${link.title}__right-top-button`} onClick={() => drawer && closeDrawer()} to={link.url} className="col-auto text-white opacity-8 align-items-end">
                        {drawer ? <ListItem button onClick={() => closeDrawer()}>
                            <button className="btn btn-sm bg-dark mb-0 me-1 h-100">
                                {link.title}
                            </button>
                        </ListItem> : <button className="btn btn-sm bg-dark text-white h-100 mb-0 me-1">
                            {link.title}
                        </button>}
                    </Link>
            )
        } else if (link.type === 'special') {
            return (
                <Link key={`${link.title}__right-top-button`} onClick={() => drawer && closeDrawer()} to={link.url} className="col-auto text-white align-items-end">
                    {drawer ? <ListItem button onClick={() => closeDrawer()}>
                        <button className="btn btn-sm bg-main text-white mb-0 me-1 w-100 h-100" style={{ borderRadius: 50, minHeight: 60 }}>
                            {link.title}
                        </button>
                    </ListItem> : <button className="btn btn-sm bg-main text-white mb-0 me-1" style={{ borderRadius: 50, minHeight: 60 }}>
                        {link.title}
                    </button>}
                </Link>
            )
        } else {
            return <></>
        }
    }

    return (
        <div className={isMobile ? "px-5 py-2" : "px-5 pt-2"} style={{ height: !isMobile && '25vh' }}>
            <div className="w-100 d-none d-md-block">
                <div className="container-fluid pt-4 px-5">
                    <div className="row w-100 justify-content-between">
                        <div className="col-auto my-auto px-3 align-items-end">
                            <a href="/" rel="tooltip" title="Becourse" data-placement="bottom" className="nav-link text-white opacity-8">
                                <img className="rounded" src={logos.navbar} height={'100%'} alt="Becourse logomarca" />
                            </a>
                        </div>

                        <div className="col-auto justify-content-end">
                            <div className="d-flex ms-auto w-100 justify-content-end">
                                {
                                    top_right_links.map(link => {
                                        return renderRightLink(link)
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4 mb-0"></div>
            </div>
            <nav
                className={`navbar navbar-expand-lg navbar-dark bg-main z-index-3 p-0 ${fixed && 'fixed-top'}`} style={{ borderRadius: 50 }}>
                <div className="container-fluid" style={{ minHeight: 60 }}>
                    <div class="w-mx d-block d-md-none">
                        <a href="/" rel="tooltip" title="Becourse" data-placement="bottom" className="nav-link text-white opacity-8">
                            <img className="rounded" src={logos.navbar} height={'100%'} alt="Becourse logomarca" />
                        </a>
                    </div>
                    <button className="navbar-toggler" onClick={openDrawer} style={{ border: 'none' }} type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                        <div className="sidenav-toggler-inner">
                            <i className="sidenav-toggler-line"></i>
                            <i className="sidenav-toggler-line"></i>
                            <i className="sidenav-toggler-line"></i>
                        </div>
                    </button>
                    <div className="collapse navbar-collapse" id="navigation">
                        <div className="row justify-content-around mx-auto">
                            {
                                top_link_list.map(link => {
                                    return renderLeftLink(link)
                                })
                            }
                        </div>
                    </div>
                </div>
                <Drawer anchor={'right'} style={{ border: 'none' }} open={drawerOpen} onClose={closeDrawer}>
                    <div
                        className={`${classes.list} bg-white text-white h-100`}
                        role="presentation"
                        onClick={closeDrawer}
                        onKeyDown={closeDrawer}
                    >
                        <List>
                            {top_link_list.map((link) => (
                                renderLeftLink(link, true)
                            ))}
                        </List>
                        <Divider />
                        <List>
                            {top_right_links.map((link) => (renderRightLink(link, true)
                            ))}
                        </List>
                    </div>
                </Drawer>
            </nav>
        </div>
    )
}

export default DefaultHeader