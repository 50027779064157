import React from "react"

const SiteFooter = () => {

    return (
        <footer className="footer pt-5 bg-dark">
            <div className="container">
                <div className=" row">
                    <div className="col-12">
                        <div className="text-center">
                            <p className="my-4 text-sm text-white">
                                {"Todos os direitos reservados. Copyright © "}
                                {new Date().getFullYear()}
                                {" Becourse by "}
                                <a href="https://rafaelgcs.com" className="text-info" rel="noreferrer" target="_blank">{"Rafael Guimarães"}</a>
                                {"."}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default SiteFooter