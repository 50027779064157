import React, { useState } from 'react';
import { logos } from '../../../core/app_images';
import TopNavigator from '../components/navigator/top_navigator';
import Sidebar from '../components/sidebar/sidebar';
import '../../../assets/css/soft-ui-dashboard.css';
import TableSuggestions from '../components/tables/table_suggestions';
import Footer from '../../shared/components/footer/footer';

const StudentSuggestionsPage = () => {
    const [showNavbar, setShowNavbar] = useState(false);

    const onChangeNavbar = (opt) => {
        setShowNavbar(opt);
    }
    return (
        <div className={`g-sidenav-show bg-gray-100${showNavbar ? " g-sidenav-pinned" : ""}`}>
            <Sidebar logo={logos.navbar} show={showNavbar} background={'bg-main'} onCloseSidebar={onChangeNavbar} />
            <div className="main-content position-relative bg-gray-100">
                <TopNavigator title="Sugestões" breadcrumps={[{ title: 'Início', link: '/student' }]} showNavbar={onChangeNavbar} navbarOpen={showNavbar} />
                <div className="container-fluid">
                    <div className="row py-4">
                        <div className="col-12 col-md-12">
                            <div className="row">
                                <div className="col-12 py-4" >
                                    <div className="container">
                                        <TableSuggestions />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default StudentSuggestionsPage;
