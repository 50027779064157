import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import EditorJS from "../../../shared/components/editor_js";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        color: 'white',
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalCourses = (props) => {
    const classes = useStyles();
    const { handleClose, open, course, showContent } = props;

    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar position="sticky" color="primary" style={{ backgroundImage: `url(${course && course.background})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {course && course.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className="mb-0 container text-justify">
                {
                    showContent &&
                    <EditorJS holder={`course__${course.id}`} paragraphIdentor data={course.description !== {} ? course.description : undefined} readOnly onChange={(_) => { }}>
                        <div className="place__editor"></div>
                    </EditorJS>
                }
            </div>
        </Dialog>
    )
}

export default ModalCourses