import { api, apiAuth, url } from "../services/api"
import { localLogin } from "../services/auth"

const initialRoute = "usuario"

const doLogin = async ({ email, password, remember }) => {
    let response;

    try {
        response = await api.post('/auth/login', {
            email,
            password
        })
    } catch (e) {
        return { success: false };
    }

    if (response.status === 200) {
        let res = response.data;

        if (res.success) {
            let result = res.data;

            if (result.user.fk_nivel !== 5 && result.user.fk_nivel !== '5') {

                return {
                    success: false,
                    error: 'Usuário não é Aluno. Você será redirecionado para o sistema de login correto...',
                    url: 'https://www.becourse.com.br/becourse/login.php'
                }

            } else {
                let logged = await localLogin(result.user, result.access_token, result.expires_in, remember ? "true" : "false");
                return {
                    success: logged,
                    error: 'Ops, desculpa! Aconteceu um problema e já estamos trabalhando para solucionar.'
                };

            }

        }
        return { success: false };
    }
    return { success: false };
}

const getFullDataOfUser = async (userId) => {
    let response = await apiAuth.get(`/${initialRoute}/fullData/${userId}`);

    if (response.status === 200) {
        let res = response.data;

        if (res.success) {
            return res.data;
        }
        return {};
    }
    return {};
}

const updateUser = async (data, id) => {
    try {
        let response = await apiAuth.put(`/${initialRoute}/update/${id}`, data);

        if (response.status === 200) {
            let res = response.data;

            if (res.success) {
                return {
                    success: true,
                    user: response.data
                };
            }
            return { success: false };
        }
        return { success: false };
    } catch (_) {
        return { success: false };
    }
}

const updateUserEndereco = async (data, id) => {
    let response = await apiAuth.put(`/${initialRoute}/endereco/update/${id}`, data);

    if (response.status === 200 || response.status === 201) {
        let res = response.data;

        if (res.success) {

            return {
                success: true,
                endereco: response.data
            };
        }
        return { success: false };
    }
    return { success: false };
}

const updateUserPassword = async (data, id) => {
    let response = await apiAuth.put(`/${initialRoute}/update/${id}`, data);

    if (response.status === 200) {
        let res = response.data;
        if (res.success) {
            return {
                success: true,

            }
        }
        else {
            return {
                success: false,
            }
        }
    } else {
        return {
            success: false,
        }
    }
}

const getProfileImage = (id) => {
    return `${url}/${initialRoute}/profileImage/${id}`;

}

const getUserTurma = async () => {

    let response = await apiAuth.get(`/${initialRoute}/turma`);
    if (response.status === 200 || response.status === 201) {
        let res = response.data;
        if (res.success) {
            return {
                success: true,
                turma: res.data ? res.data : null
            };
        }
        return { success: false };
    }
    return { success: false, turma : [] };
}

const getFriends = async (id) => {
    let response = await apiAuth.get(`/${initialRoute}/friends/${id}`);

    if (response.status === 200 || response.status === 201) {
        let res = response.data;

        if (res.success) {

            return {
                success: true,
                friends: res.data
            };
        }
        return { success: false };
    }
    return { success: false };
}

const getMessages = async (id) => {
    let response = await apiAuth.get(`/${initialRoute}/messages/${id}`);

    if (response.status === 200 || response.status === 201) {
        let res = response.data;

        if (res.success) {

            return {
                success: true,
                messages: res.data
            };
        }
        return { success: false };
    }
    return { success: false };
}

const getSuggestions = async () => {
    let response = await apiAuth.get(`/${initialRoute}/suggestions`);

    if (response.status === 200) {
        let res = response.data;

        if (res.success) {

            return {
                success: true,
                sugestoes: res.data
            };
        }
        return { success: false };
    }
    return { success: false };
}

const getSuggestionById = async (id) => {
    let response = await apiAuth.get(`/${initialRoute}/suggestions/${id}`);

    if (response.status === 200) {
        let res = response.data;

        if (res.success) {

            return {
                success: true,
                sugestao: res.data
            };
        }
        return { success: false };
    }
    return { success: false };
}

const createSuggestion = async (data) => {
    let response = await apiAuth.post(`/${initialRoute}/suggestions`, data);

    if (response.status === 201) {
        let res = response.data;
        return {
            success: true,
            sugestao: res
        };
    }
    return { success: false };
}

const getMyCertificates = async () => {
    let response = await apiAuth.get(`/${initialRoute}/certifications`);

    if (response.status === 200) {
        let res = response.data;

        if (res.success) {

            return {
                success: true,
                certificados: res.data
            };
        }
        return { success: false };
    }
    return { success: false };
}

const getMyEvolution = async () => {
    let response = await apiAuth.get(`/${initialRoute}/evolution`);

    if (response.status === 200) {
        let res = response.data;

        if (res.success) {

            return {
                success: true,
                evolutions: res.data
            };
        }
        return { success: false };
    }
    return { success: false };
}

export {
    doLogin,
    getFullDataOfUser,
    updateUser,
    updateUserEndereco,
    updateUserPassword,
    getProfileImage,
    getUserTurma,
    getFriends,
    getMessages,
    getSuggestions,
    getSuggestionById,
    createSuggestion,
    getMyCertificates,
    getMyEvolution
}