import React, { useEffect, useState } from 'react'
import '../../../assets/css/soft-ui-dashboard.css'
import { backgrounds, logos } from '../../../core/app_images'
import { Link as InternalLink } from "react-scroll"
import ScrollToTop from '../../shared/components/buttons/scroll_to_top'
import WhatsAppButton from '../../shared/components/buttons/whatsapp_button'
import DefaultHeader from '../components/headers/default_header'
import SiteFooter from '../components/navigation/footer'
import { getCourseDetail, getListCourses } from '../../../repositories/course_repository'
import ModalCourses from '../components/modals/modal_courses'
import { clews } from '../../../core/app_videos'
import Slider from 'react-slick'
import PlayerComponent from '../../shared/components/players/player_component'
import ClientCard from '../components/cards/client_card'
import { clients_list } from '../utils/clients_list'
import { Button, FormControl, InputLabel, Select, MenuItem, TextField, Grow, Slide } from '@material-ui/core';
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'

const LandingPage = () => {
    const settingsVideoSlider = {
        dots: false,
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: current => setSlideCurrentPage(current)
    }

    const [fadeIn, setFadeIn] = useState(false)
    const sliderClientsSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 5,
        slidesToScroll: 5,
        rows: 2,
        beforeChange: () => setFadeIn(false),
        afterChange: _ => setFadeIn(true),
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    }

    const [course, setCourse] = useState('')
    const [courses, setCourses] = useState([])
    const [selectedCourse, setSelectedCourse] = useState()
    const [openDetailCourse, setOpenDetailCourse] = useState(false)

    const [slideCurrentPage, setSlideCurrentPage] = useState(0)

    const handleChangeCourseSelect = (ev) => {
        setCourse(ev.target.value);
    }

    const handleOpenCourseModal = async (item) => {
        let newCourse = await getCourseDetail(item.id)
        setSelectedCourse(newCourse)
        setOpenDetailCourse(true)
    }

    const handleCloseCourseModal = () => {
        setSelectedCourse(null)
        setOpenDetailCourse(false)
    }

    const getCourses = async () => {
        let listOfCourses = await getListCourses()

        setCourses(listOfCourses)
    }

    const submitContactForm = (ev) => {
        ev.preventDefault()


    }

    useEffect(() => {
        getCourses()
    }, [])

    return (
        <div className="g-sidenav-show" style={{
            backgroundColor: '#969ca8',
            backgroundImage: 'linear-gradient(90deg, #969ca8 0%, #dbe8f1 50%, #969ca8 100%)'
        }}>
            <DefaultHeader isMobile={isMobile} />
            <div className="mx-0">
                <div className="my-0 shadow card overflow-hidden" style={{ borderRadius: 0, minHeight: isMobile ? '92vh' : '75vh', maxHeight: '80vh' }}>
                    <Slide timeout={{ appear: 500, enter: 300 }} direction="right" in={true} mountOnEnter unmountOnExit>
                        <div className="" style={{ minHeight: isMobile ? '92vh' : '75vh', backgroundImage: `url(${backgrounds.new.bg_001})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                    </Slide>
                    <div className="container row align-items-center" style={{ minHeight: isMobile ? '92vh' : '75vh', position: 'absolute', top: 0, left: 0 }}>
                        <div className="col-md-4 col-10">
                            <h2 className="text-main bg-white opacity-8 text-uppercase px-3">
                                Capacite a sua equipe
                            </h2>
                            <h6 className="text-white opacity-7 bg-main px-3">
                                Gestão de formação de inglês business com a melhor empresa de  ensino de inglês corporativo no Brasil
                            </h6>
                            <InternalLink to="contact" className="btn btn-md bg-white text-main w-100 m-0 mt-2 opacity-8" style={{ borderRadius: 0 }}>
                                {"Comece agora"}
                            </InternalLink>
                        </div>
                    </div>
                </div>
            </div>
            <section className="pt-5" id="about-us">
                <div className="mx-4">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 ms-auto order-1 order-md-1 order-lg-1">
                            <div className="p-3 pt-0">
                                <h3 className="text-main text-uppercase mb-0">Becourse</h3>
                                <hr className="my-2" />
                                <h4 className="mb-4 bg-main text-white text-uppercase rounded px-2">Inglês exclusivo para adultos</h4>
                                <p className="text-dark text-justify" style={{ textIndent: 50 }}>Professor de Inglês, especialista em ensino de adultos em ambientes corporativos, vindo do Rio Grande do Sul, Marcelo Soares visitou a Capital Mato-grossense em férias e percebeu a necessidade e carência de um ensino de inglês voltado exclusivamente a esse público na Capital. Utilizando método e material próprios, começou a lecionar aulas de cunho particular e, logo em seguida, adaptou o método para atender empresas e grandes grupos in loco, tanto de maneira presencial quanto online, atendendo nos horários e lugares mais convenientes para os alunos, poupando tanto seu tempo de ida e vinda às escolas quanto seus gastos provenientes desse deslocamento.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-8 order-2 order-md-2 order-lg-1">
                            <div className="position-relative ms-lg-5 mb-0 mb-md-7 mb-lg-0 h-75">
                                <div className="bg-gradient-main w-100 h-100 position-absolute rounded d-lg-block d-none"></div>
                                <img src={backgrounds.new.bg_005} className="w-100 mt-3 ms-lg-5 rounded position-relative z-index-5" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 d-lg-block d-none ms-auto order-1 order-md-1 order-lg-1 opacity-10">
                            <p className="text-dark text-justify" style={{ textIndent: 50 }}>Hoje, Becourse, atuando desde 2012 no mercado Mato-grossense,  conta com uma equipe constituída de Teachers Brasileiros e Norte-Americanos especializados no ramo, que atendem dentro e fora do estado ensinando o Inglês conversação e profissionalizando seus alunos com aulas também de Inglês Business (Inglês para negócios e mercado de trabalho) e áreas mais específicas como a de inglês para mecânicos de aeronaves, fazendo da Becourse a primeira instituição de ensino exclusivo de adultos e inglês Business no Brasil. </p>

                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-4 bg-gray-light" id="courses">
                <div className="py-2">
                    <div className="mx-4">
                        <div className="row text-left my-sm-5 mt-5">
                            <div className="col-md-4 align-self-start">
                                <h2 className="text-main text-gradient mb-0 text-uppercase">Cursos</h2>
                                <hr className="my-2" />
                                <p className="lead pb-1 bg-main text-white rounded px-2 text-center">Conheça os nossos cursos.</p>
                            </div>
                        </div>
                    </div>
                    <div className="mx-4 mt-5">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="row mt-4">
                                    {
                                        courses.map(c => {
                                            return (
                                                <div className="col-md-6 mt-md-0 mt-5" key={c.title}>
                                                    <div style={{ cursor: 'pointer' }} onClick={() => { handleOpenCourseModal(c) }}>
                                                        <div className="card move-on-hover bg-main mt-4" style={{ borderRadius: 15 }}>
                                                            <img className="w-100 opacity-7" src={c.background} alt={c.title} />
                                                            <h2 className="text-white" style={{ position: 'absolute', bottom: '10%', left: '10%' }}>{c.title}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-md-3 mx-auto mt-md-0 mt-5 d-md-block d-none">
                                <div className="position-sticky" style={{ top: 100 }}>
                                    <h4 className="text-dark">Observe ao lado os nossos cursos.</h4>
                                    <h6 className="text-dark opacity-7">Clique em um curso e obtenha mais informações</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="card my-4 mx-4" style={{ backgroundColor: '#617689' }} id="tips">
                <div className="container p-4">
                    <div className="container mx-2">
                        <div className="row">
                            <div className="row text-center">
                                <div className="col-lg-6 mx-auto">
                                    <h2 className="text-main mb-0">Algumas dicas ;)</h2>
                                    <hr className="my-2" />
                                    <p className="lead text-white">Aproveite e conheça alguns de nossos "Teachers"!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row w-100 m-0 p-0 pt-5">
                        <div className="col-md-12 col-12 my-auto">
                            <Slider {...settingsVideoSlider}>
                                {
                                    clews.map((v, vIndex) => {
                                        return (
                                            <div key={`clews__${v.title}`} className="row w-100" style={{ backgroundColor: '#617689' }}>
                                                <div className="col-mx">
                                                    {
                                                        slideCurrentPage === vIndex &&
                                                        <div className="row align-items-center">
                                                            <div className="col-md-4 col-ms">
                                                                <div className="text-center min-blur rounded mx-auto my-auto h-100">
                                                                    <h3 className="h2 text-dark py-2">{v.title}</h3>
                                                                </div>
                                                                <div className="text-center rounded mx-auto my-auto h-100">
                                                                    <img src={logos.icon} alt="Ícone Becourse" className="mx-auto my-auto d-none d-md-inline-block" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8 col-12 h-100">
                                                                <PlayerComponent url={v.video} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </Slider>
                        </div>
                    </div>

                </div>
            </section>
            <section className="card pb-5 bg-white blur mx-4 mt-5">
                <div id="clients" className="p-3">
                </div>
                <div className="mx-0">
                    <div className="mx-4">
                        <div className="row text-left my-sm-5 mt-5">
                            <div className="col-md-4 align-self-start">
                                <h2 className="text-main text-gradient mb-0 text-uppercase">Nossos Parceiros</h2>
                                <hr className="my-2" />
                            </div>
                        </div>
                    </div>

                    <div className="mx-5">
                        <Slider {...sliderClientsSettings}>
                            {
                                clients_list.map((client) => {
                                    return (
                                        <Grow key={`client__${client.title}`} in={fadeIn}>
                                            <div className="p-3">
                                                <ClientCard img={client.image} title={client.title} />
                                            </div>
                                        </Grow>
                                    )
                                })
                            }
                        </Slider>

                    </div>
                </div>
            </section>
            <section id="advantages" className="py-4" style={{ minHeight: '100vh' }}>
                <div className="mx-4">
                    <div className="card mx-0 mt-5" style={{ height: '80vh', backgroundColor: '#617689', backgroundImage: `url(${backgrounds.new.bg_011})`, backgroundPosition: 'center', backgroundSize: 'cover' }} >
                        <div className="mx-5 h-100 row align-items-center text-center my-sm-5">
                            <div className="col-lg-6 mx-auto">
                                <h2 className="text-main mb-3">Clube de vantagens</h2>
                                <p className="text-white">
                                    {"Conheça todos os locais em que você tem várias vantagens por fazer parte da família Becourse!"}
                                </p>
                                <div className="col-md-12 text-center">
                                    <Link to="/login" fullWidth type="button" className="btn bg-gradient-light mt-3 mb-0 w-100" style={{ borderRadius: 50 }}>Clique aqui e descubra!</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-header section-height-85" id="contact">
                <img style={{opacity: 0.5}} className="position-absolute fixed-top blur ms-auto h-100 z-index-0 d-none d-sm-none d-md-block border-radius-section border-top-end-radius-0 border-top-start-radius-0 border-bottom-end-radius-0" src={backgrounds.new.bg_008} alt="Contact-us background" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7 d-flex justify-content-center flex-column">
                            <div className="card d-flex blur justify-content-center p-4 shadow-lg my-sm-0 my-sm-6 mt-8 mb-5">
                                <div className="text-center">
                                    <h3 className="text-gradient text-dark">Fale conosco...</h3>
                                    <p className="mb-0">
                                        {"Que tal marcarmos uma reunião ou videoconferência para conhecer o projeto?"}
                                    </p>
                                </div>
                                <form className="pb-1" id="contact-form" method="post" autoComplete="off" onSubmit={submitContactForm}>
                                    <div className="card-body pb-2">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <TextField
                                                    fullWidth
                                                    label="Seu nome"
                                                    placeholder="Favor, insira aqui o seu nome"
                                                    variant="filled"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <TextField
                                                    fullWidth
                                                    label="Telefone para contato?"
                                                    type="tel"
                                                    placeholder="Favor, insira aqui o seu telefone"
                                                    variant="filled"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <TextField
                                            fullWidth
                                            type="email"
                                            label="Seu melhor e-mail?"
                                            placeholder="Ex.: meuemail@becourse.com.br"
                                            variant="filled"
                                            required
                                        />
                                        <FormControl variant="filled" required fullWidth>
                                            <InputLabel shrink id="label_course_select">
                                                Interesse em qual curso?
                                            </InputLabel>
                                            <Select
                                                labelId={"label_course_select"}
                                                value={course}
                                                onChange={handleChangeCourseSelect}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="" disabled>
                                                    Selecione o curso de interesse
                                                </MenuItem>
                                                {
                                                    courses.map(c => {
                                                        return (
                                                            <MenuItem key={`select__${c.title}`} value={c.title}>{c.title}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            fullWidth
                                            label="Mensagem"
                                            multiline
                                            rows={4}
                                            placeholder="Insira aqui a sua mensagem..."
                                            variant="filled"
                                            required
                                        />
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <Button fullWidth type="submit" className="btn bg-gradient-dark mt-3 mb-0 w-100" style={{ borderRadius: 50 }}>Enviar Mensagem</Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 d-flex justify-content-center flex-column my-5 ">
                            <div>
                                <div className="card min-blur">
                                    <div className="card-body pt-3 pb-0">
                                        <div className="row">
                                            <div className="col-1">
                                                <i className="fas fa-map-marker-alt text-dark"></i>
                                            </div>
                                            <div className="col-10">
                                                <p>
                                                    {"Cuiabá MT - Brazil"}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-1">
                                                <i className="fas fa-phone-alt text-dark"></i>
                                            </div>
                                            <div className="col-10">
                                                <p>
                                                    <span className="d-none d-md-inline-block">{"Telefone: "}</span> {"+55 65 98472-8167"}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-1">
                                                <i className="fas fa-at text-dark"></i>
                                            </div>
                                            <div className="col-10">
                                                <p>
                                                    <span className="d-none d-md-inline-block">{"E-mail: "}</span> {"comercial@becourse.com.br"}
                                                </p>
                                            </div>
                                            <div className="col-1"></div>
                                            <div className="col-1">
                                                <i className="fas fa-at text-dark"></i>
                                            </div>
                                            <div className="col-10">
                                                <p>
                                                    <span className="d-none d-md-inline-block">{"E-mail: "}</span> <small>{"administrativo@becourse.com.br"}</small>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-1">
                                                <i className="fa fa-instagram text-dark"></i>
                                            </div>
                                            <div className="col-10">
                                                <p>
                                                    <a className="" style={{ color: '#67748e' }} rel="noreferrer" href="https://instagram.com/becourseof" target="_blank">
                                                        <span className="d-none d-md-inline-block">{"Instagram: "}</span> {"@becourseof"}
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-1">
                                                <i className="fa fa-facebook text-dark"></i>
                                            </div>
                                            <div className="col-10">
                                                <p>
                                                    <a className="" style={{ color: '#67748e' }} rel="noreferrer" href="https://facebook.com/becourseof" target="_blank"> <span className="d-none d-md-inline-block">{"Facebook: "}</span> {"/becourseof"}</a>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-1">
                                                <i className="fa fa-linkedin text-dark"></i>
                                            </div>
                                            <div className="col-10">
                                                <p>
                                                    <a className="" style={{ color: '#67748e' }} rel="noreferrer" href="https://www.linkedin.com/company/becourseen" target="_blank"><span className="d-none d-md-inline-block">{"LinkedIn: "}</span> {"/company/becourseen"}</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <SiteFooter />
            <ScrollToTop showBelow={300} />
            <WhatsAppButton always showBelow={10} />

            {/** Modal Courses */}
            <ModalCourses open={openDetailCourse} handleClose={handleCloseCourseModal} showContent={selectedCourse != null} course={selectedCourse} />
        </div>
    )
}

export default LandingPage