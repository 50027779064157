import React, { useState, useRef } from 'react'
import JoditEditor from 'jodit-react'

const DefaultTextEditor = (props) => {
    const { onChange, value, readonly, placeholder } = props;
    const editor = useRef(null)

    const config = {
        readonly: readonly ? true : false, // all options from https://xdsoft.net/jodit/doc/
        allowResizeX: false,
        allowResizeY: false,
        height: '50vh',
        placeholder: placeholder ? placeholder : 'Insira aqui a sua sugestão'
    }

    return (
        <JoditEditor
            ref={editor}
            value={value}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={newContent => onChange(newContent)} // preferred to use only this option to update the content for performance reasons
        // onChange={newContent => { setContent(newContent) }}
        />
    )
}

export default DefaultTextEditor