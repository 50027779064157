import React, { useState } from 'react';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { Center } from '@chakra-ui/layout';
import ExtraFilesDialog from '../dialogs/extra_files_dialog';

const TabExtras = (props) => {
    const { extras } = props;

    const [selectedExtra, setSelectedExtra] = useState(null)


    return (
        <div className="w-100 row">
            {
                extras.map((extra, indice) => {
                    return (
                        <div className="p-2 col-3">
                            <div onClick={() => { setSelectedExtra(extra) }} className="card bg-main text-white cursor-pointer" key={`${indice}_tab-extras_${extra.id}`}>
                                <Center className="mt-2">
                                    <MenuBookIcon style={{ fontSize: 50 }} />
                                </Center>
                                <p className="px-2 text-center">
                                    {extra.nome}
                                </p>
                            </div>

                        </div>
                    )
                })
            }

            {
                extras.length == 0 &&
                <div className="card p-4">
                    <p>Nenhum encontrado.</p>
                </div>

            }

            {
                selectedExtra != null &&
                <ExtraFilesDialog open={true} extraSelected={selectedExtra} onClose={() => setSelectedExtra(null)} />
            }
        </div>
    );
}

export default TabExtras