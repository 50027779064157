import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TimelineClass from '../timelines/timeline_class';
import { getAudios, getAudiosPerPage, getExtras, getLessons, getTurmaAllData } from '../../../../repositories/turma_repository';
import TabLessons from './tab_lessons';
import TabAudios from './tab_audios';
import TabExtras from './tab_extras';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const { timeLineBlock, setTimeLineBlock} = useState('carregando...');

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const TabsStudentHome = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { turma } = props;
  const [selectedTurma, setSelectedTurma] = useState({})
  const [lessons, setLessons] = useState([])
  const [audios, setAudios] = useState([])
  const [extras, setExtras] = useState([])

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index); 
  };

  const getAllDataTurma = async () => {
      if (turma !== null) {
        let result = await getTurmaAllData(turma.id)
        if (result.success) {
          setSelectedTurma(result.turma)
        }
      }
  }

  const getTurmaLessons = async () => {
      if (turma !== null) {
        let result = await getLessons(turma.id)

        if (result.success) {
          setLessons(result.lessons)
        }
      }
  }

  const handleChangeAudios = async (value) => {
    let result = await getAudiosPerPage(value)

    if (result.success) {
      setAudios(result.audios)
    }
  }

  const getAllAudios = async () => {
    let result = await getAudios()

    if (result.success) {
      setAudios(result.audios)
    }
  }

  const getAllExtras = async () => {
    let result = await getExtras()

    if (result.success) {
      setExtras(result.extras)
    }
  }

  useEffect(() => {
    getAllDataTurma()
    getTurmaLessons()
    getAllAudios()
    getAllExtras()
  }, [turma])

  return (
    <div className={classes.root}>
      <AppBar className="px-3 w-100" position="static" color="default" style={{ borderRadius: 15 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          aria-label="full width tabs example"
        >
          <Tab label="Timeline" {...a11yProps(0)} />
          <Tab label="Lições" {...a11yProps(1)} />
          <Tab label="Áudios" {...a11yProps(2)} />
          <Tab label="Extras" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <TimelineClass topics={selectedTurma ? selectedTurma.topics : []} turma={selectedTurma}/>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <TabLessons lessons={lessons} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <TabAudios audios={audios} changeAudios={handleChangeAudios} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <TabExtras extras={extras} />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}

export default TabsStudentHome