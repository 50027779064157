import React,{ useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getFormatedDate } from '../../../../utils/functions';
import CreateDiscussion from '../modals/create_discussion';
import SaveTask from '../modals/save_task';
import { getUserTurma } from '../../../../repositories/user_repository';
import { getDiscussoes } from '../../../../repositories/turma_repository';
import userContext from '../../Context/UserContext';
import { format, isAfter } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: "#00234e",
    },
}));

const CardTimeline = (props) => {

    const classes = useStyles();
    const { showMore, topic, turma } = props;

    const [expanded, setExpanded] = React.useState(false);
    const [openedModal, setOpenedModal] = useState(false);
    const [taskClicked, setTaskClicked] = useState([]);
    // const [turma, setTurma] = useState([]);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const sucessoEnvio = () => {

    };


    // const getTurmaUserLogged = async () => {
    //     let result = await getUserTurma()

    //     if (result.success) {
    //         setTurma(result.turma);
    //         // return (result.turma)
    //     }

    //     return {}
    // }

    const openModalCreateDiscussao = (task) => {
        setOpenedModal(!openedModal)
    }

    const getDiscussions = async () => {
        if (turma) {
            var result = await getDiscussoes(turma.id)
        }
    }

    useEffect(() => {
        // getTurmaUserLogged();
        getDiscussions()
    }, [])

    const onSavedDiscussion = () => {
        console.log('salvando');
    }

    return (
        <div className="py-2">
            {
                topic ?
                    <div className="card">
                        <CardHeader
                            avatar={
                                <Avatar aria-label="recipe" component={'span'} className={classes.avatar} id={topic.id}>
                                    {"B"}
                                </Avatar>
                            }
                            title={`#${topic.id} - ${topic.titulo}`}
                            subheader={getFormatedDate(new Date(topic.created), true)}
                        />
                        
                        {
                            topic.tasks &&
                            <div>
                                {
                                    topic.tasks.map((task) => {
                                        const dataFim = task.data_fim !== "0000-00-00 00:00:00" ? task.data_fim : null;
                                        
                                        const dataLimite = dataFim ? new Date(dataFim) : null;
                                        
                                        const prazoNaoPermitido = dataLimite ? isAfter(new Date(), dataLimite) : false;
                                        
                                        return (
                                            <div key={task.id} style={{ marginLeft: '67px', marginBottom: '15px' }}>
                                                <p>Tarefa disponível: <b>{task.nome}</b></p>
                                                {dataLimite && (
                                                    <p>Data limite: <b>{format(dataLimite, 'dd/MM/yyyy HH:mm:ss')}</b></p>
                                                )}
                                                {task.answer > 0 ? (
                                                    <>
                                                        <font color="green"><b>Tarefa já respondida!</b></font>
                                                        <br />
                                                        {!prazoNaoPermitido && (
                                                            <a
                                                                style={{ cursor: 'pointer', color: 'blue' }}
                                                                className="mt-3 mb-0 w-15"
                                                                onClick={() => { openModalCreateDiscussao(); setTaskClicked(task) }}
                                                            >
                                                                Reenviar
                                                            </a>
                                                        )}
                                                    </>
                                                ) : (
                                                    <button
                                                        className="btn bg-gradient-main mt-3 mb-0 w-15"
                                                        key={task.id}
                                                        onClick={() => { openModalCreateDiscussao(); setTaskClicked(task) }}
                                                    >
                                                        Responder
                                                    </button>
                                                )}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        }

                        {/* <CardContent> */}
                            {/* {topic.data && topic.data.map((item) => {
                                return (<>
                                    <hr />
                                    <Typography variant="p" component="p">
                                        <b>{"Histórico da aula"}</b>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {item.descricao}
                                    </Typography>
                                </>
                                )
                            })} */}

                        {/* </CardContent> */}
                        {
                            showMore &&
                            (
                                <>
                                    <CardActions disableSpacing>
                                        {"O que mais aconteceu na aula?"}
                                        <IconButton
                                            className={clsx(classes.expand, {
                                                [classes.expandOpen]: expanded,
                                            })}
                                            onClick={handleExpandClick}
                                            aria-expanded={expanded}
                                            aria-label="show more"
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </CardActions>
                                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                                        <CardContent>
                                            <Typography paragraph>Method:</Typography>
                                            <Typography paragraph>
                                                Heat 1/2 cup of the broth in a pot until simmering, add saffron and set aside for 10
                                                minutes.
                                            </Typography>
                                            <Typography paragraph>
                                                Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high
                                                heat. Add chicken, shrimp and chorizo, and cook, stirring occasionally until lightly
                                                browned, 6 to 8 minutes. Transfer shrimp to a large plate and set aside, leaving chicken
                                                and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
                                                pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add
                                                saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
                                            </Typography>
                                            <Typography paragraph>
                                                Add rice and stir very gently to distribute. Top with artichokes and peppers, and cook
                                                without stirring, until most of the liquid is absorbed, 15 to 18 minutes. Reduce heat to
                                                medium-low, add reserved shrimp and mussels, tucking them down into the rice, and cook
                                                again without stirring, until mussels have opened and rice is just tender, 5 to 7
                                                minutes more. (Discard any mussels that don’t open.)
                                            </Typography>
                                            <Typography>
                                                Set aside off of the heat to let rest for 10 minutes, and then serve.
                                            </Typography>
                                        </CardContent>
                                    </Collapse>

                                </>
                            )
                        }
                    </div>
                    :
                    <div className="card">
                        <CardHeader
                            avatar={
                                <Avatar aria-label="recipe" className={classes.avatar}>
                                    {"R"}
                                </Avatar>
                            }
                            title="#9734 - 000 - No c - Warm up review"
                            subheader="19 June 2021"
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" >
                                Nenhum aluno compareceu a aula.
                            </Typography>
                        </CardContent>
                        {
                            !showMore &&
                            (
                                <>
                                    <CardActions disableSpacing>
                                        {"O que mais aconteceu na aula?"}
                                        <IconButton
                                            className={clsx(classes.expand, {
                                                [classes.expandOpen]: expanded,
                                            })}
                                            onClick={handleExpandClick}
                                            aria-expanded={expanded}
                                            aria-label="show more"
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </CardActions>
                                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                                        <CardContent>
                                            <Typography paragraph>Method:</Typography>
                                            <Typography paragraph>
                                                Heat 1/2 cup of the broth in a pot until simmering, add saffron and set aside for 10
                                                minutes.
                                            </Typography>
                                            <Typography paragraph>
                                                Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high
                                                heat. Add chicken, shrimp and chorizo, and cook, stirring occasionally until lightly
                                                browned, 6 to 8 minutes. Transfer shrimp to a large plate and set aside, leaving chicken
                                                and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
                                                pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add
                                                saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
                                            </Typography>
                                            <Typography paragraph>
                                                Add rice and stir very gently to distribute. Top with artichokes and peppers, and cook
                                                without stirring, until most of the liquid is absorbed, 15 to 18 minutes. Reduce heat to
                                                medium-low, add reserved shrimp and mussels, tucking them down into the rice, and cook
                                                again without stirring, until mussels have opened and rice is just tender, 5 to 7
                                                minutes more. (Discard any mussels that don’t open.)
                                            </Typography>
                                            <Typography>
                                                Set aside off of the heat to let rest for 10 minutes, and then serve.
                                            </Typography>
                                        </CardContent>
                                    </Collapse>

                                </>
                            )
                        }
                    </div>
            }

            {/* <CreateDiscussion
                open={openedModal}
                onClose={openModalCreateDiscussao}
                onSuccess={sucessoEnvio}
                infoTask={taskClicked}
                turma={turma}
            />  */}

            <SaveTask
                open={openedModal}
                onClose={openModalCreateDiscussao}
                onSuccess={sucessoEnvio}
                infoTask={taskClicked}
                topic={topic}
                turma={turma}
            /> 
        </div>


    );
}


export default CardTimeline