// 
import { IconButton, makeStyles, useTheme, Zoom } from '@material-ui/core'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles((theme) => ({
    toTop: {
        zIndex: 2,
        position: 'fixed',
        bottom: '2vh',
        right: '2vw',
        backgroundColor: "green",
        color: '#ffffff',
        "&:hover, &.Mui-focusVisible": {
            transition: '0.3s',
            color: '#ffffff',
            backgroundColor: 'darkGreen'
        }
    }
}))

const WhatsAppButton = (props) => {
    const classes = useStyles()
    const theme = useTheme()
    const { showBelow, always } = props
    const [show, setShow] = useState(always ? true : showBelow ? false : true)

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    }

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        } else {
            if (show) setShow(false)
        }
    }

    const enableShow = () => {
        setShow(true)
    }

    useEffect(() => {
        if (always) {
            enableShow()
        } else {

            if (showBelow) {
                window.addEventListener('scroll', handleScroll)
                return () => window.removeEventListener('scroll', handleScroll)
            }
        }
    })

    return (
        <div>
            <Zoom
                in={show}
                timeout={transitionDuration}
                style={{
                    transitionDelay: `${transitionDuration.exit}ms`,
                }}
                unmountOnExit
            >
                <IconButton target="_blank" href={'https://api.whatsapp.com/send?phone=556598472-8167&text=Ol%C3%A1!%20Estou%20entrando%20em%20contato%20atrav%C3%A9s%20do%20seu%20site!'} className={classes.toTop}>
                    <WhatsAppIcon />
                </IconButton>
            </Zoom>
        </div>
    )

}

export default WhatsAppButton