import React, { useEffect, useState } from 'react';
import { logos } from '../../../core/app_images';
import TopNavigator from '../components/navigator/top_navigator';
import Sidebar from '../components/sidebar/sidebar';
import '../../../assets/css/soft-ui-dashboard.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SendIcon from '@material-ui/icons/Send';
import { Avatar, CircularProgress, makeStyles, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { getUser } from '../../../services/auth';
import { getFriends, getMessages } from '../../../repositories/user_repository';
import { sendFriendMessage } from '../../../repositories/message_repository';
import { getFormatedDate } from '../../../utils/functions';
import Footer from '../../shared/components/footer/footer';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const StudentMessagesPage = () => {
    const classes = useStyles();
    const [showNavbar, setShowNavbar] = useState(false);
    const [isChating, setIsChating] = useState(false);
    const [loadingFriends, setLoadingFriends] = useState(true);
    const [friends, setFriends] = useState([]);
    const [messages, setMessages] = useState([]);
    const [selectedFriend, setSelectedFriend] = useState();
    const [messageTyped, setMessageTyped] = useState();

    const onChangeNavbar = (opt) => {
        setShowNavbar(opt);
    }

    const loadFriends = async () => {
        setLoadingFriends(true)
        let logged = getUser()

        let result = await getFriends(logged.id)

        if (result.success) {
            setFriends(result.friends)
        }

        setLoadingFriends(false)
    }

    const selectFriend = async (id) => {
        let result = await getMessages(id);
        if (result.success) {
            setSelectedFriend(id)
            setMessages(result.messages)
            setIsChating(true)
        }

    }

    const sendMessage = async (ev) => {
        ev.preventDefault();

        let send = {
            fk_destinatario: selectedFriend,
            mensagem: messageTyped
        }

        let result = await sendFriendMessage(send)
        if (result.success) {
            selectFriend(selectedFriend)
            setMessageTyped('')
        }
    }

    useEffect(() => {
        loadFriends()
    }, [])

    return (
        <div className={`g-sidenav-show bg-gray-100${showNavbar ? " g-sidenav-pinned" : ""}`}>
            <Sidebar logo={logos.navbar} show={showNavbar} background={'bg-main'} onCloseSidebar={onChangeNavbar} />
            <div className="main-content position-relative bg-gray-100">
                <TopNavigator title="Mensagens" breadcrumps={[{ title: 'Início', link: '/student' }]} showNavbar={onChangeNavbar} navbarOpen={showNavbar} />
                <div className="container-fluid">
                    <div className="row py-4">
                        <div className="col-12 col-md-4">
                            <div className="row">
                                <div className="col-12 py-4" >
                                    <div className="card h-100">
                                        <div className="card-header pb-0 p-3">
                                            <h6 className="mb-0">Amigos</h6>
                                            <hr />
                                        </div>
                                        <div className="card-body p-3" style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                                            <List
                                                component="nav"
                                                aria-labelledby="nested-list-subheader"
                                                className={classes.root}
                                            >
                                                {
                                                    loadingFriends ?
                                                        <ListItem button style={{ borderRadius: 50 }}>
                                                            <CircularProgress style={{ marginRight: 15 }} />
                                                            <ListItemText primary={"Carregando lista..."} />
                                                        </ListItem>
                                                        :
                                                        friends.map((friend, iterator) => {
                                                            return <ListItem onClick={() => selectFriend(friend.id)} key={`${iterator}___friend-${friend.id}`} button>
                                                                <ListItemIcon>
                                                                    <Avatar>
                                                                        {friend.nome[0]}
                                                                    </Avatar>
                                                                </ListItemIcon>
                                                                <ListItemText primary={friend.nome} />
                                                            </ListItem>
                                                        })
                                                }
                                                {
                                                    friends.length === 0 && !loadingFriends &&
                                                    <ListItem button style={{ borderRadius: 50 }}>
                                                        <ListItemText primary={"Infelizmente você não tem nenhum amigo... :("} />
                                                    </ListItem>
                                                }
                                            </List>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-8 py-4">
                            <div className="card h-100">
                                <div className="card-header pb-0 p-3">
                                    <h6 className="mb-0">Chat</h6>
                                </div>
                                <div className="card-body p-3 w-100">
                                    {
                                        isChating ?
                                            <Grid item xs={12}>
                                                <List style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                                                    {
                                                        messages.map((msg) => {
                                                            if (msg.fk_remetente !== selectedFriend) {
                                                                return <ListItem key={`message__${msg.id}`}>
                                                                    <Grid container>
                                                                        <Grid item xs={12}>
                                                                            <ListItemText align="right" primary={msg.mensagem}></ListItemText>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <ListItemText align="right" secondary={getFormatedDate(new Date(msg.created), true)}></ListItemText>
                                                                        </Grid>
                                                                    </Grid>
                                                                </ListItem>
                                                            }
                                                            else {
                                                                return <ListItem key={`message__${msg.id}`}>
                                                                    <Grid container>
                                                                        <Grid item xs={12}>
                                                                            <ListItemText align="left" primary={msg.mensagem}></ListItemText>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <ListItemText align="left" secondary={getFormatedDate(new Date(msg.created), true)}></ListItemText>
                                                                        </Grid>
                                                                    </Grid>
                                                                </ListItem>
                                                            }
                                                        })
                                                    }
                                                    {
                                                        messages.length === 0 &&
                                                        <div className="text-center my-auto mx-auto h-100">
                                                            {"Nenhuma mensagem encontrada neste chat..."}
                                                        </div>
                                                    }
                                                </List>
                                                <Divider />
                                                <form onSubmit={sendMessage}>
                                                    <Grid container style={{ padding: '20px' }}>
                                                        <Grid item xs={11}>
                                                            <TextField
                                                                label="Digite a mensagem, aqui..."
                                                                value={messageTyped}
                                                                onChange={(ev) => setMessageTyped(ev.target.value)}
                                                                fullWidth />
                                                        </Grid>
                                                        <Grid xs={1} align="right">
                                                            <IconButton type="submit" color="primary" aria-label="add"><SendIcon /></IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            </Grid> :
                                            <div className="text-center my-auto mx-auto h-100">
                                                {"Selecione um amigo para enviar mensagens"}
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default StudentMessagesPage;
