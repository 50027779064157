import { apiAuth } from "../services/api"

const initialRoute = "usuario/messages"

const getMessages = async (id) => {
    let response = await apiAuth.get(`/${initialRoute}/${id}`);

    if (response.status === 200 || response.status === 201) {
        let res = response.data;

        if (res.success) {

            return {
                success: true,
                messages: res.data
            };
        }
        return { success: false };
    }
    return { success: false };
}

const sendFriendMessage = async (data) => {
    let response = await apiAuth.post(`/${initialRoute}`, data);

    if (response.status === 201) {
        let res = response.data;

        return {
            success: true,
            message: res
        }
    }
    return { success: false };
}

export {
    sendFriendMessage,
    getMessages
}