import React, { useState } from 'react';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { Center } from '@chakra-ui/layout';

import LessonsFilesDialog from '../dialogs/lessons_files_dialog'

const TabLessons = (props) => {
    const { lessons } = props;

    const [selectedLesson, setSelectedLesson] = useState(null)

    return (
        <div className="w-100 row">
            {
                lessons.map((lesson, indice) => {
                    return (
                        <div className="p-2 col-3">
                            <div onClick={() => { setSelectedLesson(lesson) }} className="card bg-main text-white cursor-pointer" key={`${indice}_tab-lessons_${lesson.id}`}>
                                <Center className="mt-2">
                                    <MenuBookIcon style={{ fontSize: 50 }} />
                                </Center>
                                <Center>
                                    <p className="px-2">
                                        {lesson.nome}
                                    </p>
                                </Center>
                            </div>

                        </div>
                    )
                })
            }

            {
                lessons.length == 0 &&
                <div className="card p-4">
                    <p>Nenhuma lição encontrada.</p>
                </div>
            }

            {
                selectedLesson != null &&
                <LessonsFilesDialog open={true} lessonSelected={selectedLesson} onClose={() => setSelectedLesson(null)} />
            }
        </div>
    );
}

export default TabLessons