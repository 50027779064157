import { apiAuth } from "../services/api"

const initialRoute = "turmas"

const getTurmaAllData = async (id) => {
    if (id === undefined) {
        return {
            success: false,
            turma: {}
        };
    }

    let response = await apiAuth.get(`${initialRoute}/allData/${id}`)
    if (response.status === 200) {
        let res = response.data;

        if (res.success) {

            return {
                success: true,
                turma: res.data
            };
        }
        return { success: false };
    }
    return { success: false };
}

const getAllTurmasByAlunoId = async (id) => {
    let response = await apiAuth.get(`${initialRoute}/aluno/${id}`)

    if (response.status === 200) {
        let res = response.data;

        if (res.success) {

            return {
                success: true,
                turmas: res.data
            };
        }
        return { success: false };
    }
    return { success: false };
}

const getDiscussoes = async (id) => {
    let response = await apiAuth.get(`${initialRoute}/discussoes/turma/${id}`)

    if (response.status === 200) {
        let res = response.data;

        if (res.success) {

            return {
                success: true,
                discussoes: res.data
            };
        }
        return { success: false };
    }
    return { success: false };
}

const getDiscussaoById = async (id) => {
    let response = await apiAuth.get(`${initialRoute}/discussoes/${id}`)

    if (response.status === 200) {
        let res = response.data;

        if (res.success) {

            return {
                success: true,
                discussao: res.data
            };
        }
        return { success: false };
    }
    return { success: false };
}

const createDiscussion = async (data) => {

    let response = await apiAuth.post(`${initialRoute}/discussoes`, data)

    if (response.status === 201) {
        let res = response.data;
        return {
            success: true,
            discussao: res.data
        };
    }
    return { success: false };
}

const updateDiscussion = async (data, id) => {
    let response = await apiAuth.post(`${initialRoute}/discussoes/update/${id}`, data)

    if (response.status === 201) {
        let res = response.data;
        return {
            success: true,
            discussao: res.data
        };
    }
    return { success: false };
}

const saveTaskForm = async (data, id) => {
    let response = await apiAuth.post(`${initialRoute}/tasks/${id}`, data)
    
    if (response.data.success === true) {
        let res = response.data;
        return {
            success: true,
            task: res.data
        };
    }
    return { success: false };
}

const getLessons = async (id) => {
    if (id === undefined) {
        return {
            success: false,
            lessons: {}
        };
    }
    let response = await apiAuth.get(`${initialRoute}/lessons/turma/${id}`)

    if (response.status === 200) {
        let res = response.data;
        return {
            success: true,
            lessons: res.data
        };
    }
    return { success: false };
}

const getLessonsFiles = async (id) => {
    let response = await apiAuth.get(`${initialRoute}/lessons/files/${id}`)

    if (response.status === 200) {
        let res = response.data;
        return {
            success: true,
            files: res.data
        };
    }
    return { success: false };
}

const getAudios = async () => {
    let response = await apiAuth.get(`${initialRoute}/audios`)

    if (response.status === 200) {
        let res = response.data;
        return {
            success: true,
            audios: res.data
        };
    }
    return { success: false };
}

const getAudiosPerPage = async (page) => {
    let response = await apiAuth.get(`${initialRoute}/audios?page=${page}`)

    if (response.status === 200) {
        let res = response.data;
        return {
            success: true,
            audios: res.data
        };
    }
    return { success: false };
}

const getExtras = async () => {
    let response = await apiAuth.get(`${initialRoute}/extra`)

    if (response.status === 200) {
        let res = response.data;
        return {
            success: true,
            extras: res.data
        };
    }
    return { success: false };
}

const getExtrasFiles = async (id) => {
    let response = await apiAuth.get(`${initialRoute}/extra/files/${id}`)

    if (response.status === 200) {
        let res = response.data;
        return {
            success: true,
            files: res.data
        };
    }
    return { success: false };
}

const getUrlToDownloadExtraFile = async (id) => {
    let res = await apiAuth.get(`${initialRoute}/extra/download/byId/${id}`)

    return res.data;
}

const getUrlToDownloadLessonFile = async (id) => {

    let res = await apiAuth.get(`${initialRoute}/lessons/download/byId/${id}`)

    return res.data;
}

const getPerformanceByAlunoId = async (id, etapa) => {
    let response = await apiAuth.get(`${initialRoute}/performance/aluno/${id}/${etapa}`)

    if (response.status === 200) {
        let res = response.data;
        return {
            success: true,
            performance: res.data
        }
    } else {
        return {
            success: false,
            performance: null
        }
    }
}

export {
    getTurmaAllData,
    getAllTurmasByAlunoId,
    getDiscussaoById,
    getDiscussoes,
    createDiscussion,
    updateDiscussion,
    getLessons,
    getLessonsFiles,
    getUrlToDownloadLessonFile,
    getAudios,
    getAudiosPerPage,
    getExtras,
    getExtrasFiles,
    getUrlToDownloadExtraFile,
    getPerformanceByAlunoId,
    saveTaskForm
}