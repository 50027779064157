import { motion } from 'framer-motion'

const MotionDiv = motion.div;
const MotionUl = motion.ul;
const MotionLi = motion.li;

const containerCard = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.3
        }
    }
}

const containerCardFastChild = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.2,
            staggerChildren: 0.1
        }
    }
}

const itemsCard = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1
    }
}

export {
    MotionDiv,
    MotionUl,
    MotionLi,
    containerCard,
    containerCardFastChild,
    itemsCard
}