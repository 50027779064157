//Logos
import logoNav from "../assets/img/logos/becourse-min.jpg";
import iconBeCourse from "../assets/img/icon.png";
import logoCoinbase from "../assets/img/logos/gray-logos/logo-coinbase.svg";
import logoNasa from "../assets/img/logos/gray-logos/logo-nasa.svg";
import logoNetflix from "../assets/img/logos/gray-logos/logo-netflix.svg";
import logoPinterest from "../assets/img/logos/gray-logos/logo-pinterest.svg";
import logoSpotify from "../assets/img/logos/gray-logos/logo-spotify.svg";
import logoVadafone from "../assets/img/logos/gray-logos/logo-vodafone.svg";
import sicredi from "../assets/img/logos/clients/sicredi.png";
import sicoob from "../assets/img/logos/clients/sicoob.png";
import domani from "../assets/img/logos/clients/domani.png";
import brf from "../assets/img/logos/clients/brf.png";
import localiza from "../assets/img/logos/clients/localiza.png";
import cvl from "../assets/img/logos/clients/cvl.png";
import pantanalShopping from "../assets/img/logos/clients/pantanalShopping.jpg";
import latam from "../assets/img/logos/clients/latam.png";
import scheffer from "../assets/img/logos/clients/sheffer.png";
import azulLogo from "../assets/img/logos/clients/azul.png";
import eletricaParana from "../assets/img/logos/clients/eletrica_parana.jpg";
import odara from "../assets/img/logos/clients/odara.png";
import agroAmazonia from "../assets/img/logos/clients/agro_amazonia.png";
import abih from "../assets/img/logos/clients/abih.jpg";
import brookfield from "../assets/img/logos/clients/brookfield.jpg";
import lucianaDuarte from "../assets/img/logos/clients/luciana_duarte.jfif";
import sebraeMT from "../assets/img/logos/clients/sebrae_mt.png";
import sescPantanal from "../assets/img/logos/clients/sesc_pantanal.jpg";
import sescoopMT from "../assets/img/logos/clients/sescoop_mt.jpg";
import solucaoCosmeticos from "../assets/img/logos/clients/solucao_cosmeticos.jfif";

//Small Logos
import smallLogoXd from "../assets/img/small-logos/logo-xd.svg";
import smallLogoAtlassian from "../assets/img/small-logos/logo-atlassian.svg";
import smallLogoSpotify from "../assets/img/small-logos/logo-spotify.svg";
import smallLogoSlack from "../assets/img/small-logos/logo-slack.svg";
import smallLogoJira from "../assets/img/small-logos/logo-jira.svg";
import smallLogoInvision from "../assets/img/small-logos/logo-invision.svg";

//Curved
import whiteCurved from "../assets/img/curved-images/white-curved.jpeg";
import curved0 from "../assets/img/curved-images/curved0.jpg";
import curved6 from "../assets/img/curved-images/curved6.jpg";

//Shapes
import waveWhite from "../assets/img/shapes/waves-white.svg";
import wavesManyWhite from "../assets/img/shapes/waves-many-white.svg";

// Illustrations
import rocketWhite from "../assets/img/illustrations/rocket-white.png";
import signUp from "../assets/img/illustrations/sign-up.png";

// People
import ivancik from "../assets/img/ivancik.jpg";
import bruceMars from "../assets/img/bruce-mars.jpg";
import kalVisuals from "../assets/img/kal-visuals-square.jpg";
import marie from "../assets/img/marie.jpg";
import ivana from "../assets/img/ivana-square.jpg";

//Backgrounds
import bg_001 from "../assets/img/bg_001.jpg"
import bg_002 from "../assets/img/bg_002.png"
import bg_003 from "../assets/img/bg_003.jpg"
import bg_010 from "../assets/img/bg_010.jpg"
import bg_001_dark from "../assets/img/bg_004.png"
import bg_004 from "../assets/img/annie-spratt.jpg"

import n_bg_001 from "../assets/img/backgrounds/001.jpg"
import n_bg_002 from "../assets/img/backgrounds/002.jpeg"
import n_bg_003 from "../assets/img/backgrounds/002.jpg"
import n_bg_004 from "../assets/img/backgrounds/003.jpeg"
import n_bg_005 from "../assets/img/backgrounds/004.jpg"
import n_bg_006 from "../assets/img/backgrounds/006.jpeg"
import n_bg_007 from "../assets/img/backgrounds/007.png"
import n_bg_008 from "../assets/img/backgrounds/008.jpeg"
import n_bg_009 from "../assets/img/blog7-1.webp"
import n_bg_010 from "../assets/img/backgrounds/010.jpg"
import n_bg_011 from "../assets/img/backgrounds/011.webp"

// default
import team1 from "../assets/img/team-1.jpg";
import team2 from "../assets/img/team-2.jpg";
import team3 from "../assets/img/team-3.jpg";
import team4 from "../assets/img/team-4.jpg";
import homeDecor1 from "../assets/img/home-decor-1.jpg";
import homeDecor2 from "../assets/img/home-decor-2.jpg";
import homeDecor3 from "../assets/img/home-decor-3.jpg";
import downArrowDefault from "../assets/img/down-arrow.svg";
import downArrowWhite from "../assets/img/down-arrow-white.svg";
import downArrowDark from "../assets/img/down-arrow-dark.svg";
import officeDark from '../assets/img/office-dark.jpg';
import meeting1 from '../assets/img/meeting.jpg';
import meeting2 from '../assets/img/meeting_2.jpg';
import collaboration from '../assets/img/collaboration.png';

const logos = {
    navbar: logoNav,
    icon: iconBeCourse,
    gray: {
        coinbase: logoCoinbase,
        nasa: logoNasa,
        netflix: logoNetflix,
        pinterest: logoPinterest,
        spotify: logoSpotify,
        vadafone: logoVadafone,
    },
    clients: {
        sicredi,
        sicoob,
        domani,
        brf,
        localiza,
        cvl,
        pantanalShopping,
        latam,
        scheffer,
        azul: azulLogo,
        eletricaParana,
        odara,
        agroAmazonia,
        abih,
        brookfield,
        lucianaDuarte,
        sebraeMT,
        sescPantanal,
        sescoopMT,
        solucaoCosmeticos
    }

}

const smallLogos = {
    xd: smallLogoXd,
    atlassian: smallLogoAtlassian,
    spotify: smallLogoSpotify,
    slack: smallLogoSlack,
    jira: smallLogoJira,
    invision: smallLogoInvision,
}

const curved = {
    white: whiteCurved,
    curved_0: curved0,
    curved_6: curved6,
}

const illustrations = {
    rocket: {
        white: rocketWhite
    },
    signUp,
}

const shapes = {
    waves: {
        white: waveWhite,
        manyWhite: wavesManyWhite,
    },
}

const people = {
    ivancik,
    bruceMars,
    kalVisuals,
    marie,
    ivana,
}

const backgrounds = {
    bg_001,
    bg_002,
    bg_003,
    bg_004,
    bg_010,
    dark: {
        bg_001: bg_001_dark
    },
    new: {
        bg_001: n_bg_001,
        bg_002: n_bg_002,
        bg_003: n_bg_003,
        bg_004: n_bg_004,
        bg_005: n_bg_005,
        bg_006: n_bg_006,
        bg_007: n_bg_007,
        bg_008: n_bg_008,
        bg_009: n_bg_009,
        bg_010: n_bg_010,
        bg_011: n_bg_011,
    }
}

const defaultImages = {
    team1,
    team2,
    team3,
    team4,
    homeDecor1,
    homeDecor2,
    homeDecor3,
    arrow: {
        down: {
            white: downArrowWhite,
            dark: downArrowDark,
            default: downArrowDefault,
        },
    },
    office: {
        dark: officeDark
    },
    meeting1,
    meeting2,
    collaboration
}

export { logos, curved, illustrations, shapes, smallLogos, people, backgrounds, defaultImages };