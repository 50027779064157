import React, { useRef, useState } from 'react'
import ReactPlayer from 'react-player';

const PlayerComponent = (props) => {

    const { url, light } = props;
    const playerRef = useRef(null);
    const [state, setState] = useState({
        pip: false,
        playing: false,
        controls: true,
        light: true
    })

    const handlePlayPause = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setState({ ...state, playing: !state.playing });
    }

    const onProgress = (ev) => {
    }

    return (
        <div onClick={(ev) => {
            handlePlayPause(ev)
        }}>
            <ReactPlayer
                style={{ borderRadius: 50 }}
                ref={playerRef}
                className='z-index-1'
                width='100%'
                height='auto'
                url={url}
                pip={state.pip}
                playing={state.playing}
                controls={state.controls}
                light={light ? state.light : false}
                loop={false}
                playbackRate={1.0}
                volume={0.8}
                muted={false}
                onEnablePIP={() => { }}
                onDisablePIP={() => { }}
                onEnded={() => {
                    handlePlayPause();
                }}
                onProgress={onProgress}
            />
        </div>
    )
}

export default PlayerComponent