import React, { useEffect, useState } from 'react';
import { logos } from '../../../core/app_images';
import TopNavigator from '../components/navigator/top_navigator';
import Sidebar from '../components/sidebar/sidebar';
import '../../../assets/css/soft-ui-dashboard.css';
import { TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getUser } from '../../../services/auth';
import { getFullDataOfUser, getProfileImage, updateUser, updateUserEndereco } from '../../../repositories/user_repository';
import { CircularProgress } from '@material-ui/core';
import { useSnackbar, withSnackbar } from 'notistack';
import Footer from '../../shared/components/footer/footer';

const StudentConfigsPage = () => {
    const { enqueueSnackbar } = useSnackbar()
    const [savingForm, setSavingForm] = useState(false);
    const [savingPassForm, setSavingPassForm] = useState(false);
    const [showNavbar, setShowNavbar] = useState(false);
    const [user, setUser] = useState({});
    const [profileImage, setProfileImage] = useState('');

    const [nomeToEdit, setNomeToEdit] = useState('');
    const [cpfToEdit, setCpfToEdit] = useState('');
    const [dataNascToEdit, setDataNascToEdit] = useState('');
    const [telToEdit, setTelToEdit] = useState('');
    const [emailToEdit, setEmailToEdit] = useState('');

    const [cepToEdit, setCepToEdit] = useState('');
    const [endToEdit, setEndToEdit] = useState('');
    const [numToEdit, setNumToEdit] = useState('');
    const [complToEdit, setComplToEdit] = useState('');
    const [bairroToEdit, setBairroToEdit] = useState('');
    const [cityToEdit, setCityToEdit] = useState('');
    const [estateToEdit, setEstateToEdit] = useState('');

    const [actualPass, setActualPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [confirmNewPass, setConfirmNewPass] = useState('');

    const onChangeNavbar = (opt) => {
        setShowNavbar(opt);
    }

    const saveForm = async () => {
        setSavingForm(true)
        let loggedUser = await getUser();
        let sendedUserInfo = await sendUserInfo(loggedUser.id)
        let sendedUserEndereco = await sendEndereco(loggedUser.id)

        if (sendedUserInfo.success && sendedUserEndereco.success) {
            enqueueSnackbar('Dados pessoais e Endereço salvos com sucesso!',
                { variant: 'success' })
        } else if (sendedUserInfo.success && !sendedUserEndereco.success) {
            enqueueSnackbar('Dados pessoais e Endereço salvos com sucesso!',
                { variant: 'success' })
            enqueueSnackbar('Não foi possível atualizar o endereço, tente novamente mais tarde!',
                { variant: 'danger' })
        } else if (!sendedUserInfo.success && sendedUserEndereco.success) {
            enqueueSnackbar('Endereço atualizado com sucesso!',
                { variant: 'success' })
            enqueueSnackbar('Não foi possível atualizar os dados pessoais, tente novamente mais tarde!',
                { variant: 'danger' })
        } else {
            enqueueSnackbar('Algo aconteceu de errado! Tente novamente mais tarde. Caso o problema continue, fale com a equipe técnica!',
                { variant: 'danger' })
        }
        setSavingForm(false)
    }

    const saveFormPassword = async () => {
        setSavingPassForm(true)

        if (actualPass === newPass) {
            enqueueSnackbar('A senha nova e antiga são as mesmas, por favor, utiliza uma nova senha!',
                { variant: 'danger' })
        } else if (newPass !== confirmNewPass) {
            enqueueSnackbar('O campo de nova senha está divergente da confirmação! Verifique e tente novamente.',
                { variant: 'danger' })
        } else {
            let loggedUser = await getUser()
            let sendUpdatePass = await sendUpdatePassword(loggedUser.id)

            if (sendUpdatePass.success) {
                enqueueSnackbar('Nova senha configurada com sucesso!',
                    { variant: 'success' })

                setActualPass('')
                setNewPass('')
                setConfirmNewPass('')
            } else {
                enqueueSnackbar('Não foi possível atualizar os dados pessoais, tente novamente mais tarde!',
                    { variant: 'danger' })
            }
        }

        setSavingPassForm(false)
    }

    const sendUserInfo = async (id) => {
        let userInfo = {
            nome: nomeToEdit,
            cpf: cpfToEdit,
            data_nasc: dataNascToEdit,
            telefone: telToEdit,
            email: emailToEdit
        }

        let isUpdated = await updateUser(userInfo, id)

        return isUpdated
    }

    const sendEndereco = async (id) => {
        let userEndereco = {
            cep: cepToEdit,
            endereco: endToEdit,
            numero: numToEdit,
            complemento: complToEdit,
            bairro: bairroToEdit,
            cidade: cityToEdit,
            estado: estateToEdit
        }

        let isUpdated = await updateUserEndereco(userEndereco, id)

        return isUpdated
    }

    const sendUpdatePassword = async (id) => {
        let data = {
            password: actualPass,
            newPassword: newPass,
            newPasswordConfirm: confirmNewPass
        }

        let isUpdated = await updateUser(data, id);

        return isUpdated;
    }

    const saveAboutForm = () => {

    }

    const changeInput = (func, ev) => {
        func(ev.target.value)
    }

    const dataOfUser = async (logged) => {
        let loggedUser = await getFullDataOfUser(logged.id);

        setNomeToEdit(loggedUser.nome)
        setCpfToEdit(loggedUser.cpf)
        setTelToEdit(loggedUser.telefone)
        setEmailToEdit(loggedUser.email)
        setDataNascToEdit(loggedUser.data_nasc)

        if (loggedUser.endereco) {
            setCepToEdit(loggedUser.endereco.cep)
            setEndToEdit(loggedUser.endereco.endereco)
            setNumToEdit(loggedUser.endereco.numero)
            setComplToEdit(loggedUser.endereco.complemento)
            setBairroToEdit(loggedUser.endereco.bairro)
            setCityToEdit(loggedUser.endereco.cidade)
            setEstateToEdit(loggedUser.endereco.estado)
        }
    }


    useEffect(() => {
        const getLoggedUser = async () => {
            let loggedUser = await getUser()
            setUser(loggedUser)
            dataOfUser(loggedUser)
            let image = getProfileImage(loggedUser.id)
            setProfileImage(image)
        }
        getLoggedUser()
    }, [])

    return (
        <div className={`g-sidenav-show bg-gray-100${showNavbar ? " g-sidenav-pinned" : ""}`}>
            <Sidebar logo={logos.navbar} show={showNavbar} background={'bg-main'} onCloseSidebar={onChangeNavbar} />
            <div className="main-content position-relative bg-gray-100">
                <TopNavigator title="Configurações" breadcrumps={[{ title: 'Início', link: '/student' }]} showNavbar={onChangeNavbar} navbarOpen={showNavbar} />
                <div className="container-fluid">
                    <div className="row pt-8">
                        <div className="col-12 col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="avatar avatar-xl position-relative w-100">
                                        <img src={profileImage} alt="Imagem de perfil" className="w-100 position border-radius-lg shadow-sm" />
                                        <button className="btn btn-sm btn-icon-only bg-gradient-light position-absolute bottom-0 end-0 mb-n7 me-n2">
                                            <i className="fa fa-pen bottom-0" title="Editar Imagem"></i>
                                        </button>
                                    </div>
                                    <div className="h-100">
                                        <h5 className="mb-1">
                                            {user.nome}
                                        </h5>
                                        <p className="mb-0 font-weight-bold text-sm">
                                            {"PSA - Sábado - 10hr"}
                                        </p>
                                    </div>
                                    <div className="py-3">
                                        <TextField
                                            id="about_input textfield"
                                            label="Sobre"
                                            multiline
                                            fullWidth
                                            rows={4}
                                            placeholder={`Descrição breve sobre ${user.nome ? user.nome.split(' ')[0] : 'o usuário :)'}`}
                                            variant="filled"
                                        />
                                        <Link onClick={saveAboutForm} fullWidth type="submit" className="btn bg-gradient-main mt-3 mb-0 w-100" style={{ borderRadius: 50 }}>Salvar</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="row">
                                <div className="col-12 py-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 col-md-6 my-2">
                                                    <TextField
                                                        label="Nome"
                                                        fullWidth
                                                        value={nomeToEdit}
                                                        onChange={(ev) => changeInput(setNomeToEdit, ev)}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 my-2">
                                                    <TextField
                                                        label="CPF"
                                                        fullWidth
                                                        value={cpfToEdit}
                                                        onChange={(ev) => changeInput(setCpfToEdit, ev)}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4 my-2">
                                                    <TextField
                                                        label="Data de Nascimento"
                                                        fullWidth
                                                        value={dataNascToEdit}
                                                        onChange={(ev) => changeInput(setDataNascToEdit, ev)}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4 my-2">
                                                    <TextField
                                                        label="Telefone"
                                                        fullWidth
                                                        value={telToEdit}
                                                        onChange={(ev) => changeInput(setTelToEdit, ev)}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 my-2">
                                                    <TextField
                                                        label="E-mail"
                                                        type="email"
                                                        fullWidth
                                                        value={emailToEdit}
                                                        onChange={(ev) => changeInput(setEmailToEdit, ev)}
                                                    />
                                                </div>
                                                <hr className="my-3" />
                                                <div className="col-12 col-md-3 my-2">
                                                    <TextField
                                                        label="CEP"
                                                        fullWidth
                                                        helperText="Apenas números"
                                                        InputLabelProps={{ maxLength: 8 }}
                                                        value={cepToEdit}
                                                        onChange={(ev) => changeInput(setCepToEdit, ev)}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-7 my-2">
                                                    <TextField
                                                        label="Endereço"
                                                        fullWidth
                                                        InputLabelProps={{ maxLength: 8 }}
                                                        value={endToEdit}
                                                        onChange={(ev) => changeInput(setEndToEdit, ev)}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-2 my-2">
                                                    <TextField
                                                        label="Número"
                                                        fullWidth
                                                        value={numToEdit}
                                                        onChange={(ev) => changeInput(setNumToEdit, ev)}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4 my-2">
                                                    <TextField
                                                        label="Complemento"
                                                        fullWidth
                                                        value={complToEdit}
                                                        onChange={(ev) => changeInput(setComplToEdit, ev)}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-3 my-2">
                                                    <TextField
                                                        label="Bairro"
                                                        fullWidth
                                                        value={bairroToEdit}
                                                        onChange={(ev) => changeInput(setBairroToEdit, ev)}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-3 my-2">
                                                    <TextField
                                                        label="Cidade"
                                                        fullWidth
                                                        value={cityToEdit}
                                                        onChange={(ev) => changeInput(setCityToEdit, ev)}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-2 my-2">
                                                    <TextField
                                                        label="Estado"
                                                        fullWidth
                                                        value={estateToEdit}
                                                        onChange={(ev) => changeInput(setEstateToEdit, ev)}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <div className="row w-100">
                                                        <div className="col-auto">
                                                            <Link to="/student" fullWidth className="btn bg-gradient-light mt-3 mb-0 w-100" style={{ borderRadius: 50 }}>Cancelar</Link>
                                                        </div>
                                                        <div className="col-sm">

                                                        </div>
                                                        <div className="col-auto text-right">
                                                            <Link onClick={savingForm ? () => { } : saveForm} fullWidth type="submit" className="btn bg-gradient-main mt-3 mb-0 w-100" style={{ borderRadius: 50, opacity: savingForm ? 0.4 : 1 }}>{savingForm ? <><CircularProgress size={13} color='inherit' /> {'Salvando...'}</> : 'Salvar'}</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 py-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>Alteração de senha...</p>
                                                </div>
                                                <div className="col-12 col-md-4 my-2">
                                                    <TextField
                                                        label="Senha atual"
                                                        fullWidth
                                                        security
                                                        type="password"
                                                        value={actualPass}
                                                        onChange={(ev) => changeInput(setActualPass, ev)}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4 my-2">
                                                    <TextField
                                                        label="Nova senha"
                                                        fullWidth
                                                        security
                                                        type="password"
                                                        value={newPass}
                                                        onChange={(ev) => changeInput(setNewPass, ev)}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4 my-2">
                                                    <TextField
                                                        label="Confirmar nova senha"
                                                        fullWidth
                                                        security
                                                        type="password"
                                                        value={confirmNewPass}
                                                        onChange={(ev) => changeInput(setConfirmNewPass, ev)}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <div className="row w-100">
                                                        <div className="col-auto">

                                                        </div>
                                                        <div className="col-sm">

                                                        </div>
                                                        <div className="col-auto text-right">
                                                            <Link
                                                                onClick={savingPassForm ? () => { } : saveFormPassword}
                                                                fullWidth type="submit" className="btn bg-gradient-main mt-3 mb-0 w-100"
                                                                style={{ borderRadius: 50, opacity: savingPassForm ? 0.4 : 1 }}>
                                                                {
                                                                    savingPassForm ?
                                                                        <><CircularProgress size={13} color='inherit' /> {'Atualizando...'}</>
                                                                        : "Atualizar"
                                                                }
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        </div>
    );
}

export default withSnackbar(StudentConfigsPage);
